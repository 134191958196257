import * as React from 'react';
import PropTypes from 'prop-types';
import FlagIconFactory from 'react-flag-icon-css';
import DIFCFlag from '../../../src/assets/flags/DIFC.png';
import AEAZFlag from '../../../src/assets/flags/AE-AZ.png';

const FlagIcon = FlagIconFactory(React, { useCssModules: false });

const checkSpecialCase = value => {
  return value.toLowerCase() === 'difc' || value.toLowerCase() === 'ae-az';
};

const transformForSpecialCase = value => {
  if (value.toLowerCase() === 'difc') {
    return <img className='custom-flag' src={DIFCFlag} alt='DIFC' />;
  } else if (value.toLowerCase() === 'ae-az') {
    return <img className='custom-flag' src={AEAZFlag} alt='ADGM' />;
  }
};

const checkIsValid = value => {
  return value && typeof value === 'string';
};

const MyFlagIcon = ({ value }) =>
  checkIsValid(value) && !checkSpecialCase(value) ? (
    <FlagIcon code={value.toLowerCase()} size='2x' />
  ) : (
    <span>{transformForSpecialCase(value)}</span>
  );

export default MyFlagIcon;
MyFlagIcon.propTypes = {
  value: PropTypes.string,
};
