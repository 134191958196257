import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import cx from 'classnames';

import UITooltip from '../UITooltip';
import UIIcon from '../UIIcon';
import UISortingButton from '../UISortingButton';
import TextFilter from './TextFilter';
import CheckboxFilter from './CheckboxFilter';
import DateFilter from './DateFilter';

const filterTypes = {
  text: TextFilter,
  checkbox: CheckboxFilter,
  date: DateFilter,
};

const tooltipModifiers = {
  dataObject: {
    enabled: true,
    order: 890,
    fn: data => ({
      ...data,
      styles: {
        ...data.styles,
        top: '-26px',
      },
    }),
  },
};

const TableFilterContainer = ({
  title,
  type,
  sort,
  filter,
  checkboxList,
  dateFromFilter,
  dateToFilter,
  onSortChange,
  onFilterChange,
  onDateFiltersChange,
  noFilter,
}) => {
  const [visible, setVisible] = useState(false);
  const Filter = filterTypes[type || 'text'];
  const isActiveFilter =
    !!get(filter, 'value') || !!get(dateFromFilter, 'value') || !!get(dateToFilter, 'value');
  const hasSorting = !!sort;

  return (
    <div className={cx('d-flex align-items-center m-0 header-cell', { noSorting: !hasSorting })}>
      {hasSorting ? (
        <UISortingButton title={title} sort={sort} onChange={onSortChange} />
      ) : (
        <div className='d-flex align-items-center body--bold mr-3 with-opacity'>{title}</div>
      )}
      {!noFilter && (
        <UITooltip
          tooltipShown={visible}
          onVisibilityChange={setVisible}
          modifiers={tooltipModifiers}
          tooltipClassName={cx('ui-filter-dropdown', {
            'ui-filter-dropdown--date': type === 'date',
          })}
          placement='bottom-start'
          trigger='click'
          triggerContent={
            <UIIcon
              icon='filter'
              iconColor={isActiveFilter ? 'var(--dodger-blue)' : 'var(--light-grey-blue)'}
            />
          }
          tooltipContent={
            <Filter
              title={title}
              filter={filter}
              dateFromFilter={dateFromFilter}
              dateToFilter={dateToFilter}
              onDateFiltersChange={onDateFiltersChange}
              checkboxList={checkboxList}
              onFilterChange={onFilterChange}
            />
          }
        />
      )}
    </div>
  );
};

TableFilterContainer.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  sort: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  filter: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  dateFromFilter: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  dateToFilter: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  checkboxList: PropTypes.arrayOf(PropTypes.string),
  onSortChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onDateFiltersChange: PropTypes.func.isRequired,
  noFilter: PropTypes.bool,
};
export default TableFilterContainer;
