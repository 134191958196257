import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import NavbarContainer from './NavBar';
import UINotification from '../common/components/UINotification';
import UIModalContainer from './UIModalContainer';

const DashboardContainer = ({ children, notAuthorized }) => {
  const location = useLocation();
  return (
    <div className={cx('dashboard dashboard-container', { notAuthorized })}>
      {!notAuthorized && <NavbarContainer location={location} />}
      <Container fluid className='dashboard-content-wrap'>
        <Row>
          <Col sm={{ size: 12 }} className='p-0'>
            {children}
          </Col>
        </Row>
      </Container>
      <UINotification />
      <UIModalContainer />
    </div>
  );
};

const mapStateToProps = ({ common }) => ({
  notAuthorized: common.notAuthorized,
});

DashboardContainer.propTypes = {
  children: PropTypes.node,
  notAuthorized: PropTypes.bool,
};
export default connect(mapStateToProps, null)(DashboardContainer);
