import { push } from 'connected-react-router';
import jwtDecode from 'jwt-decode';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { keycloakInstance } from '../../App';
import * as authTypes from '../types/auth';
import * as authApi from '../../api/services/auth';
import { safeJsonParse } from '../../common/helpers/object';
import { getAllGroupList } from './groups';
import { getCountryList, getDocTypeList, getLicenceList } from './common';

const TOKEN_KEY = 'AUTH_TOKEN';
const AUTH_USER_MODEL = 'AUTH_USER_MODEL';
const TOKEN_TYPE_KEY = 'AUTH_TOKEN_TYPE';
export const SELECTED_GROUP_ID = 'SELECTED_GROUP_ID';

export const setAuthTokensAndUser = ({ token, tokenType = 'Bearer', user }) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(TOKEN_TYPE_KEY, tokenType);
  localStorage.setItem(AUTH_USER_MODEL, JSON.stringify(user));
  authApi.setToken(token, tokenType);
  return Promise.resolve();
};

export const initDashboard = () => (dispatch, getState) => {
  localStorage.removeItem(SELECTED_GROUP_ID);
  const groupList = get(getState(), 'groups.groupList.results');
  if (!isEmpty(groupList)) return;

  dispatch(getAllGroupList());
};

export const getAuthTokens = () => {
  const token = localStorage.getItem(TOKEN_KEY) || '';
  const user = safeJsonParse(localStorage.getItem(AUTH_USER_MODEL));
  const tokenType = localStorage.getItem(TOKEN_TYPE_KEY) || '';
  return {
    token,
    tokenType,
    user,
  };
};

export const login = token => dispatch => {
  const {
    email,
    given_name: givenName,
    family_name: familyName,
    preferred_username: username,
    typ: tokenType,
  } = jwtDecode(token);
  const user = { email, givenName, familyName, username };

  dispatch({ type: authTypes.AUTH_USER_RECEIVE, user });
  dispatch({ type: authTypes.AUTH_RECEIVE, auth: token });

  setAuthTokensAndUser({ token, tokenType, user }).then(() => {
    dispatch(getDocTypeList());
    dispatch(getCountryList());
    dispatch(getLicenceList());
    dispatch(initDashboard());
  });
};

export const logout = () => dispatch => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(AUTH_USER_MODEL);
  localStorage.removeItem(TOKEN_TYPE_KEY);
  authApi.deleteToken();
  dispatch({ type: authTypes.AUTH_USER_RECEIVE });
  dispatch({ type: authTypes.AUTH_RECEIVE });
  dispatch(push('/'));
  keycloakInstance.logout();
  return Promise.resolve();
};
