export const PROFILES_LIST_RESET = 'PROFILES_LIST_RESET';
export const PROFILES_LIST_REQUEST = 'PROFILES_LIST_REQUEST';
export const PROFILES_LIST_SUCCESS = 'PROFILES_LIST_SUCCESS';
export const PROFILES_LIST_FAILURE = 'PROFILES_LIST_FAILURE';
export const PROFILES_LIST_REMOVE = 'PROFILES_LIST_REMOVE';
export const PROFILES_LIST_UPDATE = 'PROFILES_LIST_UPDATE';

export const SEARCH_PROFILES_LIST_RESET = 'SEARCH_PROFILES_LIST_RESET';
export const SEARCH_PROFILES_LIST_REQUEST = 'SEARCH_PROFILES_LIST_REQUEST';
export const SEARCH_PROFILES_LIST_SUCCESS = 'SEARCH_PROFILES_LIST_SUCCESS';
export const SEARCH_PROFILES_LIST_FAILURE = 'SEARCH_PROFILES_LIST_FAILURE';
export const SEARCH_PROFILES_LIST_REMOVE = 'SEARCH_PROFILES_LIST_REMOVE';
export const SEARCH_PROFILES_LIST_UPDATE = 'SEARCH_PROFILES_LIST_UPDATE';

export const SEARCH_PROFILES_TOTAL_RECEIVE = 'SEARCH_PROFILES_TOTAL_RECEIVE';

export const SET_PROFILES_FILTERS = 'SET_PROFILES_FILTERS';

export const SET_SHOW_PROFILE_MODAL = 'SET_SHOW_PROFILE_MODAL';
export const SET_EDIT_PROFILE_ID = 'SET_EDIT_PROFILE_ID';

export const PROFILE_RESET = 'PROFILE_RESET';
export const PROFILE_REQUEST = 'PROFILE_REQUEST';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';

export const DATA_PROVIDER_ACCESSES_SUCCESS = 'DATA_PROVIDER_ACCESSES_SUCCESS';
export const DATA_PROVIDER_ACCESSES_FAILURE = 'DATA_PROVIDER_ACCESSES_FAILURE';
