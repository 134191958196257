import apiClient from '../api';

const CACHES_URI = '/inauth/caches';
const FEATURES_URI = '/inauth/features';

export const getAllCaches = () => apiClient.get(`${CACHES_URI}`);
export const evictsAllCaches = () => apiClient.delete(`${CACHES_URI}`);
export const evictsCacheByName = name => apiClient.delete(`${CACHES_URI}/${name}`);

export const getAllFeatures = () => apiClient.get(`${FEATURES_URI}`);
export const toggleFeatureFlag = (featureId, payload) =>
  apiClient.put(`${FEATURES_URI}/${featureId}`, payload);
