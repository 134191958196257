import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import dotProp from 'dot-prop-immutable';
import cx from 'classnames';

import UIPagination from '../common/components/UIPagination';
import UIPagesize from '../common/components/UIPagesize';
import BulkBottomBar from '../common/components/Table/BulkBottomBar';
import Table from '../common/components/Table/UITable';
// eslint-disable-next-line
import generateRTColumnsWithHandlers from '../common/components/Table/generateRTColumnsWithHandlers';
import TableNoData from '../common/components/Table/TableNoData';

const sizeList = [10, 30, 50];

const getPageSize = (total, size) => (total < Number(size) ? total : Number(size));

const TableRender = ({
  data = [],
  columns = [],
  total = 0,
  filters = {},
  noDataState = {},
  selectedRows = [],
  showPagination = true,
  isLoading = false,
  canRowSelect = true,
  onFiltersAndSortingChange = () => {},
  onSelectRowChange = () => {},
  bottomBarState,
}) => {
  const onPaginationChange = (key, value) => {
    let newPagination;
    if (key !== 'size') {
      newPagination = dotProp.set(filters, `pagination.${key}`, value);
    } else {
      let params = { size: value, page: 0 };
      if (filters.pagination.endDateFrom) {
        params = { ...params, endDateFrom: filters.pagination.endDateFrom };
      }
      if (filters.pagination.onlyActive) {
        params = { ...params, onlyActive: filters.pagination.onlyActive };
      }
      newPagination = dotProp.set(filters, `pagination`, params);
    }
    onFiltersAndSortingChange(newPagination);
  };

  const { pagination = {}, sort, filter } = filters;
  const { page, size } = pagination;
  const isEmptyFilters = isEmpty(filter) && isEmpty(sort);
  const noRows = !data.length && !isLoading && !total;
  const noData = isEmptyFilters && noRows;
  const showBottomBar = !!selectedRows.length;
  const pageSize = showPagination ? getPageSize(total, size) : data.length;
  const generatedColumns = generateRTColumnsWithHandlers(
    data,
    columns,
    filters,
    selectedRows,
    canRowSelect,
    onFiltersAndSortingChange,
    onSelectRowChange,
  );

  if (noData) {
    return <TableNoData noDataState={noDataState} />;
  }

  return (
    <Fragment>
      <div className='pb-3'>
        <Table
          className={cx({ empty: noRows })}
          data={data}
          columns={generatedColumns}
          pageSize={pageSize}
          loading={isLoading}
          keyField='id'
        />
      </div>
      {showPagination && (
        <div className='ui-pagination-container d-flex py-3'>
          <UIPagesize sizeList={sizeList} pageSize={Number(size)} onChange={onPaginationChange} />
          <UIPagination
            total={total}
            page={Number(page)}
            pageSize={Number(size)}
            onChange={onPaginationChange}
          />
        </div>
      )}
      {showBottomBar && <BulkBottomBar bottomBarState={bottomBarState} />}
    </Fragment>
  );
};

TableRender.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  filters: PropTypes.shape({
    pagination: PropTypes.object,
    sort: PropTypes.object,
    filter: PropTypes.object,
  }),
  noDataState: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  total: PropTypes.number,
  showPagination: PropTypes.bool,
  isLoading: PropTypes.bool,
  canRowSelect: PropTypes.bool,
  selectedRows: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  onFiltersAndSortingChange: PropTypes.func,
  onSelectRowChange: PropTypes.func,
  bottomBarState: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      btnType: PropTypes.string,
      onClick: PropTypes.func.isRequired,
    }),
  ),
};

export default TableRender;
