import React from 'react';
import PropTypes from 'prop-types';

import UIIcon from '../common/components/UIIcon';
import UITooltip from '../common/components/UITooltip';

const RowOptions = ({
  onEdit,
  onDuplicate,
  onDeactivate,
  onMoveUser,
  onReactivate,
  onDelete,
  isActive,
}) => (
  <UITooltip
    tooltipClassName='ui-row-option-container'
    placement='left-start'
    trigger='click'
    triggerContent={
      <UIIcon
        icon='dotsVertical'
        iconClassName='dots-icon'
        iconColor='var(--gunmetal)'
        iconSize='1.2rem'
      />
    }
    tooltipContent={
      <ul role='menu' className='ui-dropdown-menu'>
        <li className='ui-dropdown-item' role='menuitem'>
          <button onClick={onEdit} className='d-flex w-100'>
            Edit
          </button>
        </li>
        <li className='ui-dropdown-item' role='menuitem'>
          <button onClick={onDuplicate} className='d-flex w-100'>
            Duplicate
          </button>
        </li>
        {process.env.REACT_APP_DESIGN_IN_DEVELOPMENT && (
          <li className='ui-dropdown-item' role='menuitem'>
            <button onClick={onMoveUser} className='d-flex w-100'>
              Move User
            </button>
          </li>
        )}
        <li className='ui-dropdown-item' role='menuitem'>
          <button onClick={isActive ? onDeactivate : onReactivate} className='d-flex w-100'>
            {isActive ? 'Deactivate' : 'Reactivate'}
          </button>
        </li>
        <li className='ui-dropdown-item' role='menuitem'>
          <button className='d-flex w-100' onClick={onDelete}>
            Delete
          </button>
        </li>
      </ul>
    }
  />
);

export default RowOptions;

RowOptions.propTypes = {
  onEdit: PropTypes.func,
  onDuplicate: PropTypes.func,
  onDeactivate: PropTypes.func,
  onReactivate: PropTypes.func,
  onMoveUser: PropTypes.func,
  onDelete: PropTypes.func,
  isActive: PropTypes.bool,
};
