import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import cx from 'classnames';
import {
  mdiDotsVertical,
  mdiChevronDown,
  mdiChevronDoubleLeft,
  mdiPlusCircle,
  mdiMinusCircle,
  mdiFilterVariant,
  mdiCheck,
  mdiClose,
  mdiCalendarMonth,
  mdiKeyboardTab,
  mdiBankOutline,
  mdiFormatListBulletedSquare,
  mdiPlus,
  mdiAccountGroup,
  mdiFileDownloadOutline,
  mdiFileUploadOutline,
  mdiCityVariantOutline,
  mdiAccountBadgeHorizontalOutline,
  mdiBookMultiple,
  mdiCloudUpload,
  mdiCalendarRemoveOutline,
  mdiElectricSwitch,
  mdiElectricSwitchClosed,
  mdiLockAlert,
  mdiStar,
  mdiFolderStar,
} from '@mdi/js';

const nameToIconPath = {
  dotsVertical: mdiDotsVertical,
  chevronDown: mdiChevronDown,
  chevronDouble: mdiChevronDoubleLeft,
  plusCircle: mdiPlusCircle,
  minusCircle: mdiMinusCircle,
  filter: mdiFilterVariant,
  check: mdiCheck,
  close: mdiClose,
  calendar: mdiCalendarMonth,
  keyboardTab: mdiKeyboardTab,
  bank: mdiBankOutline,
  listSquare: mdiFormatListBulletedSquare,
  plus: mdiPlus,
  userGroup: mdiAccountGroup,
  downloadFile: mdiFileDownloadOutline,
  uploadFile: mdiFileUploadOutline,
  cityOutline: mdiCityVariantOutline,
  accountBadge: mdiAccountBadgeHorizontalOutline,
  bookMulti: mdiBookMultiple,
  cloudUpload: mdiCloudUpload,
  removeDate: mdiCalendarRemoveOutline,
  switch: mdiElectricSwitch,
  switchClose: mdiElectricSwitchClosed,
  lock: mdiLockAlert,
  star: mdiStar,
  folderStar: mdiFolderStar,
};

const UIIcon = ({ icon, iconSize = '1rem', iconColor, iconRotate = 0, iconClassName = '' }) => {
  const iconPath = nameToIconPath[icon] || null;

  return (
    <div className={cx('ui-icon_wrapper', iconClassName)}>
      <Icon path={iconPath} size={iconSize} color={iconColor} rotate={iconRotate} />
    </div>
  );
};

UIIcon.propTypes = {
  icon: PropTypes.oneOf(Object.keys(nameToIconPath)).isRequired,
  iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconColor: PropTypes.string,
  iconRotate: PropTypes.number,
  iconClassName: PropTypes.string,
};

export default UIIcon;
