import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup, Input, Label } from 'reactstrap';

import UIModal from '../common/components/UIModal';
import { getIsFetching, getResults } from '../redux/helpers/createList';
import { getResource } from '../redux/helpers/createResource';
import UICustomLoader from '../common/components/UICustomLoader';
import UIButton from '../common/components/UIButton';
import UISelect from '../common/components/UISelect';
import {
  createEntity as _createEntity,
  getEntityById as _getEntityById,
  resetEntity as _resetEntity,
  setEditEntityId as _setEditEntityId,
  setShowEntityModal as _setShowEntityModal,
  updateEntity as _updateEntity,
} from '../redux/actions/entities';
import CountryHelper from '../common/components/Table/CountryHelper';

const defaultEntityModel = {
  name: null,
  description: null,
  brpGroupId: null,
  groupId: null,
  domicileCountryId: null,
  licenses: [],
};

const EntityFormModal = ({
  groupId,
  editId,
  editEntity,
  isLoading,
  countryList,
  licenceList,
  getEntityById,
  resetEntity,
  setEditEntityId,
  setShowEntityModal,
  updateEntity,
  createEntity,
}) => {
  const [entityState, setEntityState] = useState(defaultEntityModel);
  const [errorState, setErrorState] = useState({});

  const errorsHandler = errors => {
    if (errors) {
      const { fieldErrors } = errors.data;
      fieldErrors.forEach(err => {
        setErrorState(prevState => ({
          ...prevState,
          [err.field]: err.message,
        }));
      });
    }
  };

  const onInit = entityId => {
    if (entityId) {
      getEntityById(entityId);
    }
  };

  const onReset = e => {
    e.preventDefault();
    resetEntity();
    setEditEntityId();
    setShowEntityModal();
  };

  const onSave = (entity, hideModal) => {
    if (entity.id) {
      updateEntity(entity).catch(error => {
        errorsHandler(error);
      });
    } else {
      createEntity(entity, hideModal).catch(error => {
        errorsHandler(error);
      });
    }
  };

  const formRef = useRef(null);
  useEffect(() => {
    if (editEntity) {
      setEntityState(editEntity);
    }
  }, [editEntity]);

  useEffect(() => {
    onInit(editId);
  }, []);

  const handleChange = (key, value) => {
    setEntityState({ ...entityState, [key]: value });
    setErrorState({ ...errorState, [key]: undefined });
  };

  const handleSave = e => {
    e.preventDefault();
    const {
      target: { name },
    } = e;
    const saveAndAddNew = name === 'saveAndAddNew';
    const checkValidity = formRef.current.reportValidity();
    if (checkValidity) {
      const updatedEntity = {
        id: entityState.id,
        groupId,
        name: entityState.name,
        description: entityState.description,
        domicileCountryId: entityState.domicileCountryId,
        brpGroupId: entityState.brpGroupId ? Number(entityState.brpGroupId) : null,
        licensesIds: entityState.licenses.map(({ id }) => id),
      };
      onSave(updatedEntity, !saveAndAddNew);
      if (saveAndAddNew) {
        setEntityState(defaultEntityModel);
      }
    }
  };

  const { name, description, domicileCountryId, licenses, brpGroupId } = entityState;
  const isEdit = !!editId;
  return (
    <UIModal
      isOpen
      toggle={onReset}
      title={`${isEdit ? 'Edit' : 'Add'} entity`}
      className='ui-edit-modal'
    >
      {isLoading ? (
        <UICustomLoader loading={isLoading} />
      ) : (
        <Form innerRef={formRef}>
          <FormGroup>
            <Label className='body body--bold mb-2'>Name</Label>
            <Input
              name='name'
              value={name || ''}
              placeholder='Name'
              className={errorState.name ? 'ui-input error' : 'ui-input'}
              onChange={({ target: { value } }) => handleChange('name', value)}
            />
            <Label className='errorMessage'>{errorState.name}</Label>
          </FormGroup>
          <FormGroup>
            <Label className='body body--bold mb-2'>BRP Group Id</Label>
            <Input
              type='number'
              min={0}
              name='brpGroupId'
              value={brpGroupId}
              placeholder='BRP Group Id'
              className={errorState.brpGroupId ? 'ui-input error' : 'ui-input'}
              onChange={({ target: { value } }) => handleChange('brpGroupId', value)}
            />
            <Label className='errorMessage'>{errorState.brpGroupId}</Label>
          </FormGroup>
          <FormGroup>
            <Label className='body body--bold mb-2'>Source</Label>
            <UISelect
              isSearchable
              getOptionLabel={({ id }) => <CountryHelper target={id} />}
              getOptionValue={({ name: countryName }) => countryName}
              placeholder='Select source'
              value={countryList.find(({ id }) => id === domicileCountryId) || 0}
              onChange={val => {
                handleChange('domicileCountryId', val ? val.id : null);
              }}
              options={countryList}
              hasError={!!errorState.domicileCountryId}
            />
            <Label className='errorMessage'>{errorState.domicileCountryId}</Label>
          </FormGroup>
          <FormGroup>
            <Label className='body body--bold mb-2'>License/s</Label>
            <UISelect
              isMulti
              isSearchable
              getOptionLabel={({ name: licenceName }) => licenceName}
              getOptionValue={({ id }) => id}
              placeholder='Select inApp target/s'
              value={licenses}
              onChange={val => handleChange('licenses', val || [])}
              options={licenceList}
            />
          </FormGroup>
          <FormGroup className='m-b-32'>
            <Label className='body body--bold mb-2'>Comment</Label>
            <Input
              name='description'
              type='textarea'
              rows='10'
              value={description || ''}
              placeholder='Add comment'
              className={errorState.description ? 'ui-input textarea error' : 'ui-input textarea'}
              onChange={({ target: { value } }) => handleChange('description', value)}
            />
            <Label className='errorMessage'>{errorState.description}</Label>
          </FormGroup>
          <div className='d-flex align-items-center mt-3'>
            <UIButton name='save' className='w-100' height={50} onClick={handleSave}>
              Save
            </UIButton>
            {!isEdit && (
              <UIButton
                name='saveAndAddNew'
                className='w-100 ml-3'
                height={50}
                btnType='outline'
                onClick={handleSave}
              >
                Save and add another one
              </UIButton>
            )}
          </div>
        </Form>
      )}
    </UIModal>
  );
};

EntityFormModal.propTypes = {
  editEntity: PropTypes.oneOfType([PropTypes.object]),
  editId: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  isLoading: PropTypes.bool,
  licenceList: PropTypes.arrayOf(PropTypes.object),
  countryList: PropTypes.arrayOf(PropTypes.object),
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getEntityById: PropTypes.func,
  resetEntity: PropTypes.func,
  setEditEntityId: PropTypes.func,
  setShowEntityModal: PropTypes.func,
  updateEntity: PropTypes.func,
  createEntity: PropTypes.func,
};

const mapStateToProps = ({ common, entities, groups }) => ({
  licenceList: getResults(common.licenceList),
  countryList: getResults(common.countryList),
  editEntity: getResource(entities.entity),
  editId: entities.editEntityId,
  isLoading: getIsFetching(entities.entity),
  editModal: entities.editModal,
  groupId: groups.selectedGroupId,
});

const mapDispatchToProps = dispatch => ({
  getEntityById: entityId => dispatch(_getEntityById(entityId)),
  resetEntity: () => dispatch(_resetEntity()),
  setEditEntityId: () => dispatch(_setEditEntityId()),
  setShowEntityModal: () => dispatch(_setShowEntityModal()),
  updateEntity: entity => dispatch(_updateEntity(entity)),
  createEntity: (entity, hideModal) => dispatch(_createEntity(entity, hideModal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EntityFormModal);
