export const ENTITY_LIST_RESET = 'ENTITY_LIST_RESET';
export const ENTITY_LIST_REQUEST = 'ENTITY_LIST_REQUEST';
export const ENTITY_LIST_SUCCESS = 'ENTITY_LIST_SUCCESS';
export const ENTITY_LIST_FAILURE = 'ENTITY_LIST_FAILURE';
export const ENTITY_LIST_REMOVE = 'ENTITY_LIST_REMOVE';
export const ENTITY_LIST_UPDATE = 'ENTITY_LIST_UPDATE';

export const SEARCH_ENTITY_LIST_RESET = 'SEARCH_ENTITY_LIST_RESET';
export const SEARCH_ENTITY_LIST_REQUEST = 'SEARCH_ENTITY_LIST_REQUEST';
export const SEARCH_ENTITY_LIST_SUCCESS = 'SEARCH_ENTITY_LIST_SUCCESS';
export const SEARCH_ENTITY_LIST_FAILURE = 'SEARCH_ENTITY_LIST_FAILURE';
export const SEARCH_ENTITY_LIST_REMOVE = 'SEARCH_ENTITY_LIST_REMOVE';
export const SEARCH_ENTITY_LIST_UPDATE = 'SEARCH_ENTITY_LIST_UPDATE';

export const SEARCH_ENTITY_TOTAL_RECEIVE = 'SEARCH_ENTITY_TOTAL_RECEIVE';

export const SET_ENTITY_FILTERS = 'SET_ENTITY_FILTERS';

export const SET_SHOW_ENTITY_MODAL = 'SET_SHOW_ENTITY_MODAL';
export const SET_EDIT_ENTITY_ID = 'SET_EDIT_ENTITY_ID';

export const ENTITY_RESET = 'ENTITY_RESET';
export const ENTITY_REQUEST = 'ENTITY_REQUEST';
export const ENTITY_SUCCESS = 'ENTITY_SUCCESS';
