import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, FormGroup, Input, Row } from 'reactstrap';

import UIIconButton from '../UIIconButton';
import UIButton from '../UIButton';

const TextFilter = ({ title, filter: { name, value }, onFilterChange }) => {
  const [search, setSearch] = useState(value || '');

  return (
    <div className='filter-dropdown'>
      <Row className='mb-3'>
        <Col className='col-auto mr-auto px-3 body'>{title}</Col>
        <Col className='col-auto ml-auto d-flex px-3'>
          <UIIconButton icon='filter' iconColor='var(--light-grey-blue)' />
        </Col>
      </Row>
      <Row className='mb-2'>
        <Col>
          <Form
            onSubmit={e => {
              e.preventDefault();
              onFilterChange(name, search);
            }}
            onReset={e => {
              e.preventDefault();
              onFilterChange(name, null);
            }}
          >
            <FormGroup className='mb-2'>
              <Input
                name='search'
                value={search}
                placeholder='Search'
                className='ui-input'
                onChange={({ target: { value: inputValue } }) => setSearch(inputValue)}
              />
            </FormGroup>
            <UIButton className='w-100 mb-2' height={32} type='submit'>
              Search
            </UIButton>
            <UIButton className='w-100' height={32} btnType='outline' type='reset'>
              Reset
            </UIButton>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

TextFilter.propTypes = {
  title: PropTypes.string.isRequired,
  filter: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  onFilterChange: PropTypes.func.isRequired,
};

export default TextFilter;
