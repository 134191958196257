import React, { useState, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import UIModal from '../UIModal';
import UIDatePicker from '../UIDatePicker';
import { formatDate, parseDateToDateObject } from '../../helpers/date';
import UIIcon from '../UIIcon';
import UIButton from '../UIButton';
import { getStatistics as _getStatistics } from '../../../redux/actions/groups';

const defaultStatisticsExportModel = {
  startDate: null,
  endDate: null,
};

export class DatePickerInput extends Component {
  render = () => (
    <div className='ui-date-input'>
      <input className='ui-input' {...this.props} placeholder='Choose date' onChange={() => {}} />
      <UIIcon icon='calendar' iconColor='var(--gunmetal)' iconSize='1.1rem' />
    </div>
  );
}

const UIStatisticsModal = ({ onReset, groupId, getStatistics, toggle }) => {
  const [statisticsState, setStatisticsState] = useState(defaultStatisticsExportModel);
  const defaultStart = formatDate('01/01/2017', 'YYYY-MM-DD');
  const today = formatDate(new Date(), 'YYYY-MM-DD');
  const defaultEndDate = formatDate(new Date(), 'YYYY-MM-DD');

  const handleChange = (key, value) => {
    setStatisticsState({ ...statisticsState, [key]: value });
  };

  const getStartDate = () => {
    return statisticsState.startDate ? statisticsState.startDate : defaultStart;
  };

  const getEndDate = () => {
    return statisticsState.endDate ? statisticsState.endDate : defaultEndDate;
  };

  return (
    <UIModal
      isOpen
      toggle={onReset}
      title={<span>Statistics export</span>}
      className='ui-edit-modal statistics-modal'
    >
      <Row form>
        <Col md={4}>
          <FormGroup className='m-b-32'>
            <Label className='body body--bold mb-2'>Start date</Label>
            <UIDatePicker
              value={defaultStart || ''}
              selectedDays={parseDateToDateObject(defaultStart)}
              setDate={date => handleChange('startDate', formatDate(date, 'YYYY-MM-DD'))}
              disabledDays={{
                before: parseDateToDateObject(defaultStart),
                after: parseDateToDateObject(getEndDate()),
              }}
              component={DatePickerInput}
              withInput
              withClass='ui-datepicker'
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup className='m-b-32'>
            <Label className='body body--bold mb-2'>End date</Label>
            <UIDatePicker
              setDate={date => handleChange('endDate', formatDate(date, 'YYYY-MM-DD'))}
              disabledDays={{
                before: parseDateToDateObject(getStartDate()),
                after: parseDateToDateObject(today),
              }}
              component={DatePickerInput}
              withInput
              withClass='ui-datepicker'
            />
          </FormGroup>
        </Col>
        <Col className='generateStatistics'>
          <div className='d-flex align-items-center'>
            <UIButton
              className='w-100'
              height={50}
              type='submit'
              onClick={() => {
                getStatistics(groupId, getStartDate(), getEndDate());
                toggle();
              }}
            >
              Generate
            </UIButton>
          </div>
        </Col>
      </Row>
    </UIModal>
  );
};

UIStatisticsModal.propTypes = {
  onReset: PropTypes.func,
  getStatistics: PropTypes.func,
  groupId: PropTypes.number,
  toggle: PropTypes.func,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onReset: e => {
    e.preventDefault();
    dispatch(ownProps.toggle());
  },
  getStatistics: (groupId, startDate, endDate) =>
    dispatch(_getStatistics(groupId, startDate, endDate)),
});

export default connect(null, mapDispatchToProps)(UIStatisticsModal);
