export const INRULES_LIST_RESET = 'INRULES_LIST_RESET';
export const INRULES_LIST_REQUEST = 'INRULES_LIST_REQUEST';
export const INRULES_LIST_SUCCESS = 'INRULES_LIST_SUCCESS';
export const INRULES_LIST_FAILURE = 'INRULES_LIST_FAILURE';
export const INRULES_LIST_REMOVE = 'INRULES_LIST_REMOVE';

export const COUNTRY_LIST_RESET = 'COUNTRY_LIST_RESET';
export const COUNTRY_LIST_REQUEST = 'COUNTRY_LIST_REQUEST';
export const COUNTRY_LIST_SUCCESS = 'COUNTRY_LIST_SUCCESS';
export const COUNTRY_LIST_FAILURE = 'COUNTRY_LIST_FAILURE';
export const COUNTRY_LIST_REMOVE = 'COUNTRY_LIST_REMOVE';

export const DOCTYPE_LIST_RESET = 'DOCTYPE_LIST_RESET';
export const DOCTYPE_LIST_REQUEST = 'DOCTYPE_LIST_REQUEST';
export const DOCTYPE_LIST_SUCCESS = 'DOCTYPE_LIST_SUCCESS';
export const DOCTYPE_LIST_FAILURE = 'DOCTYPE_LIST_FAILURE';
export const DOCTYPE_LIST_REMOVE = 'DOCTYPE_LIST_REMOVE';

export const LICENCE_LIST_RESET = 'LICENCE_LIST_RESET';
export const LICENCE_LIST_REQUEST = 'LICENCE_LIST_REQUEST';
export const LICENCE_LIST_SUCCESS = 'LICENCE_LIST_SUCCESS';
export const LICENCE_LIST_FAILURE = 'LICENCE_LIST_FAILURE';

export const GROUP_SUBSCRIBED_COUNTRY_LIST_RESET = 'GROUP_SUBSCRIBED_COUNTRY_LIST_RESET';
export const GROUP_SUBSCRIBED_COUNTRY_LIST_REQUEST = 'GROUP_SUBSCRIBED_COUNTRY_LIST_REQUEST';
export const GROUP_SUBSCRIBED_COUNTRY_LIST_SUCCESS = 'GROUP_SUBSCRIBED_COUNTRY_LIST_SUCCESS';
export const GROUP_SUBSCRIBED_COUNTRY_LIST_FAILURE = 'GROUP_SUBSCRIBED_COUNTRY_LIST_FAILURE';

export const NOTIFICATION_SET = 'NOTIFICATION_SET';
export const SHOW_MODAL = 'SHOW_MODAL';

export const RESET_PAGINATION = 'RESET_PAGINATION';

export const NOT_AUTHORIZED = 'NOT_AUTHORIZED';

export const SET_UPLOADED_INVALID_ISINS_SUCCESS = 'SET_UPLOADED_INVALID_ISINS_SUCCESS';
export const SET_UPLOADED_INVALID_ISINS_RESET = 'SET_UPLOADED_INVALID_ISINS_RESET';

export const SET_UPLOADED_NOT_SUPPORTED_ISINS_SUCCESS = 'SET_UPLOADED_NOT_SUPPORTED_ISINS_SUCCESS';
export const SET_UPLOADED_NOT_SUPPORTED_ISINS_RESET = 'SET_UPLOADED_NOT_SUPPORTED_ISINS_RESET';
