import get from 'lodash/get';

import * as profileTypes from '../types/profiles';
import * as profileApi from '../../api/services/profiles';
import { getIsFetching } from '../helpers/createList';
import { getParamObjectKeys, updateUrl } from '../../common/helpers/url';
import { setNotification, showModal } from './common';
import * as subscriptionApi from '../../api/services/subscriptions';
import { getSearchSubscriptionList } from './subscription';
import * as userApi from '../../api/services/users';
import * as userTypes from '../types/users';
import { dataProviderAccesses } from '../../api/services/profiles';
import { GET_USER_DATA_PROVIDER_ACCESSES_SUCCESS } from '../types/profiles';

export const setProfileFilters = filters => dispatch =>
  dispatch({ type: profileTypes.SET_PROFILES_FILTERS, filters });

export const updateProfileList = (key, value) => dispatch =>
  dispatch({ type: profileTypes.SEARCH_PROFILES_LIST_UPDATE, key, value });

export const getAllProfileList = groupId => (dispatch, getState) => {
  const state = getState();
  const {
    profiles: { profileList },
  } = state;
  const isFetching = getIsFetching(profileList);
  if (isFetching) return;

  dispatch({ type: profileTypes.PROFILES_LIST_REQUEST });

  profileApi
    .getAllProfiles(groupId)
    .then(results => {
      return dispatch({
        type: profileTypes.PROFILES_LIST_SUCCESS,
        results: get(results, 'data', []),
      });
    })
    .catch(() => {
      return dispatch({ type: profileTypes.PROFILES_LIST_FAILURE, results: [] });
    });
};

export const getAllProfileListByGroupId = groupId => (dispatch, getState) => {
  const state = getState();
  const {
    profiles: { profileList },
  } = state;
  const isFetching = getIsFetching(profileList);
  if (isFetching) return;

  dispatch({ type: profileTypes.PROFILES_LIST_REQUEST });

  profileApi
    .getAllProfiles(groupId)
    .then(results => {
      return dispatch({
        type: profileTypes.PROFILES_LIST_SUCCESS,
        results: get(results, 'data', []),
      });
    })
    .catch(() => {
      return dispatch({ type: profileTypes.PROFILES_LIST_FAILURE, results: [] });
    });
};

export const getSearchProfileList = filters => (dispatch, getState) => {
  const state = getState();
  const {
    profiles: { profileList, filters: stateFilters },
    groups: { selectedGroupId },
  } = state;
  const isFetching = getIsFetching(profileList);
  if (isFetching) return;

  dispatch({ type: profileTypes.SEARCH_PROFILES_LIST_REQUEST });
  const queryParams = getParamObjectKeys(filters || stateFilters);
  updateUrl(queryParams);

  profileApi
    .getSearchProfiles(selectedGroupId, queryParams)
    .then(results => {
      dispatch({
        type: profileTypes.SEARCH_PROFILES_LIST_SUCCESS,
        results: get(results, 'data.content', []),
      });
      dispatch({
        type: profileTypes.SEARCH_PROFILES_TOTAL_RECEIVE,
        total: get(results, 'data.totalElements', 0),
      });
    })
    .catch(() => {
      dispatch({ type: profileTypes.SEARCH_PROFILES_LIST_FAILURE, results: [] });
      dispatch({ type: profileTypes.SEARCH_PROFILES_TOTAL_RECEIVE });
    });
};

export const setProfile = profile => dispatch =>
  dispatch({ type: profileTypes.PROFILE_SUCCESS, resource: profile });

export const getProfileById = id => (dispatch, getState) => {
  const state = getState();
  const {
    profiles: { profile },
  } = state;
  const isFetching = getIsFetching(profile);
  if (isFetching) return;

  dispatch({ type: profileTypes.PROFILE_REQUEST });
  profileApi
    .getProfileById(id)
    .then(results => {
      dispatch(setProfile(get(results, 'data', null)));
    })
    .catch(() => {
      dispatch({
        type: profileTypes.PROFILE_RESET,
      });
    });
};

export const resetProfile = () => dispatch => dispatch({ type: profileTypes.PROFILE_RESET });

export const setEditProfileId = id => dispatch => {
  dispatch({ type: profileTypes.SET_EDIT_PROFILE_ID, id });
};

export const setShowProfileModal = show => dispatch => {
  dispatch({ type: profileTypes.SET_SHOW_PROFILE_MODAL, show });
};

export const createProfile = profile => dispatch =>
  profileApi.createProfile(profile).then(result => {
    const profileId = get(result, 'data.id');
    const profileName = get(result, 'data.name');
    if (profileId) {
      dispatch(getSearchProfileList());
      dispatch(resetProfile());
      dispatch(setEditProfileId());
      dispatch(setShowProfileModal());
      dispatch(setNotification({ path: 'profiles.create', listOfLabels: [profileName] }));
    }
  });

export const updateProfile = profile => dispatch =>
  profileApi.updateProfile(profile.id, profile).then(result => {
    const data = get(result, 'data', null);
    if (data) {
      const profileName = get(data, 'name', '');
      dispatch(getSearchProfileList());

      dispatch(resetProfile());
      dispatch(setEditProfileId());
      dispatch(setShowProfileModal());
      dispatch(setNotification({ path: 'profiles.update', listOfLabels: [profileName] }));
    }
  });

export const deactivateProfile = id => dispatch =>
  profileApi.deactivateProfile(id).then(
    result => {
      const profile = get(result, 'data', {});
      dispatch(setNotification({ path: 'profiles.deactivate', listOfLabels: [profile.name] }));
      dispatch(getSearchProfileList());
    },
    error => {
      dispatch(showModal('ERROR_MODAL', { title: 'Error', errorMsg: error.data.message }));
    },
  );

export const reactivateProfile = id => dispatch =>
  profileApi.reactivateProfile(id).then(result => {
    const profile = get(result, 'data', {});
    dispatch(setNotification({ path: 'profiles.reactivate', listOfLabels: [profile.name] }));
    dispatch(getSearchProfileList());
  });

export const getDataProviderAccesses = () => dispatch => {
  profileApi
    .getDataProviderAccesses()
    .then(results => {
      return dispatch({
        type: profileTypes.DATA_PROVIDER_ACCESSES_SUCCESS,
        results: get(results, 'data', []),
      });
    })
    .catch(() => {
      dispatch({ type: profileTypes.DATA_PROVIDER_ACCESSES_FAILURE });
    });
};
