import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import qs from 'qs';

export const createSearchQuery = (params, index) => {
  const query = Object.keys(params)
    .map(key => {
      if (index === 'sort') {
        return params[key] ? `sort=${key},${params[key]}` : `sort=${key}`;
      }
      if (params[key] instanceof Array) {
        return params[key].map(param => `${key}=${param}`).join('&');
      }
      return `${key}=${params[key]}`;
    })
    .join('&');

  return query ? `${query}` : '';
};

export const getParamObjectKeys = filter => {
  return Object.keys(filter)
    .map(mainKey => createSearchQuery(filter[mainKey], mainKey))
    .filter(val => val) // add to remove unnecessary "&"
    .join('&');
};

function parseSortFields(sort) {
  const isSortArray = isArray(sort);

  if (isSortArray) {
    return sort.reduce((acc, str) => {
      const [first, second] = str.split(',');
      return {
        ...acc,
        [first]: second || '',
      };
    }, {});
  }
  const [first, second] = sort.split(',');
  return {
    [first]: second || '',
  };
}

export const parseFunc = url => {
  const { page, size, sort = [], ...filters } = qs.parse(url);
  return {
    ...(size && {
      pagination: {
        page,
        size,
      },
    }),
    sort: parseSortFields(sort),
    filter: filters,
  };
};

export const updateUrl = searchQuery => {
  let search = '';
  const separator = '?';

  if (!isEmpty(searchQuery)) search = `${separator}${searchQuery}`;

  const newUrl = `${window.location.origin}${window.location.pathname}${search}`;

  // prevents pushing same url if function won't change url.
  if (window.location.href !== newUrl) {
    window.history.pushState(null, null, newUrl);
  }
};

export const paramObject = (queryString = window.location.search.substring(1)) => {
  return isEmpty(queryString) ? {} : parseFunc(queryString);
};
