import React from 'react';
import PropTypes from 'prop-types';

import UIIcon from '../common/components/UIIcon';
import UITooltip from '../common/components/UITooltip';

const RowOptions = ({ onEdit, onDeactivate, onStatisticsExport }) => (
  <UITooltip
    tooltipClassName='ui-row-option-container'
    placement='left-start'
    trigger='click'
    triggerContent={
      <UIIcon
        icon='dotsVertical'
        iconClassName='dots-icon'
        iconColor='var(--gunmetal)'
        iconSize='1.2rem'
      />
    }
    tooltipContent={
      <ul role='menu' className='ui-dropdown-menu'>
        <li className='ui-dropdown-item' role='menuitem'>
          <button onClick={onEdit} className='d-flex w-100'>
            Edit
          </button>
        </li>
        <li className='ui-dropdown-item' role='menuitem'>
          <button onClick={onDeactivate} className='d-flex w-100'>
            Deactivate
          </button>
        </li>
        <li className='ui-dropdown-item' role='menuitem'>
          <button onClick={onStatisticsExport} className='d-flex w-100'>
            Statistics export
          </button>
        </li>
      </ul>
    }
  />
);

RowOptions.propTypes = {
  onEdit: PropTypes.func,
  onDeactivate: PropTypes.func,
  onStatisticsExport: PropTypes.func,
};

export default RowOptions;
