import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const SidebarContainer = ({ children, staticOpen }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={cx('dashboard-sidebar', { 'static-open': staticOpen }, { open: isOpen })}>
      {React.Children.map(children, child => React.cloneElement(child, { isOpen, toggle }))}
    </div>
  );
};

SidebarContainer.propTypes = {
  children: PropTypes.node,
  staticOpen: PropTypes.bool,
};

export default SidebarContainer;
