export const Menus = {
  groups: 'groups',
  entities: 'entities',
  subscriptions: 'subscriptions',
  profiles: 'profiles',
  users: 'users',
};

export const getActiveRoute = location => {
  return location.pathname.replace('/', '');
};

export const ApplicationsAccessIds = {
  api: 'IN_API',
  light: 'IN_APP_LIGHT',
  premium: 'IN_APP_PREMIUM',
  pamis: 'PAMIS',
  tamis: 'TAMIS',
  samis: 'SAMIS',
};

export const Roles = {
  INAPP_PRODUCT_MANAGEMENT: 'IN_APP_PRODUCT_MANAGEMENT',
};

export function stringifyFields(data, fields, target) {
  fields.forEach(field => {
    if (data[field]) {
      data[field] = data[field][target];
    }
  });
  return data;
}

export function createObjectForUISelect(value) {
  return { code: value, name: value };
}
