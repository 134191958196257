import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Col, Row, Form, FormGroup } from 'reactstrap';

import UIIconButton from '../UIIconButton';
import UIButton from '../UIButton';
import UICheckbox from '../UICheckbox';

const CheckboxFilter = ({ title, filter: { name, value }, checkboxList = [], onFilterChange }) => {
  const [values, setValues] = useState((value && value.split(',')) || []);

  return (
    <div className='filter-dropdown'>
      <Row className='mb-3'>
        <Col className='col-auto mr-auto px-3 body'>{title}</Col>
        <Col className='col-auto ml-auto d-flex px-3'>
          <UIIconButton icon='filter' iconColor='var(--light-grey-blue)' />
        </Col>
      </Row>
      <Row className='mb-2'>
        <Col>
          <Form
            onSubmit={e => {
              e.preventDefault();
              onFilterChange(name, isEmpty(values) ? null : values.join(','));
            }}
            onReset={e => {
              e.preventDefault();
              onFilterChange(name, null);
            }}
          >
            <FormGroup className='mb-2'>
              {checkboxList.map(checkVal => {
                const isChecked = values.includes(checkVal);

                return (
                  <UICheckbox
                    key={checkVal}
                    wrapperClassName='mb-2'
                    label={checkVal}
                    checked={isChecked}
                    onChange={({ target: { checked } }) =>
                      setValues(
                        checked ? [...values, checkVal] : values.filter(item => item !== checkVal),
                      )
                    }
                  />
                );
              })}
            </FormGroup>
            <UIButton className='w-100 mb-2' height={32} type='submit'>
              Search
            </UIButton>
            <UIButton className='w-100' height={32} btnType='outline' type='reset'>
              Reset
            </UIButton>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

CheckboxFilter.propTypes = {
  title: PropTypes.string.isRequired,
  filter: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  }),
  checkboxList: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFilterChange: PropTypes.func,
};

export default CheckboxFilter;
