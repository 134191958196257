import React from 'react';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

const UICustomLoader = ({ loading, loaderLabel }) => {
  if (!loading) return null;

  return (
    <div className='ui-loader-wrapper'>
      <Spinner className='spinner' />
      {loaderLabel && <div className='ml-3'>{loaderLabel}</div>}
    </div>
  );
};

UICustomLoader.propTypes = {
  loading: PropTypes.bool,
  loaderLabel: PropTypes.string,
};

export default UICustomLoader;
