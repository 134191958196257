import get from 'lodash/get';
import saveAs from 'file-saver';
import dayjs from 'dayjs';

import * as subscriptionTypes from '../types/subscriptions';
import * as subscriptionApi from '../../api/services/subscriptions';
import { getIsFetching } from '../helpers/createList';
import { getParamObjectKeys, updateUrl } from '../../common/helpers/url';
import { setNotification } from './common';
import { formatDate } from '../../common/helpers/date';

export const setSubscriptionFilters = filters => dispatch =>
  dispatch({ type: subscriptionTypes.SET_SUBSCRIPTION_FILTERS, filters });

export const updateSubscriptionList = (key, value) => dispatch =>
  dispatch({ type: subscriptionTypes.SEARCH_SUBSCRIPTION_LIST_UPDATE, key, value });

export const getAllSubscriptionList = () => (dispatch, getState) => {
  const state = getState();
  const {
    subscriptions: { subscriptionList },
    groups: { selectedGroupId },
  } = state;
  const isFetching = getIsFetching(subscriptionList);
  if (isFetching) return;

  dispatch({ type: subscriptionTypes.SUBSCRIPTION_LIST_REQUEST });

  subscriptionApi
    .getAllSubscriptions(selectedGroupId)
    .then(results => {
      dispatch({
        type: subscriptionTypes.SUBSCRIPTION_LIST_SUCCESS,
        results: get(results, 'data', []),
      });
    })
    .catch(() => {
      dispatch({ type: subscriptionTypes.SUBSCRIPTION_LIST_FAILURE, results: [] });
    });
};

export const getSearchSubscriptionList = filters => (dispatch, getState) => {
  const state = getState();
  const {
    subscriptions: { subscriptionList, filters: stateFilters },
    groups: { selectedGroupId },
  } = state;
  const isFetching = getIsFetching(subscriptionList);
  if (isFetching) return;

  dispatch({ type: subscriptionTypes.SEARCH_SUBSCRIPTION_LIST_REQUEST });
  const queryParams = getParamObjectKeys(filters || stateFilters);
  updateUrl(queryParams);

  subscriptionApi
    .getSearchSubscriptions(selectedGroupId, queryParams)
    .then(results => {
      dispatch({
        type: subscriptionTypes.SEARCH_SUBSCRIPTION_LIST_SUCCESS,
        results: get(results, 'data.content', []),
      });
      dispatch({
        type: subscriptionTypes.SEARCH_SUBSCRIPTION_TOTAL_RECEIVE,
        total: get(results, 'data.totalElements', 0),
      });
    })
    .catch(() => {
      dispatch({ type: subscriptionTypes.SEARCH_SUBSCRIPTION_LIST_FAILURE, results: [] });
      dispatch({ type: subscriptionTypes.SEARCH_SUBSCRIPTION_TOTAL_RECEIVE });
    });
};

export const setSubscription = subscription => dispatch =>
  dispatch({ type: subscriptionTypes.SUBSCRIPTION_SUCCESS, resource: subscription });

export const getSubscriptionById = id => (dispatch, getState) => {
  const state = getState();
  const {
    subscriptions: { subscription },
  } = state;
  const isFetching = getIsFetching(subscription);
  if (isFetching) return;

  dispatch({ type: subscriptionTypes.SUBSCRIPTION_REQUEST });
  subscriptionApi
    .getSubscriptionById(id)
    .then(results => {
      dispatch(setSubscription(get(results, 'data', null)));
    })
    .catch(() => {
      dispatch({
        type: subscriptionTypes.SUBSCRIPTION_RESET,
      });
    });
};

export const resetSubscription = () => dispatch =>
  dispatch({ type: subscriptionTypes.SUBSCRIPTION_RESET });

export const setEditSubscriptionId = id => dispatch => {
  dispatch({ type: subscriptionTypes.SET_EDIT_SUBSCRIPTION_ID, id });
};

export const setShowSubscriptionModal = show => dispatch => {
  dispatch({ type: subscriptionTypes.SET_SHOW_SUBSCRIPTION_MODAL, show });
};

export const createSubscription = subscription => dispatch =>
  subscriptionApi.createSubscription(subscription).then(result => {
    const subscriptionId = get(result, 'data.id');
    if (subscriptionId) {
      dispatch(getSearchSubscriptionList());
      dispatch(resetSubscription());
      dispatch(setEditSubscriptionId());
      dispatch(setShowSubscriptionModal());
      dispatch(setNotification({ path: 'subscriptions.create', listOfLabels: [] }));
    }
  });

export const updateSubscription = subscription => (dispatch, getState) =>
  subscriptionApi.updateSubscription(subscription.id, subscription).then(result => {
    const data = get(result, 'data', null);
    if (data) {
      const subscriptionList = get(getState(), 'subscriptions.searchSubscriptionList.results', []);
      const subscriptionIndex = subscriptionList.findIndex(({ id }) => id === data.id);
      dispatch(updateSubscriptionList(`${subscriptionIndex}`, data));

      dispatch(resetSubscription());
      dispatch(setEditSubscriptionId());
      dispatch(setShowSubscriptionModal());
      dispatch(setNotification({ path: 'subscriptions.update', listOfLabels: [] }));
    }
  });

export const deactivateSubscription = id => dispatch =>
  subscriptionApi.deactivateSubscription(id).then(result => {
    const subscription = get(result, 'data', {});
    dispatch(
      setNotification({ path: 'subscriptions.deactivate', listOfLabels: [subscription.docTypeId] }),
    );
    dispatch(getSearchSubscriptionList());
  });

export const reactivateSubscription = id => dispatch =>
  subscriptionApi.reactivateSubscription(id).then(result => {
    const subscription = get(result, 'data', {});
    dispatch(
      setNotification({ path: 'subscriptions.reactivate', listOfLabels: [subscription.docTypeId] }),
    );
    dispatch(getSearchSubscriptionList());
  });

export const exportSubscriptions = () => (dispatch, getState) => {
  const state = getState();
  const {
    groups: { selectedGroupId },
  } = state;

  subscriptionApi.exportSubscriptions(selectedGroupId).then(({ data }) => {
    saveAs(data, `${selectedGroupId}-subscriptions-${formatDate(dayjs())}.csv`);
    dispatch(
      setNotification({ path: 'subscriptions.export', listOfLabels: ['Subscription list'] }),
    );
  });
};

export const getAdditionalLanguages = groupId => dispatch => {
  dispatch({ type: subscriptionTypes.ADDITIONAL_LANGUAGES_REQUEST });
  subscriptionApi.getLanguagesByGroupId(groupId).then(languages => {
    dispatch({
      type: subscriptionTypes.ADDITIONAL_LANGUAGES_SUCCESS,
      resource: get(languages, 'data', null),
    });
  });
};
