import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import cx from 'classnames';

import UIIconButton from '../../common/components/UIIconButton';
import UIIcon from '../../common/components/UIIcon';
import SidebarContainer from '../../containers/SidebarContainer';

const SidebarOpen = ({ sidebarState: { mainLabel, mainLabelIcon, list }, toggle }) => (
  <Container className='ui-sidebar pt-4'>
    <Row>
      <Col sm={12} className='d-flex align-items-center pb-4'>
        <UIIcon
          icon={mainLabelIcon}
          iconColor='var(--charcoal-grey)'
          iconSize='1.5rem'
          iconClassName='mr-3'
        />
        <div className='text-no-wrap body--bold'>{mainLabel}</div>
        <UIIconButton
          className='ml-auto'
          onClick={toggle}
          icon='keyboardTab'
          iconColor='var(--gunmetal)'
          iconRotate={180}
        />
      </Col>
      {list.map(({ label, icon, onClick }) => {
        const isActive = label === 'List';
        return (
          <Col
            sm={12}
            className={cx('d-flex align-items-center mb-3 item text-no-wrap', {
              active: isActive,
            })}
            onClick={onClick}
            key={icon}
          >
            {icon && (
              <UIIcon
                icon={icon}
                iconColor={isActive ? 'var(--dodger-blue)' : 'var(--gunmetal)'}
                iconSize='1rem'
                iconClassName='mr-2'
              />
            )}
            <div className={cx({ 'paragraph--bold': isActive })}>{label}</div>
          </Col>
        );
      })}
    </Row>
  </Container>
);

const SidebarCollapsed = ({ sidebarState: { list }, toggle }) => (
  <Container className='ui-sidebar__collapsed'>
    <div className='d-flex align-items-center justify-content-center mb-4'>
      <UIIconButton
        onClick={toggle}
        icon='keyboardTab'
        iconColor='var(--gunmetal)'
        iconRotate={0}
      />
    </div>
    {list.map(({ label, icon, onClick }) => {
      const isActive = label === 'List';
      return (
        <UIIconButton
          key={icon}
          className={cx('item text-no-wrap mb-3 ', {
            active: isActive,
          })}
          onClick={onClick}
          icon={icon}
          iconColor={isActive ? 'var(--dodger-blue)' : 'var(--gunmetal)'}
          iconSize='1rem'
        />
      );
    })}
  </Container>
);

const UISidebarContainer = ({ isOpen, ...props }) =>
  isOpen ? <SidebarOpen {...props} /> : <SidebarCollapsed {...props} />;

const UISidebar = props => (
  <SidebarContainer>
    <UISidebarContainer {...props} />
  </SidebarContainer>
);

SidebarOpen.propTypes = {
  toggle: PropTypes.func,
  sidebarState: PropTypes.shape({
    mainLabel: PropTypes.string.isRequired,
    mainLabelIcon: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(PropTypes.object),
  }),
};

SidebarCollapsed.propTypes = {
  toggle: PropTypes.func,
  sidebarState: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.object),
  }),
};

UISidebarContainer.propTypes = {
  isOpen: PropTypes.bool,
};

export default UISidebar;
