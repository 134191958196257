import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import UIIcon from './UIIcon';

function getNextSorting(current) {
  if (!current && current !== '') {
    return '';
  } else if (current === '') {
    return 'desc';
  }
  return null;
}

const UISortingButton = ({ title, sort: { name, value }, onChange }) => {
  const isAsc = value === '';
  const isDesc = value === 'desc';
  const isActive = isAsc || isDesc;

  return (
    <button
      onClick={() => onChange(name, getNextSorting(value))}
      className={cx('d-flex', 'align-items-center', 'body--bold', 'mr-3', {
        'with-opacity': !isActive,
        active: isActive,
      })}
    >
      {isActive && (
        <UIIcon
          icon='chevronDown'
          iconColor={isActive ? 'var(--charcoal-grey)' : 'var(--light-grey-blue)'}
          iconClassName='mr-1'
          iconRotate={isAsc ? 180 : 0}
        />
      )}
      {title}
    </button>
  );
};

UISortingButton.propTypes = {
  title: PropTypes.string.isRequired,
  sort: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
};

export default UISortingButton;
