import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { hideModal } from '../redux/actions/common';
import UIDeactivateModal from '../common/components/modals/UIDeactivateModal';
import UIErrorDisplayModal from '../common/components/modals/UIErrorDisplayModal';
import UIUploadModal from '../common/components/modals/UIUploadModal';
import GroupFormModal from '../groups/GroupFormModal';
import UIStatisticsModal from '../common/components/modals/UIStatisticsModal';
import UIDeleteModal from '../common/components/modals/UIDeleteModal';
import UIMoveUserModal from '../common/components/modals/UIMoveUserModal';
import UIConfirmationModal from '../common/components/modals/UIConfirmationModal';

const MODAL_COMPONENTS = {
  CONFIRMATION_MODAL: UIConfirmationModal,
  DEACTIVATE_MODAL: UIDeactivateModal,
  UPLOAD_MODAL: UIUploadModal,
  ERROR_MODAL: UIErrorDisplayModal,
  GROUP_MODAL: GroupFormModal,
  STATISTICS_EXPORT_MODAL: UIStatisticsModal,
  DELETE_MODAL: UIDeleteModal,
  MOVE_USER_MODAL: UIMoveUserModal,
};

const UIModalContainer = ({ modal, toggle }) => {
  if (!modal) return null;

  const { modalType, modalProps } = modal;
  const SpecificModal = MODAL_COMPONENTS[modalType];
  return <SpecificModal toggle={toggle} {...modalProps} />;
};

UIModalContainer.propTypes = {
  modal: PropTypes.oneOfType([PropTypes.object]),
  toggle: PropTypes.func,
};

const mapStateToProps = ({ common }) => ({
  modal: common.modal,
});

const mapDispatchToProps = dispatch => {
  return {
    toggle: () => dispatch(hideModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UIModalContainer);
