import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import TooltipTrigger from 'react-popper-tooltip';

const Trigger = ({ getTriggerProps, triggerRef, triggerContent, triggerClassName }) => (
  <span
    {...getTriggerProps({
      ref: triggerRef,
      className: cx('trigger', triggerClassName),
    })}
  >
    {triggerContent}
  </span>
);

const Tooltip = ({ getTooltipProps, tooltipRef, tooltipContent, tooltipClassName }) => (
  <div
    {...getTooltipProps({
      ref: tooltipRef,
      className: cx('tooltip-content', tooltipClassName),
    })}
  >
    {tooltipContent}
  </div>
);

const UITooltip = ({ placement, modifiers, trigger = 'click', ...props }) => {
  return (
    <TooltipTrigger
      placement={placement || 'end'}
      trigger={trigger}
      modifiers={modifiers}
      tooltip={args => <Tooltip {...args} {...props} />}
      {...props}
    >
      {args => <Trigger {...args} {...props} />}
    </TooltipTrigger>
  );
};

Tooltip.propTypes = {
  getTooltipProps: PropTypes.func,
  tooltipRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  tooltipContent: PropTypes.node,
  tooltipClassName: PropTypes.string,
};

Trigger.propTypes = {
  getTriggerProps: PropTypes.func,
  triggerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  triggerContent: PropTypes.node,
  triggerClassName: PropTypes.string,
};

UITooltip.propTypes = {
  placement: PropTypes.string,
  trigger: PropTypes.string,
  modifiers: PropTypes.oneOfType([PropTypes.object]),
};

export default UITooltip;
