import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withKeycloak } from '@react-keycloak/web';
import { connect } from 'react-redux';

import { logout } from '../redux/actions/auth';
import UICustomLoader from '../common/components/UICustomLoader';

class Logout extends Component {
  componentDidMount() {
    const { onLogout } = this.props;
    onLogout();
  }

  render() {
    return <UICustomLoader loading loaderLabel='Logging out...' />;
  }
}

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch(logout()),
});

Logout.propTypes = {
  onLogout: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(withKeycloak(Logout));
