export const GROUP_LIST_RESET = 'GROUP_LIST_RESET';
export const GROUP_LIST_REQUEST = 'GROUP_LIST_REQUEST';
export const GROUP_LIST_SUCCESS = 'GROUP_LIST_SUCCESS';
export const GROUP_LIST_FAILURE = 'GROUP_LIST_FAILURE';
export const GROUP_LIST_REMOVE = 'GROUP_LIST_REMOVE';
export const GROUP_LIST_UPDATE = 'GROUP_LIST_UPDATE';

export const SEARCH_GROUP_LIST_RESET = 'SEARCH_GROUP_LIST_RESET';
export const SEARCH_GROUP_LIST_REQUEST = 'SEARCH_GROUP_LIST_REQUEST';
export const SEARCH_GROUP_LIST_SUCCESS = 'SEARCH_GROUP_LIST_SUCCESS';
export const SEARCH_GROUP_LIST_FAILURE = 'SEARCH_GROUP_LIST_FAILURE';
export const SEARCH_GROUP_LIST_REMOVE = 'SEARCH_GROUP_LIST_REMOVE';
export const SEARCH_GROUP_LIST_UPDATE = 'SEARCH_GROUP_LIST_UPDATE';

export const SEARCH_GROUP_TOTAL_RECEIVE = 'SEARCH_GROUP_TOTAL_RECEIVE';

export const SET_SELECTED_GROUP = 'SET_SELECTED_GROUP';
export const SET_GROUP_FILTERS = 'SET_GROUP_FILTERS';

export const SET_SHOW_GROUP_MODAL = 'SET_SHOW_GROUP_MODAL';
export const SET_EDIT_GROUP_ID = 'SET_EDIT_GROUP_ID';

export const APPLICATIONS_GROUP_LIST_REQUEST = 'APPLICATIONS_GROUP_LIST_REQUEST';
export const APPLICATIONS_GROUP_LIST_SUCCESS = 'APPLICATIONS_GROUP_LIST_SUCCESS';
export const APPLICATIONS_GROUP_LIST_FAILURE = 'APPLICATIONS_GROUP_LIST_FAILURE';

export const LANGUAGES_LIST_REQUEST = 'LANGUAGES_LIST_REQUEST';
export const LANGUAGES_LIST_SUCCESS = 'LANGUAGES_LIST_SUCCESS';
export const LANGUAGES_LIST_FAILURE = 'LANGUAGES_LIST_FAILURE';

export const GROUP_RESET = 'GROUP_RESET';
export const GROUP_REQUEST = 'GROUP_REQUEST';
export const GROUP_SUCCESS = 'GROUP_SUCCESS';

export const STATISTICS = 'STATISTICS';
export const STATISTICS_FAILURE = 'STATISTICS_FAILURE';

export const APPLICATIONS_LIST_RESET = 'APPLICATIONS_LIST_RESET';
export const APPLICATIONS_LIST_REQUEST = 'APPLICATIONS_LIST_REQUEST';
export const APPLICATIONS_LIST_SUCCESS = 'APPLICATIONS_LIST_SUCCESS';
export const APPLICATIONS_LIST_REMOVE = 'APPLICATIONS_LIST_REMOVE';
export const APPLICATIONS_LIST_UPDATE = 'APPLICATIONS_LIST_UPDATE';

export const EXTERNAL_DATA_PROVIDERS_REQUEST = 'EXTERNAL_DATA_PROVIDERS_REQUEST';
export const EXTERNAL_DATA_PROVIDERS_SUCCESS = 'EXTERNAL_DATA_PROVIDERS_SUCCESS';

export const SANCTION_SECURITIES_SOURCES_REQUEST = 'SANCTION_SECURITIES_SOURCES_REQUEST';
export const SANCTION_SECURITIES_SOURCES_SUCCESS = 'SANCTION_SECURITIES_SOURCES_SUCESS';

export const ESG_PROVIDERS_REQUEST = 'ESG_PROVIDERS_REQUEST';
export const ESG_PROVIDERS_SUCCESS = 'ESG_PROVIDERS_SUCCESS';

export const FUND_INFO_FEEDS_REQUEST = 'FUND_INFO_FEEDS_REQUEST';
export const FUND_INFO_FEEDS_SUCCESS = 'FUND_INFO_FEEDS_SUCCESS';

export const INVESTOR_SUITABILITY_PROVIDERS_REQUEST = 'INVESTOR_SUITABILITY_PROVIDERS_REQUEST';
export const INVESTOR_SUITABILITY_PROVIDERS_SUCCESS = 'INVESTOR_SUITABILITY_PROVIDERS_SUCCESS';

export const INVESTOR_SUITABILITY_KNOWLEDGE_REQUEST = 'INVESTOR_SUITABILITY_KNOWLEDGE_REQUEST';
export const INVESTOR_SUITABILITY_KNOWLEDGE_SUCCESS = 'INVESTOR_SUITABILITY_KNOWLEDGE_SUCCESS';

export const ICON_COLORS_LIST_REQUEST = 'ICON_COLORS_LIST_REQUEST';
export const ICON_COLORS_LIST_SUCCESS = 'ICON_COLORS_LIST_SUCCESS';
export const ICON_COLORS_LIST_RESET = 'ICON_COLORS_LIST_RESET';
export const ICON_COLORS_LIST_FAILURE = 'ICON_COLORS_LIST_FAILURE';
