import { combineReducers } from 'redux';
import createList from '../helpers/createList';
import * as types from '../types/management';
import createReducer from '../helpers/createReducer';

const cachesListActions = {
  RESET: types.CACHES_LIST_RESET,
  REQUEST: types.CACHES_LIST_REQUEST,
  SUCCESS: types.CACHES_LIST_SUCCESS,
  FAILURE: types.CACHES_LIST_FAILURE,
  REMOVE: types.CACHES_LIST_REMOVE,
};

const featuresListActions = {
  REQUEST: types.FEATURES_LIST_REQUEST,
  SUCCESS: types.FEATURES_LIST_SUCCESS,
  FAILURE: types.FEATURES_LIST_FAILURE,
};

export default combineReducers({
  cachesList: createList(cachesListActions),
  total: createReducer(0, {
    [types.CACHES_LIST_TOTAL_RECEIVE]: (state, action) => action.total || 0,
  }),
  featuresList: createList(featuresListActions),
  totalFeatures: createReducer(0, {
    [types.FEATURES_LIST_TOTAL_RECEIVE]: (state, action) => action.total || 0,
  }),
});
