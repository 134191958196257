import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Redirect } from 'react-router-dom';

import TableRender from '../containers/TableRender';
import { getIsFetching, getResults } from '../redux/helpers/createList';
import { paramObject } from '../common/helpers/url';
import RowOptions from './RowOptions';
import {
  reactivateProfile,
  getSearchProfileList,
  setEditProfileId,
  setProfile,
  setProfileFilters,
  setShowProfileModal,
  deactivateProfile,
} from '../redux/actions/profiles';
import { BadgeListWithDots } from '../common/components/Table/BadgeCell';
import ProfileFormModal from './ProfileFormModal';
import UISidebar from '../common/components/UISidebar';
import { showModal } from '../redux/actions/common';
import ActiveIndicator from '../common/components/Table/ActiveIndicator';
import { getResource } from '../redux/helpers/createResource';
import { getGroupById as _getGroupById } from '../redux/actions/groups';
import { hasRole, Roles } from '../common/helpers/roles';

const Profiles = ({
  profileList,
  isLoading,
  getProfiles,
  filters,
  total,
  setFilters,
  groupId,
  showProfileModal,
  setModalProfile,
  showDeactivateModal,
  deactivateProfileById,
  setProfileForDuplicate,
  selectedGroup,
  getGroupById,
  reactivateProfileById,
  userInformation,
}) => {
  const [onlyActive, setOnlyActive] = useState(filters.pagination.onlyActive);
  // This works, but we might need to consider another option, like not using a "Router" anymore.
  if (groupId === null) return <Redirect to='/' />;

  const applyFiltersAndFetch = filter => {
    setFilters(filter);
    getProfiles(filter);
  };

  useEffect(() => {
    getGroupById(groupId);
    const urlFilters = paramObject();
    const newFilter = isEmpty(urlFilters) ? filters : { ...filters, ...urlFilters };
    applyFiltersAndFetch(newFilter);
  }, [groupId]);

  const generateColumns = () => {
    const columns = [
      {
        title: 'Profile',
        filterKey: 'name',
        sortKey: 'name',
        accessor: 'name',
        // eslint-disable-next-line
        Cell: ({ original: { name, active } }) => (
          <div className='d-flex align-items-center'>
            <ActiveIndicator active={active} />
            {name}
          </div>
        ),
      },
      {
        title: 'Entities/y',
        filterKey: 'entityName',
        // eslint-disable-next-line
        Cell: ({ original: { entities } }) => (
          // eslint-disable-next-line
          <div className='text-overflow-dots'>{entities.map(({ name }) => name).join(', ')}</div>
        ),
      },
      {
        title: 'Application',
        sortKey: 'applications',
        filterKey: 'applications',
        // eslint-disable-next-line
        Cell: access => {
          const {
            original: { applicationAccess },
          } = access;
          return <div>{applicationAccess ? applicationAccess.name : ''}</div>;
        },
      },
      {
        title: 'Product Access',
        sortKey: 'productAccess',
        filterKey: 'productAccess',
        // eslint-disable-next-line
        Cell: access => {
          const {
            original: { dataProviderAccesses },
          } = access;
          return (
            <div>
              {dataProviderAccesses ? dataProviderAccesses.map(d => d.name).join(', ') : ''}
            </div>
          );
        },
      },
      {
        title: 'Doc type',
        filterKey: 'docTypeId',
        // eslint-disable-next-line
        Cell: row => {
          const {
            original: { profileAccesses },
          } = row;

          if (isEmpty(profileAccesses)) return <div className='ui-td__no-val'>N/A</div>;

          return (
            <div className='d-flex flex-column justify-content-between'>
              {profileAccesses.map(({ docTypeId }) => (
                <div key={docTypeId}>{docTypeId || ''}</div>
              ))}
            </div>
          );
        },
      },
      {
        title: 'Source countries',
        filterKey: 'sourceCountries',
        Cell: row => {
          const {
            original: { profileAccesses },
          } = row;

          if (isEmpty(profileAccesses)) return <div className='ui-td__no-val'>N/A</div>;

          return (
            <div className='d-flex flex-column justify-content-between w-100'>
              {profileAccesses.map(({ sourceCountries, docTypeId }) => (
                <BadgeListWithDots
                  key={`${docTypeId}-inApp`}
                  array={sourceCountries}
                  ppcArray={[]}
                />
              ))}
            </div>
          );
        },
        width: 250,
      },
      {
        title: 'Target/s',
        filterKey: 'targetCountries',
        Cell: row => {
          const {
            original: { profileAccesses },
          } = row;

          if (isEmpty(profileAccesses)) return <div className='ui-td__no-val'>N/A</div>;

          return (
            <div className='d-flex flex-column justify-content-between w-100'>
              {profileAccesses.map(({ targetCountries, docTypeId }) => (
                <BadgeListWithDots key={`${docTypeId}-api`} array={targetCountries} />
              ))}
            </div>
          );
        },
        width: 400,
      },
    ];

    if (hasRole(Roles.amtClientSetup, userInformation?.amtRoles)) {
      columns.push({
        Header: () => '',
        // eslint-disable-next-line
        Cell: ({ original: { id, active, ...rest } }) => (
          <RowOptions
            onEdit={e => {
              e.preventDefault();
              setModalProfile(id, true);
            }}
            onDeactivate={e => {
              e.preventDefault();
              showDeactivateModal('DEACTIVATE_MODAL', {
                title: 'Do you really want to deactivate the profile?',
                submitBtnTitle: 'Deactivate',
                subtitle:
                  'There is no way back from deleting the profile. You will lose all the data.',
                backBtnTitle: 'Go back to profiles dashboard',
                onSubmit: () => deactivateProfileById(id),
              });
            }}
            onReactivate={() => reactivateProfileById(id)}
            onDuplicate={e => {
              e.preventDefault();
              setModalProfile(null, true);
              setProfileForDuplicate(rest);
            }}
            isActive={active}
          />
        ),
        maxWidth: 40,
        className: 'h-100',
      });
    }
    return columns;
  };

  const generateSidebarStateList = () => {
    const list = [
      {
        label: 'List',
        icon: 'listSquare',
        visible: true,
      },
    ];

    if (hasRole(Roles.amtClientSetup, userInformation?.amtRoles)) {
      list.push(
        {
          label: 'Add new',
          icon: 'plus',
          onClick: e => {
            e.preventDefault();
            setModalProfile(null, true);
          },
        },
        {
          label: onlyActive ? 'Only active entries' : 'All entries',
          icon: onlyActive ? 'switchClose' : 'switch',
          onClick: e => {
            e.preventDefault();
            const newFilter = {
              pagination: {
                page: 0,
                size: 10,
                onlyActive: !onlyActive,
              },
            };
            setOnlyActive(!onlyActive);
            getProfiles(newFilter);
            setFilters(newFilter);
          },
        },
      );
    }
    return list;
  };

  const sidebarState = {
    mainLabel: 'Profiles',
    mainLabelIcon: 'accountBadge',
    list: generateSidebarStateList(),
  };

  const noDataState = {
    label: 'profile',
    onClick: e => {
      e.preventDefault();
      setModalProfile(null, true);
    },
  };

  return (
    <div className='content__with-sidebar'>
      <UISidebar sidebarState={sidebarState} />
      <TableRender
        canRowSelect={false}
        isLoading={isLoading}
        data={profileList}
        total={total}
        filters={filters}
        noDataState={noDataState}
        columns={generateColumns()}
        onFiltersAndSortingChange={applyFiltersAndFetch}
      />
      {showProfileModal && <ProfileFormModal selectedGroup={selectedGroup} />}
    </div>
  );
};

Profiles.propTypes = {
  profileList: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  filters: PropTypes.oneOfType([PropTypes.object]),
  total: PropTypes.number,
  setFilters: PropTypes.func,
  getProfiles: PropTypes.func,
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setModalProfile: PropTypes.func,
  showProfileModal: PropTypes.bool,
  showDeactivateModal: PropTypes.func,
  deactivateProfileById: PropTypes.func,
  reactivateProfileById: PropTypes.func,
  setProfileForDuplicate: PropTypes.func,
  selectedGroup: PropTypes.oneOfType([PropTypes.object]),
  getGroupById: PropTypes.func,
};

const mapStateToProps = ({ groups, profiles, users }) => ({
  profileList: getResults(profiles.searchProfileList),
  isLoading: getIsFetching(profiles.searchProfileList),
  filters: profiles.filters,
  total: profiles.total,
  groupId: groups.selectedGroupId,
  showProfileModal: profiles.showProfileModal,
  selectedGroup: getResource(groups.group),
  userInformation: getResults(users.userInformation),
});

const mapDispatchToProps = dispatch => ({
  setFilters: filters => dispatch(setProfileFilters(filters)),
  getProfiles: filters => dispatch(getSearchProfileList(filters)),
  setModalProfile: (id, show) => {
    dispatch(setEditProfileId(id));
    dispatch(setShowProfileModal(show));
  },
  setProfileForDuplicate: profile => dispatch(setProfile(profile)),
  showDeactivateModal: (type, props) => dispatch(showModal(type, props)),
  deactivateProfileById: id => dispatch(deactivateProfile(id)),
  reactivateProfileById: id => dispatch(reactivateProfile(id)),
  getGroupById: groupId => dispatch(_getGroupById(groupId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profiles);
