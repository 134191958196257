import get from 'lodash/get';
import { getIsFetching } from '../helpers/createList';
import * as managementTypes from '../types/management';
import * as managementApi from '../../api/services/management';

export const getAllCachesList = () => (dispatch, getState) => {
  const state = getState();
  const {
    management: { cachesList },
  } = state;
  const isFetching = getIsFetching(cachesList);
  if (isFetching) return;

  dispatch({ type: managementTypes.CACHES_LIST_REQUEST });

  managementApi
    .getAllCaches()
    .then(results => {
      dispatch({
        type: managementTypes.CACHES_LIST_TOTAL_RECEIVE,
        total: get(results, 'data', []).length,
      });
      return dispatch({
        type: managementTypes.CACHES_LIST_SUCCESS,
        results: get(results, 'data', []),
      });
    })
    .catch(() => {
      return dispatch({ type: managementTypes.CACHES_LIST_FAILURE, results: [] });
    });
};

export const evictsAllCaches = () => dispatch => {
  dispatch({ type: managementTypes.EVICTS_ALL_CACHES_REQUEST });
  managementApi
    .evictsAllCaches()
    .then(() => {
      dispatch({
        type: managementTypes.EVICTS_ALL_CACHES_SUCCESS,
      });
    })
    .catch(() => {
      return dispatch({
        type: managementTypes.EVICTS_ALL_CACHES_FAILURE,
      });
    });
};

export const onEvictCacheByName = name => dispatch => {
  dispatch({ type: managementTypes.EVICTS_CACHE_BY_NAME_REQUEST });
  managementApi
    .evictsCacheByName(name)
    .then(() => {
      dispatch({
        type: managementTypes.EVICTS_CACHE_BY_NAME_SUCCESS,
      });
    })
    .catch(() => {
      return dispatch({
        type: managementTypes.EVICTS_CACHE_BY_NAME_FAILURE,
      });
    });
};

export const getAllFeaturesList = () => (dispatch, getState) => {
  const state = getState();
  const {
    management: { featuresList },
  } = state;
  const isFetching = getIsFetching(featuresList);
  if (isFetching) return;

  dispatch({ type: managementTypes.FEATURES_LIST_REQUEST });

  managementApi
    .getAllFeatures()
    .then(results => {
      dispatch({
        type: managementTypes.FEATURES_LIST_TOTAL_RECEIVE,
        total: get(results, 'data', []).length,
      });
      return dispatch({
        type: managementTypes.FEATURES_LIST_SUCCESS,
        results: get(results, 'data', []),
      });
    })
    .catch(() => {
      return dispatch({ type: managementTypes.FEATURES_LIST_FAILURE, results: [] });
    });
};

export const toggleFeature = (id, payload) => dispatch => {
  dispatch({ type: managementTypes.TOGGLE_FEATURE_REQUEST });
  managementApi
    .toggleFeatureFlag(id, payload)
    .then(() => {
      dispatch({
        type: managementTypes.TOGGLE_FEATURE_SUCCESS,
      });
    })
    .catch(() => {
      return dispatch({
        type: managementTypes.TOGGLE_FEATURE_FAILURE,
      });
    });
};
