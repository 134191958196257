export const CACHES_LIST_RESET = 'CACHES_LIST_RESET';
export const CACHES_LIST_REQUEST = 'CACHES_LIST_REQUEST';
export const CACHES_LIST_SUCCESS = 'CACHES_LIST_SUCCESS';
export const CACHES_LIST_FAILURE = 'CACHES_LIST_FAILURE';
export const CACHES_LIST_REMOVE = 'CACHES_LIST_REMOVE';

export const CACHES_LIST_TOTAL_RECEIVE = 'CACHES_LIST_TOTAL_RECEIVE';

export const EVICTS_ALL_CACHES_REQUEST = 'EVICTS_ALL_CACHES_REQUEST';
export const EVICTS_ALL_CACHES_SUCCESS = 'EVICTS_ALL_CACHES_SUCCESS';
export const EVICTS_ALL_CACHES_FAILURE = 'EVICTS_ALL_CACHES_FAILURE';

export const EVICTS_CACHE_BY_NAME_REQUEST = 'EVICTS_CACHE_BY_NAME_REQUEST';
export const EVICTS_CACHE_BY_NAME_SUCCESS = 'EVICTS_CACHE_BY_NAME_SUCCESS';
export const EVICTS_CACHE_BY_NAME_FAILURE = 'EVICTS_CACHE_BY_NAME_FAILURE';

export const EVICTS_CACHE_BY_NAME_AND_KEY_REQUEST = 'EVICTS_CACHE_BY_NAME_AND_KEY_REQUEST';
export const EVICTS_CACHE_BY_NAME_AND_KEY_SUCCESS = 'EVICTS_CACHE_BY_NAME_AND_KEY_SUCCESS';
export const EVICTS_CACHE_BY_NAME_AND_KEY_FAILURE = 'EVICTS_CACHE_BY_NAME_AND_KEY_FAILURE';

export const FEATURES_LIST_REQUEST = 'FEATURES_LIST_REQUEST';
export const FEATURES_LIST_SUCCESS = 'FEATURES_LIST_SUCCESS';
export const FEATURES_LIST_FAILURE = 'FEATURES_LIST_FAILURE';

export const FEATURES_LIST_TOTAL_RECEIVE = 'FEATURES_LIST_TOTAL_RECEIVE';

export const TOGGLE_FEATURE_REQUEST = 'TOGGLE_FEATURE_REQUEST';
export const TOGGLE_FEATURE_SUCCESS = 'TOGGLE_FEATURE_SUCCESS';
export const TOGGLE_FEATURE_FAILURE = 'TOGGLE_FEATURE_FAILURE';
