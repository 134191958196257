import apiClient from '../api';

const GROUPS_URI = '/inauth/groups';

export const getAllGroups = () => apiClient.get(GROUPS_URI);

export const getSearchGroups = queryParams => apiClient.get(`${GROUPS_URI}/search?${queryParams}`);

export const getGroupById = id => apiClient.get(`${GROUPS_URI}/${id}`);

export const createGroup = payload => apiClient.post(GROUPS_URI, payload);

export const updateGroup = (id, payload) => apiClient.put(`${GROUPS_URI}/${id}`, payload);

export const deactivateGroup = id => apiClient.post(`${GROUPS_URI}/${id}/deactivate`);

export const getApplicationAccess = () => apiClient.get(`${GROUPS_URI}/applications`);

export const getAdditionalLanguages = () => apiClient.get(`${GROUPS_URI}/additionallanguages`);

export const getStatistics = (id, startDate, endDate) =>
  apiClient.get(`${GROUPS_URI}/${id}/statistics?startDate=${startDate}&endDate=${endDate}`, 'blob');

export const getProfilesApplications = id => apiClient.get(`${GROUPS_URI}/${id}/applications`);

export const getExternalDataProviders = () => apiClient.get(`${GROUPS_URI}/externalDataProviders`);
export const getSanctionSecuritiesSources = () =>
  apiClient.get(`${GROUPS_URI}/sanction-securities-sources`);

export const getFundInfoFeeds = () => apiClient.get(`${GROUPS_URI}/fundinfo-feeds`);

export const getESGProviders = () => apiClient.get(`${GROUPS_URI}/esgProviders`);

export const getInvestorSuitabilityProviders = () =>
  apiClient.get(`${GROUPS_URI}/investorSuitabilityProviders`);

export const getInvestorSuitabilityKnowledge = () =>
  apiClient.get(`${GROUPS_URI}/investorSuitabilityKnowledges`);

export const getIconColors = () => apiClient.get(`${GROUPS_URI}/design/icons`);
