import get from 'lodash/get';

import { getIsFetching } from '../helpers/createList';
import * as commonType from '../types/common';
import * as commonApi from '../../api/services/common';
import { getParamObjectKeys, updateUrl } from '../../common/helpers/url';
import * as userApi from '../../api/services/users';
import { getSearchUserList } from './users';
import * as userTypes from '../types/users';

export const getInruleList = () => (dispatch, getState) => {
  const state = getState();
  const {
    common: { inRules },
  } = state;
  const isFetching = getIsFetching(inRules);
  if (isFetching) return;

  dispatch({ type: commonType.INRULES_LIST_REQUEST });

  commonApi
    .getInRules()
    .then(results => {
      dispatch({
        type: commonType.INRULES_LIST_SUCCESS,
        results: get(results, 'data', []),
      });
    })
    .catch(() => {
      dispatch({ type: commonType.INRULES_LIST_FAILURE });
    });
};

export const getCountryList = () => (dispatch, getState) => {
  const state = getState();
  const {
    common: { countryList },
  } = state;
  const isFetching = getIsFetching(countryList);
  if (isFetching) return;

  dispatch({ type: commonType.COUNTRY_LIST_REQUEST });

  commonApi
    .getCountryList()
    .then(results => {
      dispatch({
        type: commonType.COUNTRY_LIST_SUCCESS,
        results: get(results, 'data', []),
      });
    })
    .catch(() => {
      dispatch({ type: commonType.COUNTRY_LIST_FAILURE });
    });
};

export const getDocTypeList = () => (dispatch, getState) => {
  const state = getState();
  const {
    common: { docTypeList },
  } = state;
  const isFetching = getIsFetching(docTypeList);
  if (isFetching) return;

  dispatch({ type: commonType.DOCTYPE_LIST_REQUEST });

  commonApi
    .getDocTypeList()
    .then(results => {
      dispatch({
        type: commonType.DOCTYPE_LIST_SUCCESS,
        results: get(results, 'data', []),
      });
    })
    .catch(() => {
      dispatch({ type: commonType.DOCTYPE_LIST_FAILURE });
    });
};

export const getLicenceList = () => (dispatch, getState) => {
  const state = getState();
  const {
    common: { licenceList },
  } = state;
  const isFetching = getIsFetching(licenceList);
  if (isFetching) return;

  dispatch({ type: commonType.LICENCE_LIST_REQUEST });

  commonApi
    .getLicenceList()
    .then(results => {
      dispatch({
        type: commonType.LICENCE_LIST_SUCCESS,
        results: get(results, 'data', []),
      });
    })
    .catch(() => {
      dispatch({ type: commonType.LICENCE_LIST_FAILURE });
    });
};

export const getGroupSubscribedCountryList = docTypeId => (dispatch, getState) => {
  const state = getState();
  const {
    common: { groupSubscribedCountryList },
    groups: { selectedGroupId },
  } = state;
  const isFetching = getIsFetching(groupSubscribedCountryList);
  if (isFetching) return;

  dispatch({ type: commonType.GROUP_SUBSCRIBED_COUNTRY_LIST_REQUEST });

  commonApi
    .getGroupSubscribedCountryList(selectedGroupId, docTypeId)
    .then(results => {
      dispatch({
        type: commonType.GROUP_SUBSCRIBED_COUNTRY_LIST_SUCCESS,
        results: get(results, 'data', []),
      });
    })
    .catch(() => {
      dispatch({ type: commonType.GROUP_SUBSCRIBED_COUNTRY_LIST_FAILURE });
    });
};

export const resetNotification = () => dispatch => dispatch({ type: commonType.NOTIFICATION_SET });

export const setNotification = notification => dispatch =>
  dispatch({ type: commonType.NOTIFICATION_SET, notification });

export const hideModal = () => dispatch => dispatch({ type: commonType.SHOW_MODAL });

export const showModal = (modalType, modalProps) => dispatch =>
  dispatch({ type: commonType.SHOW_MODAL, modal: { modalType, modalProps } });

export const setCommonFilters = filters => dispatch => {
  dispatch({ type: commonType.RESET_PAGINATION, filters });
  const queryParams = getParamObjectKeys(filters);
  updateUrl(queryParams);
};

export const uploadISINs = formData => () => {
  return commonApi.uploadISINs(formData);
};

export const setUploadedInvalidIsins = isins => dispatch =>
  dispatch({ type: commonType.SET_UPLOADED_INVALID_ISINS_SUCCESS, invalidIsins: isins });

export const resetUploadedInvalidIsins = () => dispatch =>
  dispatch({ type: commonType.SET_UPLOADED_INVALID_ISINS_RESET });

export const setUploadedNotSupportedIsins = isins => dispatch =>
  dispatch({ type: commonType.SET_UPLOADED_NOT_SUPPORTED_ISINS_SUCCESS, notSupportedIsins: isins });

export const resetUploadedNotSupportedIsins = () => dispatch =>
  dispatch({ type: commonType.SET_UPLOADED_NOT_SUPPORTED_ISINS_RESET });
