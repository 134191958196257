import React, { useState, useEffect, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import dayjs from 'dayjs';
import uniq from 'lodash/uniq';

import UIModal from '../common/components/UIModal';
import { getIsFetching, getResults } from '../redux/helpers/createList';
import { getResource } from '../redux/helpers/createResource';
import UICustomLoader from '../common/components/UICustomLoader';
import UIButton from '../common/components/UIButton';
import UICheckbox from '../common/components/UICheckbox';
import UISelect from '../common/components/UISelect';
import {
  createSubscription as _createSubscription,
  getSubscriptionById as _getSubscriptionById,
  resetSubscription as _resetSubscription,
  setEditSubscriptionId as _setEditSubscriptionId,
  setShowSubscriptionModal as _setShowSubscriptionModal,
  updateSubscription as _updateSubscription,
  getAdditionalLanguages as _getAdditionalLanguages,
} from '../redux/actions/subscription';
import UIDatePicker from '../common/components/UIDatePicker';
import UIIcon from '../common/components/UIIcon';
import { formatDate, parseDateToDateObject } from '../common/helpers/date';
import CountryHelper from '../common/components/Table/CountryHelper';

const AddAllButton = ({ onClick }) => (
  <button className='add-all-btn' onClick={onClick}>
    <UIIcon iconClassName='mr-2' icon='plusCircle' iconColor='var(--light-grey-blue)' />
    <span>Add all</span>
  </button>
);

AddAllButton.propTypes = {
  onClick: PropTypes.func,
};

const DividerLineFull = () => <hr className='divider-line fullWidth' />;

export class DatePickerInput extends Component {
  render = () => (
    <div className='ui-date-input'>
      <input className='ui-input' {...this.props} placeholder='Choose date' />
      <UIIcon icon='calendar' iconColor='var(--gunmetal)' iconSize='1.1rem' />
    </div>
  );
}

const defaultSubscriptionModel = {
  groupId: null,
  docTypeId: 'CM PB BT',
  sourceCountries: [],
  targetCountries: [],
  targetCountriesPPC: [],
  startDate: formatDate(dayjs(), 'YYYY-MM-DD'),
  contractRenewDate: null,
  endDate: null,
  description: null,
  availableLanguages: [],
};

const SubscriptionFormModal = ({
  groupId,
  editId,
  editSubscription,
  isLoading,
  countryList,
  docTypeList,
  getSubscriptionById,
  resetSubscription,
  setEditSubscriptionId,
  setShowSubscriptionModal,
  updateSubscription,
  createSubscription,
  getAdditionalLanguages,
  languagesList,
}) => {
  const [subscriptionState, setSubscriptionState] = useState(defaultSubscriptionModel);
  const [mergeTargets, setMergeTargets] = useState(false);
  const [mergeTargetsPPC, setMergeTargetsPPC] = useState(false);
  const [errorState, setErrorState] = useState({});

  const errorsHandler = errors => {
    if (errors) {
      const { fieldErrors } = errors.data;
      fieldErrors.forEach(err => {
        setErrorState(prevState => ({
          ...prevState,
          [err.field]: err.message,
        }));
      });
    }
  };

  const onInit = subscriptionId => {
    if (subscriptionId) {
      getSubscriptionById(subscriptionId);
    }
    getAdditionalLanguages(groupId);
  };

  const onReset = e => {
    e.preventDefault();
    resetSubscription();
    setEditSubscriptionId();
    setShowSubscriptionModal();
  };

  const onSave = (e, subscription) => {
    e.preventDefault();
    if (subscription.id) {
      updateSubscription(subscription).catch(error => {
        errorsHandler(error);
      });
    } else {
      createSubscription(subscription).catch(error => {
        errorsHandler(error);
      });
    }
  };

  useEffect(() => {
    if (editSubscription) {
      setSubscriptionState(editSubscription);
    }
  }, [editSubscription]);

  useEffect(() => {
    onInit(editId);
  }, []);

  const handleChange = (key, value) => {
    setSubscriptionState({ ...subscriptionState, [key]: value });
    setErrorState({ ...errorState, [key]: undefined });
  };

  const handleSelectChange = (key, value) => {
    setSubscriptionState({ ...subscriptionState, [key]: value });
    setErrorState({ ...errorState, [key]: undefined });
  };

  const {
    docTypeId,
    sourceCountries,
    targetCountries,
    targetCountriesPPC,
    startDate,
    contractRenewDate,
    endDate,
    description,
    availableLanguages,
  } = subscriptionState;
  const isEdit = !!editId;

  return (
    <UIModal
      isOpen
      toggle={onReset}
      title={`${isEdit ? 'Edit' : 'Add'} subscription`}
      className='ui-edit-modal'
    >
      {isLoading ? (
        <UICustomLoader loading={isLoading} />
      ) : (
        <Form onSubmit={e => onSave(e, { ...subscriptionState, groupId })} onReset={onReset}>
          <FormGroup className='m-b-32'>
            <Label className='body body--bold mb-2'>Document type</Label>
            <UISelect
              isSearchable
              getOptionLabel={({ shortName }) => shortName}
              getOptionValue={({ id }) => id}
              placeholder='Select contact person'
              value={docTypeList.find(({ id }) => id === docTypeId)}
              onChange={val => handleSelectChange('docTypeId', val ? val.id : null)}
              options={docTypeList}
            />
            {languagesList && (
              <React.Fragment>
                <Label className='body body--bold mb-2 mt-3'>Additional Languages</Label>
                <UISelect
                  isMulti
                  isSearchable
                  getOptionLabel={({ name }) => name}
                  placeholder='Select the additional languages'
                  value={availableLanguages}
                  onChange={val => handleSelectChange('availableLanguages', val)}
                  options={languagesList}
                />
              </React.Fragment>
            )}
          </FormGroup>
          <DividerLineFull />
          <FormGroup className='m-b-32'>
            <Label className='body body--bold mb-2'>Source</Label>
            <UISelect
              isMulti
              isSearchable
              getOptionLabel={({ isoCode }) => <CountryHelper target={isoCode} />}
              getOptionValue={({ name }) => name}
              placeholder='Select source countries'
              value={countryList.filter(({ id }) => sourceCountries.indexOf(id) !== -1)}
              onChange={val =>
                handleSelectChange('sourceCountries', val ? val.map(({ id }) => id) : [])
              }
              options={countryList}
              hasError={!!errorState.sourceCountries}
            />
            <Label className='errorMessage'>{errorState.sourceCountries}</Label>
          </FormGroup>
          <DividerLineFull />
          <FormGroup className='m-b-32'>
            <Label className='body body--bold mb-3'>Subscribed Targets</Label>
            <React.Fragment>
              <div className='mt-2 mb-3'>
                <AddAllButton
                  onClick={e => {
                    e.preventDefault();
                    handleSelectChange(
                      'targetCountries',
                      countryList
                        .filter(c => targetCountriesPPC.indexOf(c.id) === -1)
                        .map(({ id }) => id),
                    );
                  }}
                />
              </div>
              <UISelect
                isMulti
                isSearchable
                noOptionsMessage={() => 'No options - All the countries are already selected'}
                getOptionLabel={({ isoCode }) => <CountryHelper target={isoCode} />}
                getOptionValue={({ name }) => name}
                placeholder='Select target/s'
                value={countryList.filter(({ id }) => targetCountries.indexOf(id) !== -1)}
                onChange={val =>
                  handleSelectChange('targetCountries', val ? val.map(({ id }) => id) : [])
                }
                options={countryList.filter(c => targetCountriesPPC.indexOf(c.id) === -1)}
              />
            </React.Fragment>
          </FormGroup>
          <DividerLineFull />
          <FormGroup className='m-b-32'>
            <Label className='body body--bold mb-3'>PPC Targets</Label>
            <React.Fragment>
              <div className='mt-2 mb-3'>
                <AddAllButton
                  onClick={e => {
                    e.preventDefault();
                    handleSelectChange(
                      'targetCountriesPPC',
                      countryList
                        .filter(c => targetCountries.indexOf(c.id) === -1)
                        .map(({ id }) => id),
                    );
                  }}
                />
              </div>
              <UISelect
                isMulti
                isSearchable
                noOptionsMessage={() => 'No options - All the countries are already selected'}
                getOptionLabel={({ isoCode }) => <CountryHelper target={isoCode} />}
                getOptionValue={({ name }) => name}
                placeholder='Select inApp target/s'
                value={countryList.filter(({ id }) => targetCountriesPPC.indexOf(id) !== -1)}
                onChange={val =>
                  handleSelectChange('targetCountriesPPC', val ? val.map(({ id }) => id) : [])
                }
                options={countryList.filter(c => targetCountries.indexOf(c.id) === -1)}
              />
            </React.Fragment>
          </FormGroup>
          <DividerLineFull />
          <Row form>
            <Col md={4}>
              <FormGroup className='m-b-32'>
                <Label className='body body--bold mb-2'>Start date</Label>
                <UIDatePicker
                  value={startDate || ''}
                  selectedDays={parseDateToDateObject(startDate)}
                  setDate={date => handleChange('startDate', formatDate(date, 'YYYY-MM-DD'))}
                  component={DatePickerInput}
                  inputProps={{ required: true }}
                  withInput
                  withClass={errorState.startDate ? 'ui-datepicker error' : 'ui-datepicker'}
                />
                <Label className='errorMessage'>{errorState.startDate}</Label>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup className='m-b-32'>
                <Label className='body body--bold mb-2'>
                  End date <span className='optional-text'>(optional)</span>
                </Label>
                <UIDatePicker
                  value={endDate || ''}
                  selectedDays={parseDateToDateObject(endDate)}
                  setDate={date => handleChange('endDate', formatDate(date, 'YYYY-MM-DD'))}
                  component={DatePickerInput}
                  withInput
                  inputProps={{ disabled: !startDate }}
                  disabledDays={{ before: parseDateToDateObject(startDate) }}
                  withClass={errorState.endDate ? 'ui-datepicker error' : 'ui-datepicker'}
                />
                <Label className='errorMessage'>{errorState.endDate}</Label>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup className='m-b-32'>
                <Label className='body body--bold mb-2'>
                  Contract renew date <span className='optional-text'>(optional)</span>
                </Label>
                <UIDatePicker
                  value={contractRenewDate || ''}
                  selectedDays={parseDateToDateObject(contractRenewDate)}
                  setDate={date =>
                    handleChange('contractRenewDate', formatDate(date, 'YYYY-MM-DD'))
                  }
                  inputProps={{ disabled: !startDate }}
                  disabledDays={{ before: parseDateToDateObject(startDate) }}
                  component={DatePickerInput}
                  withInput
                  withClass={errorState.contractRenewDate ? 'ui-datepicker error' : 'ui-datepicker'}
                />
                <Label className='errorMessage'>{errorState.contractRenewDate}</Label>
              </FormGroup>
            </Col>
          </Row>
          <DividerLineFull />
          <FormGroup className='m-b-32'>
            <Label className='body body--bold mb-2'>Comment</Label>
            <Input
              name='description'
              type='textarea'
              rows='10'
              value={description || ''}
              placeholder='Add comment'
              className={errorState.description ? 'ui-input textarea error' : 'ui-input textarea'}
              onChange={({ target: { value } }) => handleChange('description', value)}
            />
            <Label className='errorMessage'>{errorState.description}</Label>
          </FormGroup>
          <div className='d-flex align-items-center'>
            <UIButton className='w-100' height={50} type='submit'>
              Save
            </UIButton>
          </div>
        </Form>
      )}
    </UIModal>
  );
};

SubscriptionFormModal.propTypes = {
  editId: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  editSubscription: PropTypes.oneOfType([PropTypes.object]),
  isLoading: PropTypes.bool,
  countryList: PropTypes.arrayOf(PropTypes.object),
  docTypeList: PropTypes.arrayOf(PropTypes.object),
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getSubscriptionById: PropTypes.func,
  resetSubscription: PropTypes.func,
  setEditSubscriptionId: PropTypes.func,
  setShowSubscriptionModal: PropTypes.func,
  updateSubscription: PropTypes.func,
  createSubscription: PropTypes.func,
  languagesList: PropTypes.arrayOf(PropTypes.object),
  getAdditionalLanguages: PropTypes.func,
};

const mapStateToProps = ({ common, subscriptions, groups }) => ({
  countryList: getResults(common.countryList),
  docTypeList: getResults(common.docTypeList),
  editSubscription: getResource(subscriptions.subscription),
  editId: subscriptions.editSubscriptionId,
  isLoading: getIsFetching(subscriptions.subscription),
  editModal: subscriptions.editModal,
  groupId: groups.selectedGroupId,
  languagesList: getResource(subscriptions.languagesList),
});

const mapDispatchToProps = dispatch => ({
  getSubscriptionById: subscriptionId => dispatch(_getSubscriptionById(subscriptionId)),
  resetSubscription: () => dispatch(_resetSubscription()),
  setEditSubscriptionId: () => dispatch(_setEditSubscriptionId()),
  setShowSubscriptionModal: () => dispatch(_setShowSubscriptionModal()),
  updateSubscription: subscription => dispatch(_updateSubscription(subscription)),
  createSubscription: subscription => dispatch(_createSubscription(subscription)),
  getAdditionalLanguages: groupId => dispatch(_getAdditionalLanguages(groupId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionFormModal);
