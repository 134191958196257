import React from 'react';
import PropTypes from 'prop-types';

import UIButton from '../UIButton';

const BulkBottomBar = ({ bottomBarState = [] }) => (
  <div className='dashboard-bottombar py-2 px-4'>
    {bottomBarState.map(({ label, btnType = '', onClick }) => (
      <UIButton key={label} btnType={btnType} height={48} className='body--bold' onClick={onClick}>
        {label}
      </UIButton>
    ))}
  </div>
);

BulkBottomBar.propTypes = {
  bottomBarState: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      btnType: PropTypes.string,
      onClick: PropTypes.func.isRequired,
    }),
  ),
};

export default BulkBottomBar;
