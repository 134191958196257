import get from 'lodash/get';
import { saveAs } from 'file-saver';

import * as userTypes from '../types/users';
import * as userApi from '../../api/services/users';
import { getIsFetching } from '../helpers/createList';
import { getParamObjectKeys, updateUrl } from '../../common/helpers/url';
import { setNotification } from './common';
import { isEqual } from 'lodash';

export const setUserFilters = filters => dispatch =>
  dispatch({ type: userTypes.SET_USER_FILTERS, filters });

export const updateUserList = (key, value) => dispatch =>
  dispatch({ type: userTypes.SEARCH_USER_LIST_UPDATE, key, value });

export const getAllUserList = () => (dispatch, getState) => {
  const state = getState();
  const {
    users: { userList },
    groups: { selectedGroupId },
  } = state;
  const isFetching = getIsFetching(userList);
  if (isFetching) return;

  dispatch({ type: userTypes.USER_LIST_REQUEST });

  userApi
    .getAllUsers(selectedGroupId)
    .then(results => {
      dispatch({
        type: userTypes.USER_LIST_SUCCESS,
        results: get(results, 'data', []),
      });
    })
    .catch(() => {
      dispatch({ type: userTypes.USER_LIST_FAILURE });
    });
};

export const getSearchUserList = filters => (dispatch, getState) => {
  const state = getState();
  const {
    users: { userList, filters: stateFilters },
    groups: { selectedGroupId },
  } = state;
  const isFetching = getIsFetching(userList);
  if (isFetching) return;

  dispatch({ type: userTypes.SEARCH_USER_LIST_REQUEST });
  const queryParams = getParamObjectKeys(filters || stateFilters);
  updateUrl(queryParams);

  userApi
    .getSearchUsers(selectedGroupId, queryParams)
    .then(results => {
      dispatch({
        type: userTypes.SEARCH_USER_LIST_SUCCESS,
        results: get(results, 'data.content', []),
      });
      dispatch({
        type: userTypes.SEARCH_USER_TOTAL_RECEIVE,
        total: get(results, 'data.totalElements', 0),
      });
    })
    .catch(() => {
      dispatch({ type: userTypes.SEARCH_USER_LIST_FAILURE });
      dispatch({ type: userTypes.SEARCH_USER_TOTAL_RECEIVE });
    });
};

export const setUser = user => dispatch =>
  dispatch({ type: userTypes.USER_SUCCESS, resource: user });

export const getUserById = id => (dispatch, getState) => {
  const state = getState();
  const {
    users: { user },
  } = state;
  const isFetching = getIsFetching(user);
  if (isFetching) return;

  dispatch({ type: userTypes.USER_REQUEST });
  userApi
    .getUserById(id)
    .then(results => {
      dispatch(setUser(get(results, 'data', null)));
    })
    .catch(() => {
      dispatch({
        type: userTypes.USER_RESET,
      });
    });
};

export const resetUser = () => dispatch => dispatch({ type: userTypes.USER_RESET });

export const setEditUserId = id => dispatch => {
  dispatch({ type: userTypes.SET_EDIT_USER_ID, id });
};

export const setShowUserModal = show => dispatch => {
  dispatch({ type: userTypes.SET_SHOW_USER_MODAL, show });
};

export const createUser = user => dispatch =>
  userApi.createUser(user).then(result => {
    const createdUser = get(result, 'data', null);
    if (createdUser) {
      dispatch(getSearchUserList());
      dispatch(resetUser());
      dispatch(setEditUserId());
      dispatch(setShowUserModal());
      dispatch(setNotification({ path: 'users.create', listOfLabels: [createdUser.username] }));
    }
  });

export const updateUser = user => (dispatch, getState) =>
  userApi.updateUser(user.id, user).then(result => {
    const updatedUser = get(result, 'data', null);
    const loggedUser = get(getState(), 'auth.user');
    if (updatedUser) {
      const userList = get(getState(), 'users.searchUserList.results', []);
      const userIndex = userList.findIndex(({ id }) => id === updatedUser.id);
      dispatch(updateUserList(`${userIndex}`, updatedUser));

      dispatch(resetUser());
      dispatch(setEditUserId());
      dispatch(setShowUserModal());
      if (isEqual(loggedUser.username, user.username)) {
        dispatch(getUserInformation());
      }
      dispatch(setNotification({ path: 'users.update', listOfLabels: [updatedUser.username] }));
    }
  });

export const deactivateUser = id => dispatch =>
  userApi.deactivateUser(id).then(result => {
    const user = get(result, 'data', {});
    dispatch(setNotification({ path: 'users.deactivate', listOfLabels: [user.username] }));
    dispatch(getSearchUserList());
  });

export const reactivateUser = id => dispatch =>
  userApi.reactivateUser(id).then(result => {
    const user = get(result, 'data', {});
    dispatch(setNotification({ path: 'users.reactivate', listOfLabels: [user.username] }));
    dispatch(getSearchUserList());
  });

export const deactivateUsers = ids => dispatch =>
  userApi.deactivateUsers(ids.join(',')).then(() => {
    dispatch(setNotification({ path: 'users.deactivateMulti' }));
    dispatch(getSearchUserList());
  });

export const uploadUsers = formData => (dispatch, getState) => {
  const state = getState();
  const {
    groups: { selectedGroupId },
  } = state;

  return userApi.uploadUsers(selectedGroupId, formData).then(() => {
    dispatch(setNotification({ path: 'users.upload', listOfLabels: ['CSV'] }));
    dispatch(getSearchUserList());
  });
};

export const getFilename = headers => {
  let fileName = 'volume_extraction.zip';
  const contentDisposition = headers['content-disposition'];
  if (contentDisposition) {
    [, fileName] = contentDisposition.split('filename=');
  }
  return fileName;
};

export const exportUsers = () => (dispatch, getState) => {
  const state = getState();
  const {
    groups: { selectedGroupId },
  } = state;

  userApi.exportUsers(selectedGroupId).then(({ data, headers }) => {
    saveAs(data, getFilename(headers));
    dispatch(setNotification({ path: 'users.export', listOfLabels: ['User list'] }));
  });
};

export const getTechnicalRoles = () => dispatch => {
  userApi
    .apiAccess()
    .then(results => {
      return dispatch({
        type: userTypes.USER_API_ROLES_SUCCESS,
        results: get(results, 'data', []),
      });
    })
    .catch(() => {
      dispatch({ type: userTypes.USER_API_ROLES_FAILURE });
    });
};

export const getAMTRoles = () => dispatch => {
  userApi
    .getAMTRoles()
    .then(results => {
      return dispatch({
        type: userTypes.USER_AMT_ROLES_SUCCESS,
        results: get(results, 'data', []),
      });
    })
    .catch(() => {
      dispatch({ type: userTypes.USER_AMT_ROLES_FAILURE });
    });
};

export const getInAppRoles = () => dispatch => {
  userApi
    .getInAppRoles()
    .then(results => {
      return dispatch({
        type: userTypes.USER_IN_APP_ROLES_SUCCESS,
        results: get(results, 'data', []),
      });
    })
    .catch(() => {
      dispatch({ type: userTypes.USER_IN_APP_ROLES_FAILURE });
    });
};

export const getUserInformation = () => dispatch => {
  userApi
    .getUserInformation()
    .then(results => {
      return dispatch({
        type: userTypes.USER_INFO_SUCCESS,
        results: get(results, 'data', []),
      });
    })
    .catch(() => {
      dispatch({ type: userTypes.USER_INFO_FAILURE });
    });
};

export const deleteUser = id => dispatch => {
  dispatch({ type: userTypes.DELETE_USER_REQUEST });
  userApi
    .deleteUser(id)
    .then(() => {
      dispatch(getSearchUserList());
      dispatch({ type: userTypes.DELETE_USER_SUCCESS });
    })
    .catch(() => {
      dispatch({ type: userTypes.DELETE_USER_FAILURE });
    });
};

export const moveUser = (id, profileId, groupId) => dispatch => {
  dispatch({ type: userTypes.MOVE_USER_REQUEST });
  return userApi.moveUser(id, { profileId, groupId }).then(() => {
    dispatch(getSearchUserList());
    dispatch({ type: userTypes.MOVE_USER_SUCCESS });
  });
};

export const forceMoveUser = (id, profileId, groupId) => dispatch => {
  dispatch({ type: userTypes.FORCE_MOVE_USER_REQUEST });
  return userApi.forceMoveUser(id, { profileId, groupId }).then(() => {
    dispatch(getSearchUserList());
    dispatch({ type: userTypes.FORCE_MOVE_USER_SUCCESS });
  });
};
