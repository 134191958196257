import get from 'lodash/get';

import * as entityTypes from '../types/entities';
import * as entityApi from '../../api/services/entities';
import { getIsFetching } from '../helpers/createList';
import { getParamObjectKeys, updateUrl } from '../../common/helpers/url';
import { setNotification, showModal } from './common';

export const setEntityFilters = filters => dispatch =>
  dispatch({ type: entityTypes.SET_ENTITY_FILTERS, filters });

export const updateEntityList = (key, value) => dispatch =>
  dispatch({ type: entityTypes.SEARCH_ENTITY_LIST_UPDATE, key, value });

export const getAllEntityList = () => (dispatch, getState) => {
  const state = getState();
  const {
    entities: { entityList },
    groups: { selectedGroupId },
  } = state;
  const isFetching = getIsFetching(entityList);
  if (isFetching) return;

  dispatch({ type: entityTypes.ENTITY_LIST_REQUEST });

  entityApi
    .getAllEntities(selectedGroupId)
    .then(results => {
      return dispatch({
        type: entityTypes.ENTITY_LIST_SUCCESS,
        results: get(results, 'data', []),
      });
    })
    .catch(() => {
      return dispatch({ type: entityTypes.ENTITY_LIST_FAILURE, results: [] });
    });
};

export const getSearchEntityList = filters => (dispatch, getState) => {
  const state = getState();
  const {
    entities: { searchEntityList, filters: stateFilters },
    groups: { selectedGroupId },
  } = state;
  const isFetching = getIsFetching(searchEntityList);
  if (isFetching) return;

  dispatch({ type: entityTypes.SEARCH_ENTITY_LIST_REQUEST });
  const queryParams = getParamObjectKeys(filters || stateFilters);
  updateUrl(queryParams);

  entityApi
    .getSearchEntities(selectedGroupId, queryParams)
    .then(results => {
      dispatch({
        type: entityTypes.SEARCH_ENTITY_LIST_SUCCESS,
        results: get(results, 'data.content', []),
      });
      dispatch({
        type: entityTypes.SEARCH_ENTITY_TOTAL_RECEIVE,
        total: get(results, 'data.totalElements', 0),
      });
    })
    .catch(() => {
      dispatch({ type: entityTypes.SEARCH_ENTITY_LIST_FAILURE, results: [] });
      dispatch({ type: entityTypes.SEARCH_ENTITY_TOTAL_RECEIVE });
    });
};

export const getEntityById = id => (dispatch, getState) => {
  const state = getState();
  const {
    entities: { entity },
  } = state;
  const isFetching = getIsFetching(entity);
  if (isFetching) return;

  dispatch({ type: entityTypes.ENTITY_REQUEST });
  entityApi
    .getEntityById(id)
    .then(results => {
      dispatch({
        type: entityTypes.ENTITY_SUCCESS,
        resource: get(results, 'data', null),
      });
    })
    .catch(() => {
      dispatch({
        type: entityTypes.ENTITY_RESET,
      });
    });
};

export const resetEntity = () => dispatch => dispatch({ type: entityTypes.ENTITY_RESET });

export const setEditEntityId = id => dispatch => {
  dispatch({ type: entityTypes.SET_EDIT_ENTITY_ID, id });
};

export const setShowEntityModal = show => dispatch => {
  dispatch({ type: entityTypes.SET_SHOW_ENTITY_MODAL, show });
};

export const createEntity = (entity, hideModal) => dispatch =>
  entityApi.createEntity(entity).then(result => {
    const entityId = get(result, 'data.id');
    const entityName = get(result, 'data.name');
    if (entityId) {
      dispatch(getSearchEntityList());
      dispatch(resetEntity());
      dispatch(setEditEntityId());
      if (hideModal) dispatch(setShowEntityModal());
      dispatch(setNotification({ path: 'entities.create', listOfLabels: [entityName] }));
    }
  });

export const updateEntity = entity => (dispatch, getState) =>
  entityApi.updateEntity(entity.id, entity).then(result => {
    const data = get(result, 'data', null);
    if (data) {
      const entityList = get(getState(), 'entities.searchEntityList.results', []);
      const entityIndex = entityList.findIndex(({ id }) => id === data.id);
      const entityName = get(data, 'name', '');
      dispatch(updateEntityList(`${entityIndex}`, data));

      dispatch(resetEntity());
      dispatch(setEditEntityId());
      dispatch(setShowEntityModal());
      dispatch(setNotification({ path: 'entities.update', listOfLabels: [entityName] }));
    }
  });

export const deactivateEntity = id => dispatch =>
  entityApi.deactivateEntity(id).then(
    result => {
      const entity = get(result, 'data', {});
      dispatch(setNotification({ path: 'entities.deactivate', listOfLabels: [entity.name] }));
      dispatch(getSearchEntityList());
    },
    error => {
      dispatch(showModal('ERROR_MODAL', { title: 'Error', errorMsg: error.data.message }));
    },
  );

export const reactivateEntity = id => dispatch =>
  entityApi.reactivateEntity(id).then(
    result => {
      const entity = get(result, 'data', {});
      dispatch(setNotification({ path: 'entities.reactivate', listOfLabels: [entity.name] }));
      dispatch(getSearchEntityList());
    },
    error => {
      dispatch(showModal('ERROR_MODAL', { title: 'Error', errorMsg: error.data.message }));
    },
  );
