import { combineReducers } from 'redux';

import * as types from '../types/entities';
import createList from '../helpers/createList';
import createReducer from '../helpers/createReducer';
import createResource from '../helpers/createResource';

export const entityFilterDefaultState = {
  pagination: {
    page: 0,
    size: 10,
    onlyActive: true,
  },
  sort: {},
  filter: {},
};

const entityListActions = {
  RESET: types.ENTITY_LIST_RESET,
  REQUEST: types.ENTITY_LIST_REQUEST,
  SUCCESS: types.ENTITY_LIST_SUCCESS,
  FAILURE: types.ENTITY_LIST_FAILURE,
  REMOVE: types.ENTITY_LIST_REMOVE,
  UPDATE: types.ENTITY_LIST_UPDATE,
};

const searchEntityListActions = {
  RESET: types.SEARCH_ENTITY_LIST_RESET,
  REQUEST: types.SEARCH_ENTITY_LIST_REQUEST,
  SUCCESS: types.SEARCH_ENTITY_LIST_SUCCESS,
  FAILURE: types.SEARCH_ENTITY_LIST_FAILURE,
  REMOVE: types.SEARCH_ENTITY_LIST_REMOVE,
  UPDATE: types.SEARCH_ENTITY_LIST_UPDATE,
};

const entityActions = {
  RESET: types.ENTITY_RESET,
  REQUEST: types.ENTITY_REQUEST,
  SUCCESS: types.ENTITY_SUCCESS,
};

export default combineReducers({
  entityList: createList(entityListActions),
  searchEntityList: createList(searchEntityListActions),
  filters: createReducer(entityFilterDefaultState, {
    [types.SET_ENTITY_FILTERS]: (state, action) => action.filters || entityFilterDefaultState,
  }),
  total: createReducer(0, {
    [types.SEARCH_ENTITY_TOTAL_RECEIVE]: (state, action) => action.total || 0,
  }),
  entity: createResource(entityActions),
  showEntityModal: createReducer(null, {
    [types.SET_SHOW_ENTITY_MODAL]: (state, action) => action.show || null,
  }),
  editEntityId: createReducer(null, {
    [types.SET_EDIT_ENTITY_ID]: (state, action) => action.id || null,
  }),
});
