import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Redirect } from 'react-router-dom';

import TableRender from '../containers/TableRender';
import { getIsFetching, getResults } from '../redux/helpers/createList';
import { paramObject } from '../common/helpers/url';
import {
  deactivateEntity,
  getSearchEntityList,
  reactivateEntity,
  setEditEntityId,
  setEntityFilters,
  setShowEntityModal,
} from '../redux/actions/entities';
import { createBadgeList } from '../common/components/Table/BadgeCell';
import EntityFormModal from './EntityFormModal';
import UISidebar from '../common/components/UISidebar';
import { showModal } from '../redux/actions/common';
import RowOptions from './RowOptions';
import ActiveIndicator from '../common/components/Table/ActiveIndicator';
import { hasRole, Roles } from '../common/helpers/roles';

const Entities = ({
  entityList,
  isLoading,
  getEntities,
  filters,
  total,
  setFilters,
  groupId,
  showEntityModal,
  setModalEntity,
  showDeactivateModal,
  deactivateEntityById,
  reactivateEntityById,
  userInformation,
}) => {
  const [onlyActive, setOnlyActive] = useState(filters.pagination.onlyActive);
  // This works, but we might need to consider another option, like not using a "Router" anymore.
  if (groupId === null) return <Redirect to='/' />;

  const applyFiltersAndFetch = filter => {
    setFilters(filter);
    getEntities(filter, groupId);
  };

  useEffect(() => {
    const urlFilters = paramObject();
    const newFilter = isEmpty(urlFilters) ? filters : { ...filters, ...urlFilters };
    applyFiltersAndFetch(newFilter);
  }, [groupId]);

  const generateColumns = () => {
    const columns = [
      {
        title: 'Entity',
        filterKey: 'name',
        sortKey: 'name',
        accessor: 'name',
        // eslint-disable-next-line
        Cell: ({ original: { name, active } }) => (
          <div className='d-flex align-items-center'>
            <ActiveIndicator active={active} />
            {name}
          </div>
        ),
      },
      {
        title: 'BRP Group Id',
        filterKey: 'brpGroupId',
        sortKey: 'brpGroupId',
        accessor: 'brpGroupId',
        // eslint-disable-next-line
        Cell: ({ original: { brpGroupId } }) =>
          brpGroupId === undefined || Number.isNaN(brpGroupId) ? (
            <div className='ui-td__no-val'>No group id</div>
          ) : (
            brpGroupId
          ),
      },
      {
        title: 'Source',
        filterKey: 'domicileCountryIds',
        accessor: 'domicileCountryId',
        Cell: ({ original: { domicileCountryId } }) => createBadgeList([domicileCountryId], 'name'),
      },
      {
        title: 'Licence/s',
        filterKey: 'licence',
        // eslint-disable-next-line
        Cell: ({ original: { licenses } }) => createBadgeList(licenses, 'name', 'No licenses'),
      },
    ];

    if (hasRole(Roles.amtClientSetup, userInformation?.amtRoles)) {
      columns.push({
        Header: () => '',
        // eslint-disable-next-line
        Cell: ({ original: { id, active } }) => (
          <RowOptions
            onEdit={e => {
              e.preventDefault();
              setModalEntity(id, true);
            }}
            onDeactivate={e => {
              e.preventDefault();
              showDeactivateModal('DEACTIVATE_MODAL', {
                title: 'Do you really want to deactivate the entity?',
                submitBtnTitle: 'Deactivate',
                backBtnTitle: 'Go back to entities dashboard',
                onSubmit: () => deactivateEntityById(id),
              });
            }}
            onReactivate={() => reactivateEntityById(id)}
            isActive={active}
          />
        ),
        maxWidth: 40,
      });
    }
    return columns;
  };

  const generateSidebarStateList = () => {
    const list = [
      {
        label: 'List',
        icon: 'listSquare',
        visible: true,
      },
    ];

    if (hasRole(Roles.amtClientSetup, userInformation?.amtRoles)) {
      list.push(
        {
          label: 'Add new',
          icon: 'plus',
          onClick: e => {
            e.preventDefault();
            setModalEntity(null, true);
          },
        },
        {
          label: onlyActive ? 'Only active entries' : 'All entries',
          icon: onlyActive ? 'switchClose' : 'switch',
          onClick: e => {
            e.preventDefault();
            const newFilter = {
              pagination: {
                page: 0,
                size: 10,
                onlyActive: !onlyActive,
              },
            };
            setOnlyActive(!onlyActive);
            getEntities(newFilter);
            setFilters(newFilter);
          },
        },
      );
    }
    return list;
  };

  const sidebarState = {
    mainLabel: 'Entities',
    mainLabelIcon: 'cityOutline',
    list: generateSidebarStateList(),
  };

  const noDataState = {
    label: 'entity',
    onClick: e => {
      e.preventDefault();
      setModalEntity(null, true);
    },
  };

  return (
    <div className='content__with-sidebar'>
      <UISidebar sidebarState={sidebarState} />
      <TableRender
        canRowSelect={false}
        isLoading={isLoading}
        data={entityList}
        filters={filters}
        total={total}
        columns={generateColumns()}
        noDataState={noDataState}
        onFiltersAndSortingChange={applyFiltersAndFetch}
      />
      {showEntityModal && <EntityFormModal />}
    </div>
  );
};

Entities.propTypes = {
  entityList: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  filters: PropTypes.oneOfType([PropTypes.object]),
  total: PropTypes.number,
  setFilters: PropTypes.func,
  setModalEntity: PropTypes.func,
  showEntityModal: PropTypes.bool,
  getEntities: PropTypes.func,
  showDeactivateModal: PropTypes.func,
  deactivateEntityById: PropTypes.func,
  reactivateEntityById: PropTypes.func,
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const mapStateToProps = ({ groups, entities, users }) => ({
  entityList: getResults(entities.searchEntityList),
  isLoading: getIsFetching(entities.searchEntityList),
  filters: entities.filters,
  total: entities.total,
  groupId: groups.selectedGroupId,
  showEntityModal: entities.showEntityModal,
  userInformation: getResults(users.userInformation),
});

const mapDispatchToProps = dispatch => ({
  setFilters: filters => dispatch(setEntityFilters(filters)),
  getEntities: filters => dispatch(getSearchEntityList(filters)),
  setModalEntity: (id, show) => {
    dispatch(setEditEntityId(id));
    dispatch(setShowEntityModal(show));
  },
  showDeactivateModal: (type, props) => dispatch(showModal(type, props)),
  deactivateEntityById: id => dispatch(deactivateEntity(id)),
  reactivateEntityById: id => dispatch(reactivateEntity(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Entities);
