export const API_URL =
  window['_env_'] !== undefined ? window['_env_'].REACT_APP_API_URL : process.env.REACT_APP_API_URL;
export const API_AUTH_URL =
  window['_env_'] !== undefined
    ? window['_env_'].REACT_APP_API_AUTH_URL
    : process.env.REACT_APP_API_AUTH_URL;
export const API_AUTH_REALM =
  window['_env_'] !== undefined
    ? window['_env_'].REACT_APP_API_AUTH_REALM
    : process.env.REACT_APP_API_AUTH_REALM;
export const API_AUTH_CLIENT_ID =
  window['_env_'] !== undefined
    ? window['_env_'].REACT_APP_API_AUTH_CLIENT_ID
    : process.env.REACT_APP_API_AUTH_CLIENT_ID;
export const REACT_APP_DESIGN_IN_DEVELOPMENT =
  window['_env_'] !== undefined
    ? window['_env_'].REACT_APP_DESIGN_IN_DEVELOPMENT
    : process.env.REACT_APP_DESIGN_IN_DEVELOPMENT;
