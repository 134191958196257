import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import UIButton from './UIButton';

const UIPagesize = ({ sizeList, pageSize, onChange }) => {
  return (
    <div className='ui-pagination mr-5'>
      {sizeList.map(sizeNumber => {
        const isActive = sizeNumber === pageSize;

        return (
          <UIButton
            key={sizeNumber}
            btnType={!isActive ? 'outline' : ''}
            className={cx({ active: isActive })}
            onClick={() => {
              if (isActive) return;
              onChange('size', sizeNumber);
            }}
          >
            {sizeNumber}
          </UIButton>
        );
      })}
      <span className='body ml-2'>per page</span>
    </div>
  );
};

UIPagesize.propTypes = {
  sizeList: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageSize: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default UIPagesize;
