import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import invoke from 'lodash/invoke';
import { connect } from 'react-redux';

import { resetNotification } from '../../redux/actions/common';

const applyBoldStyle = text => <span className='body--bold'>{text}</span>;

const notificationLabels = {
  users: {
    create: name => <span>User {applyBoldStyle(name)} was added</span>,
    update: name => <span>User {applyBoldStyle(name)} was updated</span>,
    deactivate: name => <span>User {applyBoldStyle(name)} was deactivated</span>,
    reactivate: name => <span>User {applyBoldStyle(name)} was reactivated</span>,
    deactivateMulti: () => <span>Users was deactivated</span>,
    upload: name => <span>{applyBoldStyle(name)} was uploaded</span>,
    export: name => <span>{applyBoldStyle(name)} was downloaded</span>,
  },
  groups: {
    create: name => <span>Group {applyBoldStyle(name)} was added</span>,
    update: name => <span>Group {applyBoldStyle(name)} was updated</span>,
    deactivate: name => <span>Group {applyBoldStyle(name)} was deactivated</span>,
  },
  entities: {
    create: name => <span>Entity {applyBoldStyle(name)} was added</span>,
    update: name => <span>Entity {applyBoldStyle(name)} was updated</span>,
    deactivate: name => <span>Entity {applyBoldStyle(name)} was deactivated</span>,
    reactivate: name => <span>Entity {applyBoldStyle(name)} was reactivated</span>,
  },
  subscriptions: {
    create: name => <span>Subscription {applyBoldStyle(name)} was added</span>,
    update: name => <span>Subscription {applyBoldStyle(name)} was updated</span>,
    deactivate: name => <span>Subscription {applyBoldStyle(name)} was deactivated</span>,
    reactivate: name => <span>Subscription {applyBoldStyle(name)} was reactivated</span>,
    export: name => <span>{applyBoldStyle(name)} was downloaded</span>,
  },
  profiles: {
    create: name => <span>Profile {applyBoldStyle(name)} was added</span>,
    update: name => <span>Profile {applyBoldStyle(name)} was updated</span>,
    deactivate: name => <span>Profile {applyBoldStyle(name)} was deactivated</span>,
    reactivate: name => <span>Profile {applyBoldStyle(name)} was reactivated</span>,
  },
};

const UINotification = ({ notification, removeNotification }) => {
  if (!notification) return null;

  useEffect(() => {
    const timer = setTimeout(removeNotification, 3000);
    return () => clearTimeout(timer);
  }, []);

  const { path = '', listOfLabels = [] } = notification;
  return (
    <div className='ui-notification'>
      <div className='ui-toast'>{invoke(notificationLabels, path, ...listOfLabels)}</div>
    </div>
  );
};

UINotification.propTypes = {
  notification: PropTypes.shape({
    path: PropTypes.string.isRequired,
    listOfLabels: PropTypes.arrayOf(PropTypes.string),
  }),
  removeNotification: PropTypes.func,
};

const mapStateToProps = ({ common }) => ({
  notification: common.notification,
});

const mapDispatchToProps = dispatch => ({
  removeNotification: () => dispatch(resetNotification()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UINotification);
