import apiClient from '../api';

const GROUPS_URI = '/inauth/groups';
const PROFILES_URI = '/inauth/profiles';

export const getAllProfiles = groupId => apiClient.get(`${GROUPS_URI}/${groupId}/profiles`);

export const getSearchProfiles = (groupId, queryParams) =>
  apiClient.get(`${GROUPS_URI}/${groupId}/profiles/search?${queryParams}`);

export const getProfileById = id => apiClient.get(`${PROFILES_URI}/${id}`);

export const createProfile = payload => apiClient.post(PROFILES_URI, payload);

export const updateProfile = (id, payload) => apiClient.put(`${PROFILES_URI}/${id}`, payload);

export const deactivateProfile = id => apiClient.post(`${PROFILES_URI}/${id}/deactivate`);

export const reactivateProfile = id => apiClient.post(`${PROFILES_URI}/${id}/reactivate`);

export const getDataProviderAccesses = () =>
  apiClient.get(`${PROFILES_URI}/data-provider-accesses`);
