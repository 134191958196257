import React from 'react';
import PropTypes from 'prop-types';

const UIAvatar = ({ src = '', width, height, ...rest }) => (
  <div
    className='ui-avatar'
    style={{
      backgroundImage: `url(${src})`,
      width: `${width}px`,
      height: `${height}px`,
    }}
    {...rest}
  />
);

UIAvatar.propTypes = {
  src: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default UIAvatar;
