import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const ActiveIndicator = ({ active }) => <div className={cx('active-indicator mr-2', { active })} />;

ActiveIndicator.propTypes = {
  active: PropTypes.bool,
};

export default ActiveIndicator;
