import React from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import dotProp from 'dot-prop-immutable';

import { updateObjectProp } from '../../helpers/object';
import TableFilterContainer from './TableFilterContainer';
import UICheckbox from '../UICheckbox';

const makeSortObj = (fieldName, sort) => ({
  name: fieldName,
  value: get(sort, fieldName, null),
});
const makeFilterObj = (fieldName, filter) => ({
  name: fieldName,
  value: get(filter, fieldName, null),
});

const generateRTColumnsWithHandlers = (
  data,
  columns,
  filters,
  selectedRows,
  canRowSelect,
  onFiltersAndSortingChange,
  onSelectRowChange,
) => {
  const { sort, filter } = filters;

  const onFiltersChange = (key, value) => {
    const resetPaginationFilter = updateObjectProp(filters, 'pagination.page', '0');
    onFiltersAndSortingChange(updateObjectProp(resetPaginationFilter, `filter.${key}`, value));
  };

  const onDateFiltersChange = ({ fromKey, toKey }, { fromValue, toValue }) => {
    const resetPaginationFilter = updateObjectProp(filters, 'pagination.page', '0');
    const filterWithFrom = updateObjectProp(resetPaginationFilter, `filter.${fromKey}`, fromValue);
    onFiltersAndSortingChange(updateObjectProp(filterWithFrom, `filter.${toKey}`, toValue));
  };

  const onSortingChange = (key, value) => {
    const resetSortFilter = updateObjectProp(filters, 'sort', {});
    const newSorting =
      value || value === ''
        ? dotProp.set(resetSortFilter, `sort.${key}`, value)
        : dotProp.delete(resetSortFilter, `sort.${key}`);
    onFiltersAndSortingChange(newSorting);
  };

  const onSelectRow = id => {
    const idIndex = selectedRows.indexOf(id);
    const newSelect =
      idIndex !== -1 ? selectedRows.filter(_id => _id !== id) : [...selectedRows, id];
    onSelectRowChange(newSelect);
  };

  const onSelectAll = () => {
    const newSelect = isEmpty(selectedRows) ? data.map(item => item.id) : [];
    onSelectRowChange(newSelect);
  };

  const SearchFilter = props => (
    <TableFilterContainer
      onSortChange={onSortingChange}
      onFilterChange={onFiltersChange}
      onDateFiltersChange={onDateFiltersChange}
      {...props}
    />
  );

  const selectColumn = {
    Header: () => (
      <div className='select-all'>
        <UICheckbox checked={!isEmpty(selectedRows)} intermid onChange={onSelectAll} />
      </div>
    ),
    // eslint-disable-next-line
    Cell: ({ original: { id } }) => (
      <UICheckbox checked={selectedRows.includes(id)} onChange={() => onSelectRow(id)} />
    ),
    maxWidth: 40,
  };

  const newColumns = columns.map(
    ({ type, title, sortKey, filterKey, dateFromFilter, dateToFilter, checkboxList, ...rest }) => {
      const header = {
        title,
        type,
        ...(filterKey && { filter: makeFilterObj(filterKey, filter) }),
        ...(sortKey && { sort: makeSortObj(sortKey, sort) }),
        ...(type === 'date' && {
          dateFromFilter: makeFilterObj(dateFromFilter, filter),
          dateToFilter: makeFilterObj(dateToFilter, filter),
        }),
        ...(type === 'checkbox' && { checkboxList }),
      };

      return {
        Header: () => <SearchFilter {...header} />,
        ...rest,
      };
    },
  );

  return canRowSelect ? [selectColumn, ...newColumns] : newColumns;
};

export default generateRTColumnsWithHandlers;
