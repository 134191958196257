import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Col, Form, Row } from 'reactstrap';

import { formatDate, parseDateToDateObject } from '../../helpers/date';
import UIIconButton from '../UIIconButton';
import UIDatePicker from '../UIDatePicker';
import UIButton from '../UIButton';

const DateFilter = ({
  title,
  dateFromFilter: { name: fromKey, value: fromValue },
  dateToFilter: { name: toKey, value: toValue },
  onDateFiltersChange,
}) => {
  const [dateFrom, setDateFrom] = useState(parseDateToDateObject(fromValue));
  const [dateTo, setDateTo] = useState(parseDateToDateObject(toValue));

  return (
    <div className='filter-dropdown'>
      <Row className='mb-3'>
        <Col className='col-auto mr-auto px-3 body'>{title}</Col>
        <Col className='col-auto ml-auto d-flex px-3'>
          <UIIconButton icon='filter' iconColor='var(--light-grey-blue)' />
        </Col>
      </Row>
      <Form
        onSubmit={e => {
          e.preventDefault();
          if (dateFrom || dateTo) {
            onDateFiltersChange(
              { fromKey, toKey },
              {
                fromValue: formatDate(dateFrom, 'YYYY-MM-DD'),
                toValue: formatDate(dateTo, 'YYYY-MM-DD'),
              },
            );
          }
        }}
        onReset={e => {
          e.preventDefault();
          onDateFiltersChange({ fromKey, toKey }, { fromValue: '', toValue: '' });
        }}
      >
        <Row className='mb-2'>
          <Col xs={6}>
            <Col className='col-auto mx-auto text-center body'>{`From: ${formatDate(dateFrom) ||
              'Select date'}`}</Col>
            <UIDatePicker
              selectedDays={dateFrom}
              setDate={setDateFrom}
              initialMonth={dateFrom ? parseDateToDateObject(dateFrom) : dayjs().toDate()}
              disabledDays={{ after: dateTo }}
            />
          </Col>
          <Col xs={6}>
            <Col className='col-auto mr-auto text-center body'>{`To: ${formatDate(dateTo) ||
              'Select date'}`}</Col>
            <UIDatePicker
              selectedDays={dateTo}
              setDate={setDateTo}
              initialMonth={dateTo ? parseDateToDateObject(dateTo) : dayjs().toDate()}
              disabledDays={{ before: dateFrom }}
            />
          </Col>
        </Row>
        <UIButton className='w-50 mb-2 mx-auto' height={32} type='submit'>
          Search
        </UIButton>
        <UIButton className='w-50 mx-auto' height={32} btnType='outline' type='reset'>
          Reset
        </UIButton>
      </Form>
    </div>
  );
};

DateFilter.propTypes = {
  title: PropTypes.string.isRequired,
  dateFromFilter: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  dateToFilter: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  onDateFiltersChange: PropTypes.func,
};

export default DateFilter;
