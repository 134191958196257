import dotProp from 'dot-prop-immutable';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap';
import { NavLink as RouterNavLink } from 'react-router-dom';
import toString from 'lodash/toString';
import mainLogo from './../assets/img/indigita_logo_AMT.svg';
import UIIcon from '../common/components/UIIcon';
import UIAvatar from '../common/components/UIAvatar';
import UISelect from '../common/components/UISelect';
import { getResults } from '../redux/helpers/createList';
import { resetGroup, setSelectedGroup } from '../redux/actions/groups';
import { setCommonFilters, showModal as _showModal } from '../redux/actions/common';
import { Menus, getActiveRoute } from '../utils/general';
import { push } from 'connected-react-router';
import { getUserInformation as _getUserInformation } from '../redux/actions/users';
import { hasRole, Roles as roles } from '../common/helpers/roles';
import { API_AUTH_URL, API_AUTH_REALM, API_AUTH_CLIENT_ID } from '../common/environment';

const ChevronIcon = () => <UIIcon icon='chevronDown' iconColor='var(--white)' />;

const NavItemLink = ({ to, label }) => (
  <NavItem>
    <NavLink to={to} tag={RouterNavLink} className='link-title' activeClassName='active'>
      {label}
    </NavLink>
  </NavItem>
);

const DropdownItemLink = ({ children, to, onClick }) => (
  <DropdownItem to={to} tag={RouterNavLink} onClick={onClick}>
    {children}
  </DropdownItem>
);

const NavbarContainer = ({
  groupList = [],
  selectedGroupId,
  selectGroup,
  addGroup,
  filters = {},
  setGroupFilters,
  groupsFilters,
  entitiesFilters,
  subscriptionsFilters,
  profilesFilters,
  usersFilters,
  location,
  notAuthorized,
  sendToISINsManagement,
  getUserInformation,
  userInformation,
}) => {
  useEffect(() => {
    getUserInformation();
  }, []);

  const getActiveFilters = () => {
    switch (getActiveRoute(location)) {
      case Menus.groups:
        return groupsFilters;
      case Menus.entities:
        return entitiesFilters;
      case Menus.subscriptions:
        return subscriptionsFilters;
      case Menus.profiles:
        return profilesFilters;
      case Menus.users:
        return usersFilters;
      default:
        return groupsFilters;
    }
  };

  const onGroupChange = val => {
    const newPagination = dotProp.set(filters, 'pagination', getActiveFilters().pagination);
    newPagination.pagination.page = 0;
    setGroupFilters(newPagination);
    return val ? selectGroup(val.id) : null;
  };

  const clearGroupSelection = () => {
    selectGroup(null);
  };

  const isStaging = () => String(window.location.origin).includes('staging');

  const isUat = () => String(window.location.origin).includes('uat');

  const getEnvName = () => {
    if (isUat()) {
      return 'UAT';
    } else if (isStaging()) {
      return 'Staging';
    }
    return '';
  };

  const passwordManagementUrl =
    API_AUTH_URL +
    '/realms/' +
    API_AUTH_REALM +
    '/account/?referrer=' +
    API_AUTH_CLIENT_ID +
    '#/security/signingin';

  return (
    <Navbar className='dashboard-navbar' expand='md' fixed='top'>
      {isStaging() && (
        <div className={cx('ribbon', { staging: isStaging() })}>
          <span>{getEnvName()}</span>
        </div>
      )}
      {isUat() && (
        <div className={cx('ribbon', { uat: isUat() })}>
          <span>{getEnvName()}</span>
        </div>
      )}
      <div className='logoContainer'>
        <NavbarBrand to='/' tag={RouterNavLink} className='logo'>
          <img className='logo' src={mainLogo} alt='main-logo' />
        </NavbarBrand>
      </div>
      <NavbarToggler />
      <Collapse navbar>
        <Nav className='align-items-center mr-auto' navbar>
          <NavItem className='d-flex ml-5 mr-4'>
            <UISelect
              isSearchable
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              placeholder='Select a group'
              // Add toString(), coz id from BE is Number
              value={groupList.find(({ id }) => toString(id) === toString(selectedGroupId)) || 0}
              onChange={val => {
                onGroupChange(val);
              }}
              options={groupList}
            />
            <UncontrolledDropdown inNavbar>
              <DropdownToggle nav className='dropdown-title'>
                <UIIcon icon='dotsVertical' iconColor='var(--white)' iconSize='1.1rem' />
              </DropdownToggle>
              {!notAuthorized && (
                <DropdownMenu className='dropdown-list dropdown-list__right' right>
                  <DropdownItem onClick={addGroup}>Add new group</DropdownItem>
                  <DropdownItemLink to='/groups' onClick={clearGroupSelection}>
                    Manage
                  </DropdownItemLink>
                  {/* <DropdownItemLink to='/report'>Report</DropdownItemLink> */}
                </DropdownMenu>
              )}
            </UncontrolledDropdown>
          </NavItem>
          {selectedGroupId && <NavItemLink to='/entities' label='Entities' />}
          {selectedGroupId && <NavItemLink to='/subscriptions' label='Subscriptions' />}
          {selectedGroupId && <NavItemLink to='/profiles' label='Profiles' />}
          {selectedGroupId && <NavItemLink to='/users' label='Users' />}
        </Nav>
        <Nav className='align-items-center ml-auto' navbar>
          <NavItemLink to='/help' label='Help' />

          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav className='dropdown-title dropdown-title__no-bg'>
              <UIAvatar width={45} height={45} />
              <ChevronIcon />
            </DropdownToggle>
            <DropdownMenu className='dropdown-list dropdown-list__right' right>
              <DropdownItem href={passwordManagementUrl}>Change the password</DropdownItem>
              {hasRole(roles.amtFeatureManagement, userInformation?.amtRoles) && (
                <DropdownItemLink to='/features'>Features management</DropdownItemLink>
              )}
              {hasRole(roles.amtCacheManagement, userInformation?.amtRoles) && (
                <DropdownItemLink to='/cache'>Cache Management</DropdownItemLink>
              )}
              {hasRole(roles.amtIsinUpload, userInformation?.amtRoles) && (
                <DropdownItem onClick={() => sendToISINsManagement()}>ISIN Management</DropdownItem>
              )}
              <DropdownItemLink to='/logout'>Log Out</DropdownItemLink>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

NavItemLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

DropdownItemLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

NavbarContainer.propTypes = {
  groupList: PropTypes.arrayOf(PropTypes.object),
  selectedGroupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectGroup: PropTypes.func,
  addGroup: PropTypes.func,
  filters: PropTypes.oneOfType([PropTypes.object]),
  setGroupFilters: PropTypes.func,
  groupsFilters: PropTypes.oneOfType([PropTypes.object]),
  entitiesFilters: PropTypes.oneOfType([PropTypes.object]),
  subscriptionsFilters: PropTypes.oneOfType([PropTypes.object]),
  profilesFilters: PropTypes.oneOfType([PropTypes.object]),
  usersFilters: PropTypes.oneOfType([PropTypes.object]),
  location: PropTypes.oneOfType([PropTypes.object]),
  notAuthorized: PropTypes.bool,
};

const mapStateToProps = ({ groups, entities, subscriptions, profiles, users, common }) => ({
  groupList: getResults(groups.groupList),
  selectedGroupId: groups.selectedGroupId,
  groupsFilters: groups.filters,
  entitiesFilters: entities.filters,
  subscriptionsFilters: subscriptions.filters,
  profilesFilters: profiles.filters,
  usersFilters: users.filters,
  notAuthorized: common.notAuthorized,
  userInformation: getResults(users.userInformation),
});

const mapDispatchToProps = dispatch => ({
  selectGroup: groupId => dispatch(setSelectedGroup(groupId)),
  addGroup: () => {
    dispatch(resetGroup());
    dispatch(_showModal('GROUP_MODAL'));
  },
  setGroupFilters: filters => dispatch(setCommonFilters(filters)),
  showModal: (type, props) => dispatch(_showModal(type, props)),
  sendToISINsManagement: () => dispatch(push(`/isins/management/`)),
  getUserInformation: () => dispatch(_getUserInformation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavbarContainer);
