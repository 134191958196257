import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UIModal from '../UIModal';

const UIErrorDisplayModal = ({ title, errorMsg, onReset }) => {
  return (
    <UIModal isOpen toggle={onReset} title={title} className='ui-edit-modal upload-modal'>
      <span>{errorMsg}</span>
    </UIModal>
  );
};

UIErrorDisplayModal.propTypes = {
  title: PropTypes.string,
  errorMsg: PropTypes.string,
  onReset: PropTypes.func,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onReset: e => {
    e.preventDefault();
    dispatch(ownProps.toggle());
  },
});

export default connect(null, mapDispatchToProps)(UIErrorDisplayModal);
