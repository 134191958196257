import apiClient from '../api';

const GROUPS_URI = '/inauth/groups';
const ENTITY_URI = '/inauth/entities';

export const getAllEntities = groupId => apiClient.get(`${GROUPS_URI}/${groupId}/entities`);

export const getSearchEntities = (groupId, queryParams) =>
  apiClient.get(`${GROUPS_URI}/${groupId}/entities/search?${queryParams}`);

export const getEntityById = id => apiClient.get(`${ENTITY_URI}/${id}`);

export const createEntity = payload => apiClient.post(ENTITY_URI, payload);

export const updateEntity = (id, payload) => apiClient.put(`${ENTITY_URI}/${id}`, payload);

export const deactivateEntity = id => apiClient.post(`${ENTITY_URI}/${id}/deactivate`);

export const reactivateEntity = id => apiClient.post(`${ENTITY_URI}/${id}/reactivate`);
