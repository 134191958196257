import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getIsFetching, getResults } from '../../redux/helpers/createList';
import {
  getAllCachesList as _getAllCachesList,
  evictsAllCaches as _evictsAllCaches,
  onEvictCacheByName as _onEvictCacheByName,
} from '../../redux/actions/management';
import TableRender from '../../containers/TableRender';
import RowOptions from './RowOptions';
import UIIcon from '../../common/components/UIIcon';

class CacheManagement extends Component {
  componentDidMount() {
    const { getAllCachesList } = this.props;
    getAllCachesList();
  }

  render() {
    const {
      cachesList,
      total,
      isLoading,
      getAllCachesList,
      evictsAllCaches,
      onEvictCacheByName,
    } = this.props;
    const columns = [
      {
        title: 'Name',
        filterKey: 'name',
        sortKey: 'name',
        accessor: 'name',
        Cell: ({ original: { name } }) => <div className='d-flex align-items-center'>{name}</div>,
        maxWidth: '2500',
      },
      {
        title: 'Nb of entries',
        filterKey: 'nbEntries',
        sortKey: 'nbEntries',
        accessor: 'nbEntries',
        Cell: ({ original: { nbEntries } }) => (
          <div className='d-flex align-items-center'>{nbEntries}</div>
        ),
        maxWidth: '200',
      },
      {
        Header: () => 'Actions',
        Cell: ({ original: { name } }) => (
          <RowOptions
            onEvictCacheByName={e => {
              e.preventDefault();
              onEvictCacheByName(name);
              getAllCachesList();
            }}
          />
        ),
        maxWidth: '100',
      },
    ];
    return (
      <div>
        <div className='title'>Cache Management</div>
        <div>
          <div className='d-flex ml-2 mb-1 title--bold'>Options</div>
          <div
            role='button'
            tabIndex={0}
            className='d-flex flex-row ml-2 h6 cursorPointer'
            onClick={() => {
              evictsAllCaches();
              getAllCachesList();
            }}
          >
            Clear All Caches <UIIcon iconSize='1.25rem' iconColor='var(--warm-pink)' icon='close' />
          </div>
        </div>
        <TableRender
          showPagination={false}
          canRowSelect={false}
          isLoading={isLoading}
          data={cachesList}
          columns={columns}
          total={total}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ management }) => ({
  cachesList: getResults(management.cachesList),
  total: management.total,
  isLoading: getIsFetching(management.cachesList),
});

const mapDispatchToProps = dispatch => ({
  getAllCachesList: () => dispatch(_getAllCachesList()),
  evictsAllCaches: () => dispatch(_evictsAllCaches()),
  onEvictCacheByName: name => dispatch(_onEvictCacheByName(name)),
});

CacheManagement.propTypes = {
  cachesList: PropTypes.arrayOf(PropTypes.object),
  getAllCachesList: PropTypes.func,
  evictsAllCaches: PropTypes.func,
  onEvictCacheByName: PropTypes.func,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(CacheManagement);
