export const USER_LIST_RESET = 'USER_LIST_RESET';
export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAILURE = 'USER_LIST_FAILURE';
export const USER_LIST_REMOVE = 'USER_LIST_REMOVE';
export const USER_LIST_UPDATE = 'USER_LIST_UPDATE';

export const SEARCH_USER_LIST_RESET = 'SEARCH_USER_LIST_RESET';
export const SEARCH_USER_LIST_REQUEST = 'SEARCH_USER_LIST_REQUEST';
export const SEARCH_USER_LIST_SUCCESS = 'SEARCH_USER_LIST_SUCCESS';
export const SEARCH_USER_LIST_FAILURE = 'SEARCH_USER_LIST_FAILURE';
export const SEARCH_USER_LIST_REMOVE = 'SEARCH_USER_LIST_REMOVE';
export const SEARCH_USER_LIST_UPDATE = 'SEARCH_USER_LIST_UPDATE';

export const SEARCH_USER_TOTAL_RECEIVE = 'SEARCH_USER_TOTAL_RECEIVE';
export const SET_USER_FILTERS = 'SET_USER_FILTERS';

export const SET_SHOW_USER_MODAL = 'SET_SHOW_USER_MODAL';
export const SET_EDIT_USER_ID = 'SET_EDIT_USER_ID';

export const USER_RESET = 'USER_RESET';
export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';

export const USER_API_ROLES_SUCCESS = 'USER_API_ROLES_SUCCESS';
export const USER_API_ROLES_FAILURE = 'USER_API_ROLES_FAILURE';

export const USER_AMT_ROLES_REQUEST = 'USER_AMT_ROLES_REQUEST';
export const USER_AMT_ROLES_SUCCESS = 'USER_AMT_ROLES_SUCCESS';
export const USER_AMT_ROLES_FAILURE = 'USER_AMT_ROLES_FAILURE';

export const USER_IN_APP_ROLES_REQUEST = 'USER_IN_APP_ROLES_REQUEST';
export const USER_IN_APP_ROLES_SUCCESS = 'USER_IN_APP_ROLES_SUCCESS';
export const USER_IN_APP_ROLES_FAILURE = 'USER_IN_APP_ROLES_FAILURE';

export const USER_INFO_REQUEST = 'USER_INFO_REQUEST';
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
export const USER_INFO_FAILURE = 'USER_INFO_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const MOVE_USER_REQUEST = 'MOVE_USER_REQUEST';
export const MOVE_USER_SUCCESS = 'MOVE_USER_SUCCESS';
export const MOVE_USER_FAILURE = 'MOVE_USER_FAILURE';

export const FORCE_MOVE_USER_REQUEST = 'FORCE_MOVE_USER_REQUEST';
export const FORCE_MOVE_USER_SUCCESS = 'FORCE_MOVE_USER_SUCCESS';
export const FORCE_MOVE_USER_FAILURE = 'FORCE_MOVE_USER_FAILURE';
