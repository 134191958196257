import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import TableRender from '../containers/TableRender';
import { getIsFetching, getResults } from '../redux/helpers/createList';
import {
  deactivateUser,
  reactivateUser,
  deactivateUsers,
  deleteUser as _deleteUser,
  exportUsers,
  getSearchUserList,
  setEditUserId,
  setShowUserModal,
  setUser,
  setUserFilters,
  uploadUsers,
} from '../redux/actions/users';
import RowOptions from './RowOptions';
import UserFormModal from './UserFormModal';
import UISidebar from '../common/components/UISidebar';
import { showModal as _showModal } from '../redux/actions/common';
import { formatDate, getActiveByStartEndDates } from '../common/helpers/date';
import ActiveIndicator from '../common/components/Table/ActiveIndicator';
import UIIcon from '../common/components/UIIcon';
import { hasRole, Roles } from '../common/helpers/roles';

const Users = ({
  userList,
  isLoading,
  total,
  getUsers,
  filters,
  setFilters,
  groupId,
  setEditUser,
  showUserModal,
  showModal,
  deactivateUserById,
  reactivateUserById,
  deactivateSelectedUsers,
  uploadUserList,
  exportUserList,
  setUserForDuplicate,
  deleteUser,
  userInformation,
}) => {
  const [onlyActive, setOnlyActive] = useState(filters.pagination.endDateFrom);
  // This works, but we might need to consider another option, like not using a "Router" anymore.
  if (groupId === null) return <Redirect to='/' />;

  const [selectedRows, setSelectedRows] = useState([]);
  const applyFiltersAndFetch = filter => {
    setFilters(filter);
    getUsers(filter, groupId);
  };

  useEffect(() => {
    const newFilter = { pagination: filters.pagination, filter: {} };
    applyFiltersAndFetch(newFilter);
  }, [groupId]);

  const hasProductManagementRole = inAppUserRoles => {
    return inAppUserRoles.filter(r => r.id !== inAppUserRoles.INAPP_PRODUCT_MANAGEMENT).length > 0;
  };

  const generateColumns = () => {
    const columns = [
      {
        title: 'Username',
        filterKey: 'username',
        sortKey: 'username',
        accessor: 'username',
        // eslint-disable-next-line
        Cell: ({ original: { username, startDate, endDate } }) => (
          <div className='d-flex align-items-center'>
            <ActiveIndicator active={getActiveByStartEndDates(startDate, endDate)} />
            {username}
          </div>
        ),
      },
      {
        title: 'Name',
        filterKey: 'fullName',
        sortKey: 'fullName',
        // eslint-disable-next-line
        Cell: ({ original: { fullName } }) => (
          <div className='d-flex align-items-center'>{fullName}</div>
        ),
      },
      {
        title: 'Application',
        filterKey: 'applications',
        // eslint-disable-next-line
        Cell: access => {
          const {
            original: { profile },
          } = access;
          return <div>{profile.applicationAccess ? profile.applicationAccess.name : ''}</div>;
        },
      },
      {
        title: 'Profile',
        filterKey: 'profile',
        sortKey: 'profile',
        accessor: 'profile.name',
      },
    ];

    if (hasRole(Roles.amtManagement, userInformation?.amtRoles)) {
      columns.push({
        title: 'AMT Roles',
        filterKey: 'amtUserRoles',
        sortKey: 'amtUserRoles',
        // eslint-disable-next-line
        Cell: ({ original: { admin, amtUserRoles } }) => {
          return (
            <div className='d-flex flex-column keys'>
              {admin && (
                <Tooltip title='Admin' aria-label='add'>
                  <div className='mr-2'>
                    <UIIcon icon='star' iconColor='var(--butterscotch)' iconSize='1.2rem' />
                  </div>
                </Tooltip>
              )}
              {amtUserRoles.map(role => {
                return (
                  <div className='d-flex flex-row'>
                    <div className='keyElement'> {role.name}</div>
                  </div>
                );
              })}
            </div>
          );
        },
      });
    }

    if (
      hasRole(Roles.amtManagement, userInformation?.amtRoles) ||
      hasRole(Roles.amtClientSetup, userInformation?.amtRoles)
    ) {
      columns.push({
        title: 'InApp Roles',
        filterKey: 'inAppUserRoles',
        sortKey: 'inAppUserRoles',
        // eslint-disable-next-line
        Cell: ({ original: { admin, inAppUserRoles } }) => {
          return (
            <div className='d-flex flex-row'>
              {admin && (
                <Tooltip title='Admin' aria-label='add'>
                  <div className='mr-2'>
                    <UIIcon icon='star' iconColor='var(--butterscotch)' iconSize='1.2rem' />
                  </div>
                </Tooltip>
              )}
              {hasProductManagementRole(inAppUserRoles) && (
                <Tooltip title='Product Management' aria-label='add'>
                  <div>
                    <UIIcon icon='folderStar' iconColor='var(--blue-green)' iconSize='1.2rem' />
                  </div>
                </Tooltip>
              )}
            </div>
          );
        },
      });
    }

    columns.push(
      {
        title: 'Start date',
        type: 'date',
        dateFromFilter: 'startDateFrom',
        dateToFilter: 'startDateTo',
        sortKey: 'startDate',
        Cell: ({ original: { startDate } }) =>
          startDate || <div className='ui-td__no-val'>No date</div>,
        maxWidth: 130,
      },
      {
        title: 'End date',
        type: 'date',
        dateFromFilter: 'endDateFrom',
        dateToFilter: 'endDateTo',
        sortKey: 'endDate',
        Cell: ({ original: { endDate } }) =>
          endDate || <div className='ui-td__no-val'>No date</div>,
        maxWidth: 130,
      },
    );

    if (
      hasRole(Roles.amtManagement, userInformation?.amtRoles) ||
      hasRole(Roles.amtClientSetup, userInformation?.amtRoles)
    ) {
      columns.push({
        Header: () => '',
        // eslint-disable-next-line
        Cell: ({ original: { id, startDate, endDate, ...rest } }) => (
          <RowOptions
            onEdit={e => {
              e.preventDefault();
              setEditUser(id, true);
            }}
            onDeactivate={e => {
              e.preventDefault();
              showModal('DEACTIVATE_MODAL', {
                title: 'Do you really want to deactivate the user?',
                submitBtnTitle: 'Deactivate',
                backBtnTitle: 'Go back to users dashboard',
                onSubmit: () => deactivateUserById(id),
              });
            }}
            onReactivate={() => reactivateUserById(id)}
            onMoveUser={e => {
              e.preventDefault();
              showModal('MOVE_USER_MODAL', {
                userId: id,
              });
            }}
            onDuplicate={e => {
              e.preventDefault();
              setEditUser(null, true);
              setUserForDuplicate(rest);
            }}
            onDelete={e => {
              e.preventDefault();
              showModal('DELETE_MODAL', {
                title: 'Are you sure you want to delete this user ? This action is irreversible.',
                submitBtnTitle: 'Delete',
                backBtnTitle: 'Go back',
                onSubmit: () => deleteUser(id),
              });
            }}
            isActive={getActiveByStartEndDates(startDate, endDate)}
          />
        ),
        maxWidth: 40,
      });
    }
    return columns;
  };

  const getOnlyActiveFilter = () => {
    const newFilter = {
      pagination: {
        page: 0,
        size: 10,
        endDateFrom: formatDate(
          new Date(new Date().setDate(new Date().getDate() + 1)),
          'YYYY-MM-DD',
        ),
      },
    };
    return newFilter;
  };

  const getAllFilter = () => {
    const newFilter = {
      pagination: {
        page: 0,
        size: 10,
      },
    };
    return newFilter;
  };

  const generateSidebarStateList = () => {
    const list = [
      {
        label: 'List',
        icon: 'listSquare',
        visible: true,
      },
    ];

    if (
      hasRole(Roles.amtManagement, userInformation?.amtRoles) ||
      hasRole(Roles.amtClientSetup, userInformation?.amtRoles)
    ) {
      list.push(
        {
          label: 'Add new',
          icon: 'plus',
          onClick: e => {
            e.preventDefault();
            setEditUser(null, true);
          },
        },
        {
          label: 'Upload list',
          icon: 'uploadFile',
          onClick: e => {
            e.preventDefault();
            showModal('UPLOAD_MODAL', {
              title: 'Upload a list ',
              fieldName: 'usersFile',
              onSubmit: formData => uploadUserList(formData),
            });
          },
        },
        {
          label: 'Download list',
          icon: 'downloadFile',
          onClick: e => {
            e.preventDefault();
            exportUserList();
          },
        },
        {
          label: onlyActive ? 'Only active entries' : 'All entries',
          icon: onlyActive ? 'switchClose' : 'switch',
          onClick: e => {
            e.preventDefault();
            setOnlyActive(!onlyActive);
            const newFilter = onlyActive ? getAllFilter() : getOnlyActiveFilter();
            getUsers(newFilter);
            setFilters(newFilter);
          },
        },
      );
    }
    return list;
  };

  const sidebarState = {
    mainLabel: 'Users',
    mainLabelIcon: 'userGroup',
    list: generateSidebarStateList(),
  };

  const bottomBarState = [
    {
      label: 'Deactivate',
      btnType: 'outline',
      onClick: e => {
        e.preventDefault();
        showModal('DEACTIVATE_MODAL', {
          title: 'Do you really want to deactivate users?',
          submitBtnTitle: 'Deactivate',
          backBtnTitle: 'Go back to users dashboard',
          onSubmit: () => {
            deactivateSelectedUsers(selectedRows);
            setSelectedRows([]);
          },
        });
      },
    },
  ];

  const noDataState = {
    label: 'user',
    onClick: e => {
      e.preventDefault();
      setEditUser(null, true);
    },
  };

  return (
    <div className='content__with-sidebar'>
      <UISidebar sidebarState={sidebarState} />
      <TableRender
        isLoading={isLoading}
        data={userList}
        total={total}
        filters={filters}
        columns={generateColumns()}
        onFiltersAndSortingChange={applyFiltersAndFetch}
        selectedRows={selectedRows}
        onSelectRowChange={setSelectedRows}
        noDataState={noDataState}
        bottomBarState={bottomBarState}
      />
      {showUserModal && <UserFormModal />}
    </div>
  );
};

Users.propTypes = {
  userList: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  filters: PropTypes.oneOfType([PropTypes.object]),
  setFilters: PropTypes.func,
  getUsers: PropTypes.func,
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showUserModal: PropTypes.bool,
  setEditUser: PropTypes.func,
  showModal: PropTypes.func,
  deactivateUserById: PropTypes.func,
  reactivateUserById: PropTypes.func,
  deactivateSelectedUsers: PropTypes.func,
  uploadUserList: PropTypes.func,
  exportUserList: PropTypes.func,
  setUserForDuplicate: PropTypes.func,
};

const mapStateToProps = ({ groups, users }) => ({
  userList: getResults(users.searchUserList),
  isLoading: getIsFetching(users.searchUserList),
  filters: users.filters,
  total: users.total,
  groupId: groups.selectedGroupId,
  showUserModal: users.showUserModal,
  userInformation: getResults(users.userInformation),
});

const mapDispatchToProps = dispatch => ({
  setFilters: filters => dispatch(setUserFilters(filters)),
  getUsers: filters => dispatch(getSearchUserList(filters)),
  setEditUser: (id, show) => {
    dispatch(setEditUserId(id));
    dispatch(setShowUserModal(show));
  },
  setUserForDuplicate: subscription => dispatch(setUser(subscription)),
  showModal: (type, props) => dispatch(_showModal(type, props)),
  deactivateUserById: id => dispatch(deactivateUser(id)),
  reactivateUserById: id => dispatch(reactivateUser(id)),
  deactivateSelectedUsers: ids => dispatch(deactivateUsers(ids)),
  uploadUserList: formData => dispatch(uploadUsers(formData)),
  exportUserList: () => dispatch(exportUsers()),
  deleteUser: id => dispatch(_deleteUser(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
