import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const UIButton = ({ children, className = '', width, height, btnType, onClick, ...props }) => (
  <button
    onClick={onClick}
    style={{ width: `${width}px`, height: `${height}px` }}
    className={cx(
      'ui-button',
      {
        'ui-button--outline': btnType === 'outline',
      },
      className,
    )}
    {...props}
  >
    {children}
  </button>
);

UIButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  btnType: PropTypes.string,
  onClick: PropTypes.func,
};

export default UIButton;
