import dotProp from 'dot-prop-immutable';
import get from 'lodash/get';
import isArray from 'lodash/isArray';

/* eslint-disable */
export const updateObjectProp = (obj, key, value) =>
  value ? dotProp.set(obj, key, value) : dotProp.delete(obj, key);

export const makeStringFromArrayObj = (array, key) =>
  isArray(array) ? array.map(obj => get(obj, key)).join(',') : '';

/* eslint-disable */
export const safeJsonParse = json => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return null;
  }
};
