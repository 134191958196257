import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore);

export const parseDateToDateObject = date => (dayjs(date).isValid() ? dayjs(date).toDate() : null);

export const parseDateToIsoString = date =>
  dayjs(date).isValid() ? dayjs(date).toISOString() : null;

export const formatDate = (date, format = 'YYYY/MM/DD') =>
  dayjs(date).isValid() ? dayjs(date).format(format) : null;

export const setStartDayTime = date =>
  dayjs(date).isValid()
    ? dayjs(date)
        .startOf('day')
        .hour('3')
        .toISOString()
    : null;

export const setEndDayTime = date =>
  dayjs(date).isValid()
    ? dayjs(date)
        .endOf('day')
        .toISOString()
    : null;

export const isSameOrBeforeToday = date =>
  dayjs(date).isValid() && dayjs(date).isSameOrBefore(dayjs(), 'day');

export const isAfterToday = date => dayjs(date).isValid() && dayjs(date).isAfter(dayjs(), 'day');

export const getActiveByStartEndDates = (startDate, endDate) =>
  (isSameOrBeforeToday(startDate) && !endDate) ||
  (isSameOrBeforeToday(startDate) && isAfterToday(endDate));
