export const Roles = {
  amtCacheManagement: 'AMT_CACHE_MANAGEMENT',
  amtClientSetup: 'AMT_CLIENT_SETUP',
  amtManagement: 'AMT_MANAGEMENT',
  amtIsinUpload: 'AMT_ISIN_UPLOAD',
  amtFeatureManagement: 'AMT_FEATURE_MANAGEMENT',
};

export const hasRole = (role, roles) => {
  return roles?.indexOf(role) !== -1;
};
