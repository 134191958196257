import React from 'react';
import PropTypes from 'prop-types';

import UIIcon from './UIIcon';

const UIIconButton = ({ icon, iconSize, iconColor, iconRotate, ...props }) => (
  <button {...props}>
    <UIIcon
      {...{
        icon,
        iconSize,
        iconColor,
        iconRotate,
      }}
    />
  </button>
);

UIIconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  iconSize: PropTypes.string,
  iconColor: PropTypes.string,
  iconRotate: PropTypes.number,
};

export default UIIconButton;
