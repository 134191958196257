import { connect } from 'react-redux';
import React, { Component, useState } from 'react';
import UIIcon from '../../common/components/UIIcon';
import {
  resetUploadedInvalidIsins as _resetUploadedInvalidIsins,
  resetUploadedNotSupportedIsins as _resetUploadedNotSupportedIsins,
  showModal as _showModal,
  uploadISINs as _uploadISINs,
} from '../../redux/actions/common';
import { isEqual } from 'lodash';
import * as toastr from 'toastr';

class IsinsManagement extends Component {
  state = {
    fileUploaded: false,
  };

  componentDidMount() {
    const { resetUploadedInvalidIsins, resetUploadedNotSupportedIsins } = this.props;
    resetUploadedInvalidIsins();
    resetUploadedNotSupportedIsins();
  }

  render() {
    const { showModal, uploadISINs, notSupportedIsins, invalidIsins } = this.props;
    const { fileUploaded } = this.state;
    const showISINUpdateModal = () => {
      showModal('UPLOAD_MODAL', {
        onSubmit: formData => {
          this.setState({ fileUploaded: true });
          return uploadISINs(formData).catch(error => {
            this.setState({ fileUploaded: false });
            const e = error.data;
            if (e && e.fieldErrors) {
              e.fieldErrors.forEach(err => toastr.error(err.message, 'Error'));
            } else if (e && e.message) {
              toastr.error(e.message, 'Error');
            }
          });
        },
        type: 'isins',
        fieldName: 'file',
        title: 'Upload a list of ISINs ',
      });
    };
    return (
      <div>
        <div className='title'>ISINs Management</div>
        <div>
          <div className='d-flex ml-2 mb-1 title--bold'>Options</div>
          <div
            role='button'
            tabIndex={0}
            className='d-flex flex-row ml-2 h6 cursorPointer'
            onClick={() => {
              this.setState({ fileUploaded: false });
              showISINUpdateModal();
            }}
          >
            Add ISINs
            <UIIcon
              iconClassName='ml-2'
              iconSize='1.25rem'
              iconColor='var(--warm-pink)'
              icon='plusCircle'
            />
          </div>
          <div className='divLine' />
          <div className='mt-1'>
            {fileUploaded && isEqual(notSupportedIsins, []) && isEqual(invalidIsins, []) && (
              <div className='upload-successful'>Upload successful</div>
            )}
            <div className='ml-2'>
              {!isEqual(notSupportedIsins, []) && (
                <div>
                  {notSupportedIsins.map(isin => (
                    <li key={isin}>{isin}: Not processed by the Data provider</li>
                  ))}
                </div>
              )}
              {!isEqual(invalidIsins, []) && (
                <div>
                  {invalidIsins.map(isin => (
                    <li key={isin}>{isin}: Format invalid</li>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ common }) => ({
  notSupportedIsins: common.notSupportedIsins,
  invalidIsins: common.invalidIsins,
});

const mapDispatchToProps = dispatch => ({
  uploadISINs: formData => dispatch(_uploadISINs(formData)),
  showModal: (type, props) => dispatch(_showModal(type, props)),
  resetUploadedInvalidIsins: () => dispatch(_resetUploadedInvalidIsins()),
  resetUploadedNotSupportedIsins: () => dispatch(_resetUploadedNotSupportedIsins()),
});

IsinsManagement.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(IsinsManagement);
