import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import { getActiveByStartEndDates } from '../common/helpers/date';

import UISelect from '../common/components/UISelect';
import UIIcon from '../common/components/UIIcon';
import CountryHelper from '../common/components/Table/CountryHelper';
import Tooltip from '@material-ui/core/Tooltip';

const AddAllButton = ({ onClick }) => (
  <button className='add-all-btn' onClick={onClick}>
    <UIIcon iconClassName='mr-2' icon='plusCircle' iconColor='var(--light-grey-blue)' />
    <span>Add all</span>
  </button>
);

AddAllButton.propTypes = {
  onClick: PropTypes.func,
};

const CountrySelectGroup = ({
  valueKey,
  valueList,
  label,
  countryList,
  change,
  handleAllSelect,
  placeholder,
  isValidSubscriptionSource,
}) => {
  const CustomStyle = isValidSubscription => {
    return {
      multiValueLabel: (styles, { data: { isoCode } }) => ({
        ...styles,
        backgroundColor: !isValidSubscription(isoCode) ? 'lightyellow' : 'transparent',
      }),
      multiValueRemove: (styles, { data: { isoCode } }) => ({
        ...styles,
        backgroundColor: !isValidSubscription(isoCode) ? 'lightyellow' : 'transparent',
      }),
    };
  };

  return (
    <React.Fragment>
      <div className='d-flex align-items-center mb-2'>
        {label && <span className='mr-2'>{label}</span>}
        <AddAllButton
          onClick={e => {
            e.preventDefault();
            handleAllSelect(valueKey);
          }}
        />
      </div>
      <UISelect
        isMulti
        isSearchable
        getOptionLabel={({ id }) =>
          isValidSubscriptionSource && !isValidSubscriptionSource(id) ? (
            <Tooltip
              title='Subscription is not active due to not existing, not started yet or is expired'
              aria-label='add'
            >
              <div>
                <CountryHelper isValidSubscriptionSource={isValidSubscriptionSource} target={id} />
              </div>
            </Tooltip>
          ) : (
            <CountryHelper isValidSubscriptionSource={isValidSubscriptionSource} target={id} />
          )
        }
        getOptionValue={({ name }) => name}
        placeholder={placeholder}
        value={countryList.filter(({ id }) => valueList.indexOf(id) !== -1)}
        onChange={val => change(valueKey, val ? val.map(({ id }) => id) : [])}
        options={countryList}
        styles={isValidSubscriptionSource ? CustomStyle(isValidSubscriptionSource) : ''}
      />
    </React.Fragment>
  );
};

CountrySelectGroup.propTypes = {
  valueKey: PropTypes.string,
  placeholder: PropTypes.string,
  valueList: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  countryList: PropTypes.arrayOf(PropTypes.object),
  change: PropTypes.func,
  handleAllSelect: PropTypes.func,
  isValidSubscriptionSource: PropTypes.func,
};

const DocTypeTab = ({
  access,
  accessIndex,
  targetCountryList,
  sourceCountryList,
  subscriptionList,
  onChange,
}) => {
  const { sourceCountries, subscriptions, targetCountries } = access;
  const sourceListsByKey = {
    sourceCountries: sourceCountryList,
    subscriptions: subscriptionList,
    targetCountries: targetCountryList,
  };

  const change = (key, value) => onChange(`profileAccesses.${accessIndex}.${key}`, value);
  const handleAllSelect = key => {
    change(
      key,
      sourceListsByKey[key].map(({ id }) => id),
    );
  };

  const isValidSubscriptionSource = key => {
    const subs = sourceListsByKey['subscriptions'];
    // Filter by doctype and active
    const filteredSubs = subs.filter(function(item) {
      return (
        item.docTypeId === access.docTypeId &&
        getActiveByStartEndDates(item.startDate, item.endDate)
      );
    });
    // Merge all src countries list
    const allSourceCountries = filteredSubs.flatMap(item => item.sourceCountries);
    // Check if src list contains key
    return allSourceCountries.some(item => item === key);
  };

  return (
    <div>
      <FormGroup className='m-b-32'>
        <Label className='body body--bold mb-3'>Source countries</Label>
        <CountrySelectGroup
          placeholder='Select sources/s'
          valueKey='sourceCountries'
          valueList={sourceCountries}
          countryList={sourceCountryList}
          change={change}
          handleAllSelect={handleAllSelect}
          isValidSubscriptionSource={key => isValidSubscriptionSource(key)}
        />
      </FormGroup>
      <FormGroup className='m-b-32'>
        <Label className='body body--bold mb-3'>Target countries</Label>
        <CountrySelectGroup
          placeholder='Select target/s'
          valueKey='targetCountries'
          valueList={targetCountries}
          countryList={targetCountryList}
          change={change}
          handleAllSelect={handleAllSelect}
        />
      </FormGroup>
    </div>
  );
};

DocTypeTab.propTypes = {
  access: PropTypes.oneOfType([PropTypes.object]),
  accessIndex: PropTypes.number,
  targetCountryList: PropTypes.arrayOf(PropTypes.object),
  sourceCountryList: PropTypes.arrayOf(PropTypes.object),
  subscriptionList: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
};

export default DocTypeTab;
