import React from 'react';
import PropTypes from 'prop-types';

import UIIcon from '../../common/components/UIIcon';
import UITooltip from '../../common/components/UITooltip';

const RowOptions = ({ onEvictCacheByName }) => (
  <UITooltip
    tooltipClassName='ui-row-option-container'
    placement='left-start'
    trigger='click'
    triggerContent={
      <UIIcon
        icon='dotsVertical'
        iconClassName='dots-icon'
        iconColor='var(--gunmetal)'
        iconSize='1.2rem'
      />
    }
    tooltipContent={
      <ul role='menu' className='ui-dropdown-menu'>
        <li className='ui-dropdown-item' role='menuitem'>
          <button onClick={onEvictCacheByName} className='d-flex w-100'>
            Clear Cache
          </button>
        </li>
      </ul>
    }
  />
);

RowOptions.propTypes = {
  onEvictCacheByName: PropTypes.func,
};

export default RowOptions;
