import { combineReducers } from 'redux';
import * as types from '../types/common';
import createList from '../helpers/createList';
import createReducer from '../helpers/createReducer';

export const commonFilterDefaultState = {
  pagination: {
    page: 0,
    size: 10,
  },
  sort: {},
  filter: {},
};

const inRulesActions = {
  RESET: types.INRULES_LIST_RESET,
  REQUEST: types.INRULES_LIST_REQUEST,
  SUCCESS: types.INRULES_LIST_SUCCESS,
  FAILURE: types.INRULES_LIST_FAILURE,
  REMOVE: types.INRULES_LIST_REMOVE,
};

const countryListActions = {
  RESET: types.COUNTRY_LIST_RESET,
  REQUEST: types.COUNTRY_LIST_REQUEST,
  SUCCESS: types.COUNTRY_LIST_SUCCESS,
  FAILURE: types.COUNTRY_LIST_FAILURE,
  REMOVE: types.COUNTRY_LIST_REMOVE,
};

const docTypeListActions = {
  RESET: types.DOCTYPE_LIST_RESET,
  REQUEST: types.DOCTYPE_LIST_REQUEST,
  SUCCESS: types.DOCTYPE_LIST_SUCCESS,
  FAILURE: types.DOCTYPE_LIST_FAILURE,
  REMOVE: types.DOCTYPE_LIST_REMOVE,
};

const licenceListActions = {
  RESET: types.LICENCE_LIST_RESET,
  REQUEST: types.LICENCE_LIST_REQUEST,
  SUCCESS: types.LICENCE_LIST_SUCCESS,
  FAILURE: types.LICENCE_LIST_FAILURE,
};

const groupSubscribedCountryListActions = {
  RESET: types.GROUP_SUBSCRIBED_COUNTRY_LIST_RESET,
  REQUEST: types.GROUP_SUBSCRIBED_COUNTRY_LIST_REQUEST,
  SUCCESS: types.GROUP_SUBSCRIBED_COUNTRY_LIST_SUCCESS,
  FAILURE: types.GROUP_SUBSCRIBED_COUNTRY_LIST_FAILURE,
};

export default combineReducers({
  inRules: createList(inRulesActions),
  countryList: createList(countryListActions),
  docTypeList: createList(docTypeListActions),
  licenceList: createList(licenceListActions),
  groupSubscribedCountryList: createList(groupSubscribedCountryListActions),
  notification: createReducer(null, {
    [types.NOTIFICATION_SET]: (state, action) => action.notification || null,
  }),
  modal: createReducer(null, {
    [types.SHOW_MODAL]: (state, action) => action.modal || null,
  }),
  filters: createReducer(commonFilterDefaultState, {
    [types.RESET_PAGINATION]: (state, action) => action.filters || commonFilterDefaultState,
  }),
  notAuthorized: createReducer(null, {
    [types.NOT_AUTHORIZED]: (state, action) => action.notAuthorized || null,
  }),
  notSupportedIsins: createReducer([], {
    [types.SET_UPLOADED_NOT_SUPPORTED_ISINS_SUCCESS]: (state, action) =>
      action.notSupportedIsins || [],
    [types.SET_UPLOADED_NOT_SUPPORTED_ISINS_RESET]: () => [],
  }),
  invalidIsins: createReducer([], {
    [types.SET_UPLOADED_INVALID_ISINS_SUCCESS]: (state, action) => action.invalidIsins || [],
    [types.SET_UPLOADED_INVALID_ISINS_RESET]: () => [],
  }),
});
