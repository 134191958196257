import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UICustomLoader from '../common/components/UICustomLoader';

const AuthComponent = props => {
  const { children, only = [], except = [], state } = props;
  const check = fns => {
    return fns.some(fn => fn(state));
  };
  const hasPermissions = (only.length === 0 || check(only)) && !check(except);
  return hasPermissions ? children : <UICustomLoader loading loaderLabel='Initializing...' />;
};

AuthComponent.propTypes = {
  children: PropTypes.node,
  only: PropTypes.arrayOf(PropTypes.func),
  except: PropTypes.arrayOf(PropTypes.func),
  state: PropTypes.oneOfType([PropTypes.object]),
};

export default connect(state => ({ state }))(AuthComponent);
