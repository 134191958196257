import React from 'react';
import PropTypes from 'prop-types';

import UIButton from '../UIButton';

const TableNoData = ({ noDataState: { label, onClick } }) => (
  <div className='ui-no-data'>
    <div className='text-center pb-3'>{`There is no ${label} on the list`}</div>
    <UIButton height={50} className='body--bold mx-auto mt-3' onClick={onClick}>
      {`Create new ${label}`}
    </UIButton>
  </div>
);

TableNoData.propTypes = {
  noDataState: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
};

export default TableNoData;
