export const SUBSCRIPTION_LIST_RESET = 'SUBSCRIPTION_LIST_RESET';
export const SUBSCRIPTION_LIST_REQUEST = 'SUBSCRIPTION_LIST_REQUEST';
export const SUBSCRIPTION_LIST_SUCCESS = 'SUBSCRIPTION_LIST_SUCCESS';
export const SUBSCRIPTION_LIST_FAILURE = 'SUBSCRIPTION_LIST_FAILURE';
export const SUBSCRIPTION_LIST_REMOVE = 'SUBSCRIPTION_LIST_REMOVE';
export const SUBSCRIPTION_LIST_UPDATE = 'SUBSCRIPTION_LIST_UPDATE';

export const SEARCH_SUBSCRIPTION_LIST_RESET = 'SEARCH_SUBSCRIPTION_LIST_RESET';
export const SEARCH_SUBSCRIPTION_LIST_REQUEST = 'SEARCH_SUBSCRIPTION_LIST_REQUEST';
export const SEARCH_SUBSCRIPTION_LIST_SUCCESS = 'SEARCH_SUBSCRIPTION_LIST_SUCCESS';
export const SEARCH_SUBSCRIPTION_LIST_FAILURE = 'SEARCH_SUBSCRIPTION_LIST_FAILURE';
export const SEARCH_SUBSCRIPTION_LIST_REMOVE = 'SEARCH_SUBSCRIPTION_LIST_REMOVE';
export const SEARCH_SUBSCRIPTION_LIST_UPDATE = 'SEARCH_SUBSCRIPTION_LIST_UPDATE';

export const SEARCH_SUBSCRIPTION_TOTAL_RECEIVE = 'SEARCH_SUBSCRIPTION_TOTAL_RECEIVE';

export const SET_SUBSCRIPTION_FILTERS = 'SET_SUBSCRIPTION_FILTERS';

export const SET_SHOW_SUBSCRIPTION_MODAL = 'SET_SHOW_SUBSCRIPTION_MODAL';
export const SET_EDIT_SUBSCRIPTION_ID = 'SET_EDIT_SUBSCRIPTION_ID';

export const SUBSCRIPTION_RESET = 'SUBSCRIPTION_RESET';
export const SUBSCRIPTION_REQUEST = 'SUBSCRIPTION_REQUEST';
export const SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_SUCCESS';

export const ADDITIONAL_LANGUAGES_REQUEST = 'ADDITIONAL_LANGUAGES_REQUEST';
export const ADDITIONAL_LANGUAGES_SUCCESS = 'ADDITIONAL_LANGUAGES_SUCCESS';
