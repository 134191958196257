import get from 'lodash/get';
import saveAs from 'file-saver';
import * as groupTypes from '../types/groups';
import * as commonType from '../types/common';
import * as groupApi from '../../api/services/groups';
import { getIsFetching } from '../helpers/createList';
import { getParamObjectKeys, updateUrl } from '../../common/helpers/url';
import { hideModal, setNotification } from './common';

export const setGroupFilters = filters => dispatch =>
  dispatch({ type: groupTypes.SET_GROUP_FILTERS, filters });

export const setSelectedGroup = groupId => dispatch => {
  dispatch({ type: groupTypes.SET_SELECTED_GROUP, groupId });
};

export const updateGroupList = (key, value) => dispatch =>
  dispatch({ type: groupTypes.SEARCH_GROUP_LIST_UPDATE, key, value });

export const removeGroupByIndexFromList = index => dispatch =>
  dispatch({ type: groupTypes.SEARCH_GROUP_LIST_REMOVE, index });

export const getAllGroupList = () => (dispatch, getState) => {
  const state = getState();
  const {
    groups: { groupList },
  } = state;
  const isFetching = getIsFetching(groupList);
  if (isFetching) return;

  dispatch({ type: groupTypes.GROUP_LIST_REQUEST });

  // eslint-disable-next-line
  return groupApi
    .getAllGroups()
    .then(results => {
      return dispatch({
        type: groupTypes.GROUP_LIST_SUCCESS,
        results: get(results, 'data', []),
      });
    })
    .catch(() => {
      return dispatch({ type: groupTypes.GROUP_LIST_FAILURE, results: [] });
    });
};

export const getSearchGroupList = filters => (dispatch, getState) => {
  const state = getState();
  const {
    groups: { groupList, filters: stateFilters },
  } = state;
  const isFetching = getIsFetching(groupList);
  if (isFetching) return;

  dispatch({ type: groupTypes.SEARCH_GROUP_LIST_REQUEST });
  const queryParams = getParamObjectKeys(filters || stateFilters);
  updateUrl(queryParams);

  // eslint-disable-next-line
  return groupApi
    .getSearchGroups(queryParams)
    .then(results => {
      dispatch({
        type: groupTypes.SEARCH_GROUP_LIST_SUCCESS,
        results: get(results, 'data.content', []),
      });
      dispatch({
        type: groupTypes.SEARCH_GROUP_TOTAL_RECEIVE,
        total: get(results, 'data.totalElements', 0),
      });
    })
    .catch(err => {
      if (err.status === 403) {
        dispatch({ type: commonType.NOT_AUTHORIZED, notAuthorized: true });
      }
      dispatch({ type: groupTypes.SEARCH_GROUP_LIST_FAILURE, results: [] });
      dispatch({ type: groupTypes.SEARCH_GROUP_TOTAL_RECEIVE });
    });
};

export const getGroupById = id => (dispatch, getState) => {
  const state = getState();
  const {
    groups: { group },
  } = state;
  const isFetching = getIsFetching(group);
  if (isFetching) return;

  dispatch({ type: groupTypes.GROUP_REQUEST });
  groupApi
    .getGroupById(id)
    .then(results => {
      dispatch({ type: groupTypes.GROUP_SUCCESS, resource: get(results, 'data', null) });
    })
    .catch(() => {
      dispatch({
        type: groupTypes.GROUP_RESET,
      });
    });
};

export const resetGroup = () => dispatch => dispatch({ type: groupTypes.GROUP_RESET });

export const setEditGroupId = id => dispatch => {
  dispatch({ type: groupTypes.SET_EDIT_GROUP_ID, id });
};

export const createGroup = group => dispatch =>
  groupApi.createGroup(group).then(result => {
    const groupId = get(result, 'data.id');
    const groupName = get(result, 'data.name');
    if (groupId) {
      dispatch(getSearchGroupList());
      dispatch(getAllGroupList());
      dispatch(resetGroup());
      dispatch(setEditGroupId());
      dispatch(hideModal());
      dispatch(setNotification({ path: 'groups.create', listOfLabels: [groupName] }));
    }
  });

export const updateGroup = group => dispatch =>
  groupApi.updateGroup(group.id, group).then(result => {
    const updatedGroup = get(result, 'data', null);
    if (updatedGroup) {
      dispatch(getSearchGroupList());
      dispatch(getAllGroupList());
      dispatch(resetGroup());
      dispatch(setEditGroupId());
      dispatch(hideModal());
      dispatch(setNotification({ path: 'groups.update', listOfLabels: [updatedGroup.name] }));
    }
  });

export const deactivateGroup = id => dispatch =>
  groupApi.deactivateGroup(id).then(result => {
    const group = get(result, 'data', {});
    dispatch(setNotification({ path: 'groups.deactivate', listOfLabels: [group.name] }));
    dispatch(getSearchGroupList());
    dispatch(getAllGroupList());
  });

export const getApplicationAccess = () => dispatch =>
  groupApi
    .getApplicationAccess()
    .then(result => {
      return dispatch({
        type: groupTypes.APPLICATIONS_GROUP_LIST_SUCCESS,
        results: get(result, 'data', []),
      });
    })
    .catch(() => {
      return dispatch({ type: groupTypes.APPLICATIONS_GROUP_LIST_FAILURE });
    });

export const getAdditionalLanguages = () => dispatch =>
  groupApi
    .getAdditionalLanguages()
    .then(result => {
      return dispatch({
        type: groupTypes.LANGUAGES_LIST_SUCCESS,
        results: get(result, 'data', []),
      });
    })
    .catch(() => {
      return dispatch({ type: groupTypes.LANGUAGES_LIST_FAILURE });
    });

export const getStatisticsFilename = headers => {
  let fileName = 'statistics.xlsx';
  const contentDisposition = headers['content-disposition'];
  if (contentDisposition) {
    [, fileName] = contentDisposition.split('filename=');
  }
  return fileName;
};

export const getStatistics = (id, startDate, endDate) => dispatch =>
  groupApi
    .getStatistics(id, startDate, endDate)
    .then(({ data, headers }) => {
      saveAs(data, getStatisticsFilename(headers));
      return dispatch({
        type: groupTypes.STATISTICS,
        results: get(data, 'data', []),
      });
    })
    .catch(() => {
      return dispatch({ type: groupTypes.STATISTICS_FAILURE });
    });

export const getProfilesApplications = id => (dispatch, getState) => {
  const state = getState();
  const {
    groups: { profileApplications },
  } = state;
  const isFetching = getIsFetching(profileApplications);
  if (isFetching) return;

  dispatch({ type: groupTypes.APPLICATIONS_LIST_REQUEST });
  groupApi.getProfilesApplications(id).then(results => {
    return dispatch({
      type: groupTypes.APPLICATIONS_LIST_SUCCESS,
      results: get(results, 'data', []),
    });
  });
};

export const getExternalDataProviders = () => dispatch => {
  dispatch({ type: groupTypes.EXTERNAL_DATA_PROVIDERS_REQUEST });
  groupApi.getExternalDataProviders().then(providers => {
    dispatch({
      type: groupTypes.EXTERNAL_DATA_PROVIDERS_SUCCESS,
      resource: get(providers, 'data', null),
    });
  });
};

export const getESGProviders = () => dispatch => {
  dispatch({ type: groupTypes.ESG_PROVIDERS_REQUEST });
  groupApi.getESGProviders().then(providers => {
    dispatch({
      type: groupTypes.ESG_PROVIDERS_SUCCESS,
      resource: get(providers, 'data', null),
    });
  });
};

export const getSanctionSecuritiesSources = () => dispatch => {
  dispatch({ type: groupTypes.SANCTION_SECURITIES_SOURCES_REQUEST });
  groupApi.getSanctionSecuritiesSources().then(providers => {
    let { data } = providers;
    data = data.map(d => ({ code: d, name: d }));
    dispatch({
      type: groupTypes.SANCTION_SECURITIES_SOURCES_SUCCESS,
      resource: data,
    });
  });
};

export const getFundInfoFeeds = () => dispatch => {
  dispatch({ type: groupTypes.FUND_INFO_FEEDS_REQUEST });
  groupApi.getFundInfoFeeds().then(feeds => {
    let { data } = feeds;
    data = data.map(d => ({ code: d, name: d }));
    dispatch({
      type: groupTypes.FUND_INFO_FEEDS_SUCCESS,
      resource: data,
    });
  });
};

export const getInvestorSuitabilityProviders = () => dispatch => {
  dispatch({ type: groupTypes.INVESTOR_SUITABILITY_PROVIDERS_REQUEST });
  groupApi.getInvestorSuitabilityProviders().then(providers => {
    dispatch({
      type: groupTypes.INVESTOR_SUITABILITY_PROVIDERS_SUCCESS,
      resource: get(providers, 'data', null),
    });
  });
};

export const getInvestorSuitabilityKnowledge = () => dispatch => {
  dispatch({ type: groupTypes.INVESTOR_SUITABILITY_KNOWLEDGE_REQUEST });
  groupApi.getInvestorSuitabilityKnowledge().then(knowledge => {
    dispatch({
      type: groupTypes.INVESTOR_SUITABILITY_KNOWLEDGE_SUCCESS,
      resource: get(knowledge, 'data', null),
    });
  });
};

export const getIconColors = () => (dispatch, getState) => {
  const state = getState();
  const {
    groups: { iconColors },
  } = state;
  const isFetching = getIsFetching(iconColors);
  if (isFetching) return;

  dispatch({ type: groupTypes.ICON_COLORS_LIST_REQUEST });

  return groupApi
    .getIconColors()
    .then(colors => {
      return dispatch({
        type: groupTypes.ICON_COLORS_LIST_SUCCESS,
        results: get(colors, 'data', []),
      });
    })
    .catch(() => {
      return dispatch({ type: groupTypes.ICON_COLORS_LIST_FAILURE, results: [] });
    });
};
