import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import UIIcon from './UIIcon';

const UICheckbox = ({
  name = '',
  checked,
  label,
  onChange,
  intermid,
  wrapperClassName = '',
  className = '',
}) => (
  <label className={cx('ui-checkbox', wrapperClassName)}>
    <input name={name} type='checkbox' checked={checked} onChange={onChange} />
    <span
      className={cx(
        'ui-checkbox__tick',
        {
          'ui-checkbox__tick--intermid': intermid && checked,
          'ui-checkbox__tick--mr': label,
        },
        className,
      )}
    >
      {!intermid && checked && (
        <UIIcon icon='check' iconSize='0.9rem' iconClassName='d-flex' iconColor='var(--white)' />
      )}
    </span>
    {label}
  </label>
);

UICheckbox.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  intermid: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
};

export default UICheckbox;
