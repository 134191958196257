import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'reactstrap';

import UIModal from '../UIModal';
import UIButton from '../UIButton';

const UIConfirmationModal = ({
  toggle,
  onConfirmation,
  onReset,
  onCancel,
  cancelBtnTitle,
  confirmationBtnTitle,
  message,
}) => (
  <UIModal isOpen toggle={onReset} className='ui-edit-modal confirmation-modal'>
    <Form
      onSubmit={e => {
        e.preventDefault();
        onConfirmation();
        toggle();
      }}
      onReset={onReset}
    >
      {message && <div>{message}</div>}
      <div className='d-flex align-items-center mt-3'>
        <UIButton btnType='outline' className='w-100' height={50} onClick={() => onCancel()}>
          {cancelBtnTitle}
        </UIButton>
        <UIButton className='ml-3 w-100' height={50} type='submit'>
          {confirmationBtnTitle}
        </UIButton>
      </div>
    </Form>
  </UIModal>
);

UIConfirmationModal.propTypes = {
  toggle: PropTypes.func,
  onReset: PropTypes.func,
  onConfirmation: PropTypes.func,
  title: PropTypes.string,
  cancelBtnTitle: PropTypes.string,
  confirmationBtnTitle: PropTypes.string,
  message: PropTypes.string,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onReset: e => {
    e.preventDefault();
    dispatch(ownProps.toggle());
  },
});

export default connect(null, mapDispatchToProps)(UIConfirmationModal);
