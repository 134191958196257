import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import cx from 'classnames';

import UIIcon from './UIIcon';

const selectTypes = {
  default: Select,
  creatable: CreatableSelect,
};

const Option = ({ children, isMulti, innerProps, ...props }) => {
  return (
    <components.Option innerProps={innerProps} {...props}>
      {children}
      {isMulti && <UIIcon icon='plusCircle' iconColor='var(--gunmetal)' iconClassName='ml-1' />}
    </components.Option>
  );
};

Option.propTypes = {
  children: PropTypes.node,
  isMulti: PropTypes.bool,
  innerProps: PropTypes.oneOfType([PropTypes.object]),
};

const filterOptions = ({ label, value }, input) => {
  if (typeof label === 'string') {
    if (input) return label.toLowerCase().indexOf(input.toLowerCase()) !== -1;
  } else if (typeof value === 'string') {
    if (input) return value.toLowerCase().indexOf(input.toLowerCase()) !== -1;
  } else if (label.props) {
    return label.props.target.toLowerCase().indexOf(input.toLowerCase()) !== -1;
  }
  return true;
};

filterOptions.propTypes = {
  label: PropTypes.oneOfType[(PropTypes.string, PropTypes.object)],
  value: PropTypes.oneOfType[(PropTypes.number, PropTypes.string, PropTypes.object)],
};

const DropdownIndicator = props => <components.DropdownIndicator {...props} />;
const UISelect = ({ isMulti, hasError, isCreatable, ...props }) => {
  const Tag = isCreatable ? selectTypes.creatable : selectTypes.default;
  return (
    <React.Fragment>
      <Tag
        className={cx('ui-select-container', {
          multi: isMulti,
          error: hasError,
        })}
        classNamePrefix='ui-select'
        isMulti={isMulti}
        filterOption={filterOptions}
        {...props}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator: isMulti ? null : DropdownIndicator,
          Option,
        }}
      />
      {!props.disabled && props.required && (
        <input
          tabIndex={-1}
          value={props.value || ''}
          required={props.required}
          onChange={() => {}}
          style={{
            opacity: 0,
            width: 0,
            height: 0,
            position: 'absolute',
          }}
        />
      )}
    </React.Fragment>
  );
};

UISelect.propTypes = {
  isMulti: PropTypes.bool,
  isCreatable: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.number,
    PropTypes.string,
  ]),
  hasError: PropTypes.bool,
};

export default UISelect;
