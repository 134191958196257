import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Label } from 'reactstrap';

import UIModal from '../UIModal';
import UIButton from '../UIButton';
import { FormGroup } from '@material-ui/core';
import { isEqual } from 'lodash';
import UISelect from '../UISelect';
import { getAllGroupList as _getAllGroupList } from '../../../redux/actions/groups';
import { getAllProfileListByGroupId as _getAllProfileListByGroupId } from '../../../redux/actions/profiles';
import { getResults } from '../../../redux/helpers/createList';
import {
  moveUser as _moveUser,
  forceMoveUser as _forceMoveUser,
} from '../../../redux/actions/users';
import { showModal as _showModal } from '../../../redux/actions/common';

const UIMoveUserModal = ({
  toggle,
  onReset,
  getAllGroupList,
  groupList,
  getAllProfileListByGroupId,
  profileList,
  userId,
  moveUser,
  forceMoveUser,
  showModal,
  previousSelectedGroup,
  previousSelectedProfile,
}) => {
  const [selectedGroup, setSelectedGroup] = useState(previousSelectedGroup);
  const [selectedProfile, setSelectedProfile] = useState(previousSelectedProfile);
  const [errorState, setErrorState] = useState({});

  const errorsHandler = errors => {
    if (errors) {
      const { fieldErrors } = errors.data;
      fieldErrors.forEach(err => {
        setErrorState(prevState => ({
          ...prevState,
          [err.field]: err.message,
        }));
      });
    }
  };

  useEffect(() => {
    getAllGroupList();
  }, []);

  return (
    <UIModal isOpen toggle={onReset} title='Move User' className='ui-edit-modal moveUser-modal'>
      <Form
        onSubmit={e => {
          e.preventDefault();
          setErrorState({});
          moveUser(userId, selectedProfile?.id, selectedGroup?.id)
            .then(() => toggle())
            .catch(error => {
              if (error.status == 400) {
                errorsHandler(error);
              } else if (error.status == 406) {
                showModal('CONFIRMATION_MODAL', {
                  message:
                    'Profile deactivated: The user will be deactivated, do you want to continue?',
                  confirmationBtnTitle: 'Yes, move user',
                  cancelBtnTitle: 'No',
                  onCancel: () => {
                    showModal('MOVE_USER_MODAL', {
                      userId,
                      previousSelectedProfile: selectedProfile,
                      previousSelectedGroup: selectedGroup,
                    });
                  },
                  onConfirmation: () => {
                    forceMoveUser(userId, selectedProfile?.id, selectedGroup?.id).then();
                  },
                });
              }
            });
        }}
        onReset={onReset}
      >
        <FormGroup>
          <Label className='body body--bold mb-2'>Target Group</Label>
          <UISelect
            isSearchable
            getOptionLabel={({ name }) => name}
            getOptionValue={({ id }) => id}
            placeholder='Select a group'
            value={groupList.find(({ id }) => isEqual(id, selectedGroup?.id))}
            onChange={val => {
              setSelectedGroup(val);
              setSelectedProfile(null);
              getAllProfileListByGroupId(val.id);
            }}
            options={groupList}
            hasError={!!errorState.groupId}
          />
          <Label className='errorMessage'>{errorState.groupId}</Label>
        </FormGroup>
        <FormGroup>
          <Label className='body body--bold mb-2'>Profile</Label>
          <UISelect
            isSearchable
            getOptionLabel={({ name }) => name}
            getOptionValue={({ id }) => id}
            placeholder='Select a Profile'
            value={
              selectedProfile
                ? profileList.find(({ id }) => isEqual(id, selectedProfile?.id))
                : null
            }
            onChange={val => {
              setSelectedProfile(val);
            }}
            options={profileList}
            hasError={!!errorState.profileId}
          />
          <Label className='errorMessage'>{errorState.profileId}</Label>
        </FormGroup>
        <div className='d-flex justify-content-center pt-5'>
          <UIButton className='w-100' height={50} type='submit'>
            Move User
          </UIButton>
        </div>
        <div className='font-italic pt-5'>
          After clicking on “Move User” the user will be automatically moved to the selected target
          group and profile. He will keep his email address, name and login.
        </div>
      </Form>
    </UIModal>
  );
};

UIMoveUserModal.propTypes = {
  toggle: PropTypes.func,
  onReset: PropTypes.func,
  onSubmit: PropTypes.func,
  getAllGroupList: PropTypes.func,
  getAllProfileList: PropTypes.func,
  id: PropTypes.number,
  moveUser: PropTypes.func,
  showModal: PropTypes.func,
  forceMoveUser: PropTypes.func,
};

const mapStateToProps = ({ groups, profiles, users }) => ({
  groupList: getResults(groups.groupList),
  profileList: getResults(profiles.profileList),
  user: getResults(users.user),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAllGroupList: () => dispatch(_getAllGroupList()),
  getAllProfileListByGroupId: groupId => dispatch(_getAllProfileListByGroupId(groupId)),
  onReset: e => {
    e.preventDefault();
    dispatch(ownProps.toggle());
  },
  moveUser: (userId, profileId, groupId) => dispatch(_moveUser(userId, profileId, groupId)),
  forceMoveUser: (userId, profileId, groupId) =>
    dispatch(_forceMoveUser(userId, profileId, groupId)),
  showModal: (type, props) => dispatch(_showModal(type, props)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UIMoveUserModal);
