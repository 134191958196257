import { combineReducers } from 'redux';

import * as types from '../types/groups';
import createList from '../helpers/createList';
import createReducer from '../helpers/createReducer';
import createResource from '../helpers/createResource';

export const selectedGroupFromLS = localStorage.getItem('SELECTED_GROUP_ID');

export const groupFilterDefaultState = {
  pagination: {
    page: 0,
    size: 10,
    onlyActive: true,
  },
  sort: {},
  filter: {},
};

const groupListActions = {
  RESET: types.GROUP_LIST_RESET,
  REQUEST: types.GROUP_LIST_REQUEST,
  SUCCESS: types.GROUP_LIST_SUCCESS,
  FAILURE: types.GROUP_LIST_FAILURE,
  REMOVE: types.GROUP_LIST_REMOVE,
  UPDATE: types.GROUP_LIST_UPDATE,
};

const searchGroupListActions = {
  RESET: types.SEARCH_GROUP_LIST_RESET,
  REQUEST: types.SEARCH_GROUP_LIST_REQUEST,
  SUCCESS: types.SEARCH_GROUP_LIST_SUCCESS,
  FAILURE: types.SEARCH_GROUP_LIST_FAILURE,
  REMOVE: types.SEARCH_GROUP_LIST_REMOVE,
  UPDATE: types.SEARCH_GROUP_LIST_UPDATE,
};

const groupActions = {
  RESET: types.GROUP_RESET,
  REQUEST: types.GROUP_REQUEST,
  SUCCESS: types.GROUP_SUCCESS,
};

const applicationsAccessActions = {
  FAILURE: types.APPLICATIONS_GROUP_LIST_FAILURE,
  SUCCESS: types.APPLICATIONS_GROUP_LIST_SUCCESS,
};

const additionalLanguagesActions = {
  FAILURE: types.LANGUAGES_LIST_FAILURE,
  SUCCESS: types.LANGUAGES_LIST_SUCCESS,
};

const profilesApplicationsListActions = {
  RESET: types.APPLICATIONS_LIST_RESET,
  REQUEST: types.APPLICATIONS_LIST_REQUEST,
  SUCCESS: types.APPLICATIONS_LIST_SUCCESS,
  REMOVE: types.APPLICATIONS_LIST_REMOVE,
  UPDATE: types.APPLICATIONS_LIST_UPDATE,
};

const externalDataProvidersActions = {
  REQUEST: types.EXTERNAL_DATA_PROVIDERS_REQUEST,
  SUCCESS: types.EXTERNAL_DATA_PROVIDERS_SUCCESS,
};

const ESGDataProvidersActions = {
  REQUEST: types.ESG_PROVIDERS_REQUEST,
  SUCCESS: types.ESG_PROVIDERS_SUCCESS,
};

const sanctionSecuritiesSourcesActions = {
  REQUEST: types.SANCTION_SECURITIES_SOURCES_REQUEST,
  SUCCESS: types.SANCTION_SECURITIES_SOURCES_SUCCESS,
};

const investorSuitabilityProvidersActions = {
  REQUEST: types.INVESTOR_SUITABILITY_PROVIDERS_REQUEST,
  SUCCESS: types.INVESTOR_SUITABILITY_PROVIDERS_SUCCESS,
};
const investorSuitabilityKnowledgeActions = {
  REQUEST: types.INVESTOR_SUITABILITY_KNOWLEDGE_REQUEST,
  SUCCESS: types.INVESTOR_SUITABILITY_KNOWLEDGE_SUCCESS,
};

const fundInfoFeedsActions = {
  REQUEST: types.FUND_INFO_FEEDS_REQUEST,
  SUCCESS: types.FUND_INFO_FEEDS_SUCCESS,
};

const iconColorsActions = {
  REQUEST: types.ICON_COLORS_LIST_REQUEST,
  SUCCESS: types.ICON_COLORS_LIST_SUCCESS,
  RESET: types.ICON_COLORS_LIST_RESET,
};

export default combineReducers({
  groupList: createList(groupListActions),
  searchGroupList: createList(searchGroupListActions),
  total: createReducer(0, {
    [types.SEARCH_GROUP_TOTAL_RECEIVE]: (state, action) => action.total || 0,
  }),
  selectedGroupId: createReducer(selectedGroupFromLS, {
    [types.SET_SELECTED_GROUP]: (state, action) => action.groupId || null,
  }),
  filters: createReducer(groupFilterDefaultState, {
    [types.SET_GROUP_FILTERS]: (state, action) => action.filters || groupFilterDefaultState,
  }),
  group: createResource(groupActions),
  showGroupModal: createReducer(null, {
    [types.SET_SHOW_GROUP_MODAL]: (state, action) => action.show || null,
  }),
  editGroupId: createReducer(null, {
    [types.SET_EDIT_GROUP_ID]: (state, action) => action.id || null,
  }),
  applicationAccessList: createList(applicationsAccessActions),
  additionalLanguagesList: createList(additionalLanguagesActions),
  profileApplications: createList(profilesApplicationsListActions),
  externalDataProviders: createResource(externalDataProvidersActions),
  ESGDataProviders: createResource(ESGDataProvidersActions),
  sanctionSecuritiesSources: createResource(sanctionSecuritiesSourcesActions),
  fundInfoFeeds: createResource(fundInfoFeedsActions),
  investorSuitabilityProviders: createResource(investorSuitabilityProvidersActions),
  investorSuitabilityKnowledge: createResource(investorSuitabilityKnowledgeActions),
  iconColors: createList(iconColorsActions),
});
