import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import UIButton from './UIButton';
import UIIcon from './UIIcon';

const getPages = (total, page, pageSize) => {
  const totalPages = Math.ceil(total / pageSize);

  let startPage;
  let endPage;
  if (totalPages <= 10) {
    // less than 10 total pages so show all
    startPage = 1;
    endPage = totalPages;
  } else if (page <= 6) {
    startPage = 1;
    endPage = 10;
  } else if (page + 4 >= totalPages) {
    startPage = totalPages - 9;
    endPage = totalPages;
  } else {
    startPage = page - 5;
    endPage = page + 4;
  }

  // create an object with array of pages to epeat in the pager control and totalPages count
  return {
    totalPages,
    pages: [...Array(endPage + 1 - startPage).keys()].map(i => startPage + i),
  };
};

const UIPagination = ({ total, page = 0, pageSize = 10, onChange }) => {
  const { totalPages, pages } = getPages(total, page, pageSize);
  const isFirstPage = page === 0;
  const isLastPage = page === totalPages - 1;
  const change = val => onChange('page', val);

  return (
    <div className='ui-pagination'>
      <UIButton
        disabled={isFirstPage}
        btnType='outline'
        onClick={() => change(0)}
        className='prev-next'
      >
        <UIIcon icon='chevronDouble' iconSize='1.5rem' />
      </UIButton>
      <UIButton
        disabled={isFirstPage}
        btnType='outline'
        onClick={() => change(page - 1)}
        className='prev-next'
      >
        <UIIcon icon='chevronDown' iconSize='1.5rem' iconRotate={90} />
      </UIButton>
      {pages.map(pageItem => {
        const isActive = pageItem === page + 1;
        return (
          <UIButton
            key={pageItem}
            btnType={!isActive ? 'outline' : ''}
            onClick={() => {
              if (isActive) return;
              change(pageItem - 1);
            }}
            className={cx({ active: isActive })}
          >
            {pageItem}
          </UIButton>
        );
      })}
      <UIButton
        disabled={isLastPage}
        btnType='outline'
        onClick={() => change(page + 1)}
        className='prev-next'
      >
        <UIIcon icon='chevronDown' iconSize='1.5rem' iconRotate={270} />
      </UIButton>
      <UIButton
        disabled={isLastPage}
        btnType='outline'
        onClick={() => change(totalPages - 1)}
        className='prev-next'
      >
        <UIIcon icon='chevronDouble' iconSize='1.5rem' iconRotate={180} />
      </UIButton>
    </div>
  );
};

UIPagination.propTypes = {
  total: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default UIPagination;
