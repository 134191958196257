import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import Keycloak from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web';

import 'react-table/react-table.css';
import 'react-day-picker/lib/style.css';
import './styles/index.scss';
import { API_AUTH_CLIENT_ID, API_AUTH_REALM, API_AUTH_URL } from './common/environment';
import { login, logout } from './redux/actions/auth';
import auth from './redux/reducers/auth';
import common from './redux/reducers/common';
import groups from './redux/reducers/groups';
import management from './redux/reducers/management';
import entities from './redux/reducers/entities';
import subscriptions from './redux/reducers/subscriptions';
import profiles from './redux/reducers/profiles';
import users from './redux/reducers/users';
import DashboardContainer from './containers/DashboardContainer';
import Groups from './groups';
import Entities from './entities';
import Subscriptions from './subscriptions';
import Profiles from './profiles';
import Users from './users';
import Logout from './auth/Logout';
import CacheManagement from './management/cache/cache-management';
import FeatureManagement from './management/feature/feature-management';
import Auth from './containers/Auth';
import * as authApi from './api/services/auth';
import IsinsManagement from './management/isins/isins-management';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faTimesCircle,
  faBell,
  faCircle,
  faFileAlt,
  faPaintBrush,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faTimesCircle,
  faBell,
  faCircle,
  faFileAlt,
  faPaintBrush,
  faTimes,
);

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  router: connectRouter(history),
  auth,
  common,
  groups,
  entities,
  subscriptions,
  users,
  profiles,
  management,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk, routerMiddleware(history))),
);

export const keycloakInstance = new Keycloak({
  url: API_AUTH_URL,
  realm: API_AUTH_REALM,
  clientId: API_AUTH_CLIENT_ID,
});

const userWithProfile = ({ auth: { user, auth: authToken } }) => {
  return (user && authToken && keycloakInstance.authenticated) || false;
};

const MainRoutes = () => {
  return (
    <Fragment>
      <Auth only={[userWithProfile]}>
        <DashboardContainer>
          <Switch>
            <Redirect exact from='/' to='/groups' />
            <Route exact path='/groups' component={Groups} />
            <Route exact path='/entities' component={Entities} />
            <Route exact path='/subscriptions' component={Subscriptions} />
            <Route exact path='/users' component={Users} />
            <Route exact path='/profiles' component={Profiles} />
            <Route exact path='/features' component={FeatureManagement} />
            <Route exact path='/cache' component={CacheManagement} />
            <Route exact path='/isins/management/' component={IsinsManagement} />
            <Route exact path='/logout' component={Logout} />
          </Switch>
        </DashboardContainer>
      </Auth>
    </Fragment>
  );
};

const App = () => {
  return (
    <ReactKeycloakProvider
      authClient={keycloakInstance}
      initOptions={{ onLoad: 'login-required', pkceMethod: 'S256' }}
      onTokens={({ token }, error) => {
        if (error) {
          store.dispatch(logout());
        } else {
          authApi.setToken(token);
          store.dispatch(login(token));
        }
      }}
    >
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route component={MainRoutes} />
          </Switch>
        </ConnectedRouter>
      </Provider>
    </ReactKeycloakProvider>
  );
};

export default App;
