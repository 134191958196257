import { combineReducers } from 'redux';

import * as types from '../types/subscriptions';
import createList from '../helpers/createList';
import createReducer from '../helpers/createReducer';
import createResource from '../helpers/createResource';
import { formatDate } from '../../common/helpers/date';

export const subscriptionFilterDefaultState = {
  pagination: {
    page: 0,
    size: 10,
    endDateFrom: formatDate(new Date(new Date().setDate(new Date().getDate() + 1)), 'YYYY-MM-DD'),
  },
  sort: {},
  filter: {},
};

const subscriptionListActions = {
  RESET: types.SUBSCRIPTION_LIST_RESET,
  REQUEST: types.SUBSCRIPTION_LIST_REQUEST,
  SUCCESS: types.SUBSCRIPTION_LIST_SUCCESS,
  FAILURE: types.SUBSCRIPTION_LIST_FAILURE,
  REMOVE: types.SUBSCRIPTION_LIST_REMOVE,
  UPDATE: types.SUBSCRIPTION_LIST_UPDATE,
};

const searchSubscriptionListActions = {
  RESET: types.SEARCH_SUBSCRIPTION_LIST_RESET,
  REQUEST: types.SEARCH_SUBSCRIPTION_LIST_REQUEST,
  SUCCESS: types.SEARCH_SUBSCRIPTION_LIST_SUCCESS,
  FAILURE: types.SEARCH_SUBSCRIPTION_LIST_FAILURE,
  REMOVE: types.SEARCH_SUBSCRIPTION_LIST_REMOVE,
  UPDATE: types.SEARCH_SUBSCRIPTION_LIST_UPDATE,
};

const subscriptionActions = {
  RESET: types.SUBSCRIPTION_RESET,
  REQUEST: types.SUBSCRIPTION_REQUEST,
  SUCCESS: types.SUBSCRIPTION_SUCCESS,
};
const languagesListActions = {
  REQUEST: types.ADDITIONAL_LANGUAGES_REQUEST,
  SUCCESS: types.ADDITIONAL_LANGUAGES_SUCCESS,
};

export default combineReducers({
  subscriptionList: createList(subscriptionListActions),
  searchSubscriptionList: createList(searchSubscriptionListActions),
  total: createReducer(0, {
    [types.SEARCH_SUBSCRIPTION_TOTAL_RECEIVE]: (state, action) => action.total || 0,
  }),
  filters: createReducer(subscriptionFilterDefaultState, {
    [types.SET_SUBSCRIPTION_FILTERS]: (state, action) =>
      action.filters || subscriptionFilterDefaultState,
  }),
  subscription: createResource(subscriptionActions),
  showSubscriptionModal: createReducer(null, {
    [types.SET_SHOW_SUBSCRIPTION_MODAL]: (state, action) => action.show || null,
  }),
  editSubscriptionId: createReducer(null, {
    [types.SET_EDIT_SUBSCRIPTION_ID]: (state, action) => action.id || null,
  }),
  languagesList: createResource(languagesListActions),
});
