import axios from 'axios';

import { API_URL } from '../common/environment';
import { history } from './../App';

const DEFAULT_CONTENT_TYPE = 'application/json';

const getClient = (baseUrl = null) => {
  const options = {
    baseURL: baseUrl,
  };

  const client = axios.create(options);

  client.interceptors.response.use(
    response => response,
    error => {
      if (error && error.response && error.response.status === 401) {
        history.push('/logout');
      }

      return Promise.reject(error.response);
    },
  );

  return client;
};

class ApiClient {
  constructor(baseUrl, contentType) {
    this.client = getClient(baseUrl);
    this.client.defaults.headers.common['Content-Type'] = contentType;
  }

  getHeader(key) {
    return this.client.defaults.headers.common[key];
  }
  setHeader(key, value) {
    this.client.defaults.headers.common[key] = value;
    return this;
  }

  get(path, format) {
    return this.request('get', path, undefined, format);
  }

  post(path, payload) {
    return this.request('post', path, payload);
  }

  delete(path) {
    return this.request('delete', path);
  }

  put(path, payload) {
    return this.request('put', path, payload);
  }

  patch(path, payload) {
    return this.request('patch', path, payload);
  }

  request(method, path, payload, format) {
    const promise = new Promise((resolve, reject) => {
      this.client
        .request({
          method,
          url: path,
          data: payload,
          responseType: format || 'json',
        })
        .then((...args) => {
          resolve(...args);
        })
        .catch(error => {
          if (error) {
            if (error.status === 400) {
              return reject(error);
            }
            if (error.status < 500) {
              return reject(error);
            } else if (error.statusText) {
              return reject(error.statusText);
            }
          }
          return reject(Error(error));
        });
    });
    return promise;
  }
}

export default new ApiClient(API_URL, DEFAULT_CONTENT_TYPE);
