import apiClient from '../api';

const GROUPS_URI = '/inauth/groups';
const SUBSCRIPTIONS_URI = '/inauth/subscriptions';

export const getAllSubscriptions = groupId =>
  apiClient.get(`${GROUPS_URI}/${groupId}/subscriptions`);

export const getSearchSubscriptions = (groupId, queryParams) =>
  apiClient.get(`${GROUPS_URI}/${groupId}/subscriptions/search?${queryParams}`);

export const getSubscriptionById = id => apiClient.get(`${SUBSCRIPTIONS_URI}/${id}`);

export const createSubscription = payload => apiClient.post(SUBSCRIPTIONS_URI, payload);

export const updateSubscription = (id, payload) =>
  apiClient.put(`${SUBSCRIPTIONS_URI}/${id}`, payload);

export const deactivateSubscription = id => apiClient.post(`${SUBSCRIPTIONS_URI}/${id}/deactivate`);

export const reactivateSubscription = id => apiClient.post(`${SUBSCRIPTIONS_URI}/${id}/reactivate`);

export const exportSubscriptions = groupId =>
  apiClient.get(`${GROUPS_URI}/${groupId}/subscriptions/export`, 'blob');

export const getLanguagesByGroupId = groupId => apiClient.get(`${GROUPS_URI}/${groupId}/languages`);
