import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import cx from 'classnames';

import UIIconButton from './UIIconButton';

const UIModal = ({ children, title, className, toggle, ...props }) => {
  return (
    <Modal
      backdrop='static'
      toggle={toggle}
      contentClassName={cx('ui-modal', className)}
      className='ui-modal-dialog'
      backdropClassName='ui-modal-backdrop'
      {...props}
    >
      <div className='header'>
        <UIIconButton
          icon='close'
          className='ml-auto'
          iconSize='1.5rem'
          iconColor='var(--gunmetal)'
          onClick={toggle}
        />
        {title && <h2 className='title title--h1 mb-4'>{title}</h2>}
      </div>
      <div className='content'>{children}</div>
    </Modal>
  );
};

UIModal.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isOpen: PropTypes.bool.isRequired,
  className: PropTypes.string,
  toggle: PropTypes.func,
};

export default UIModal;
