import apiClient from '../api';

const GROUPS_URI = '/inauth/groups';
const GET_INRULES_URI = '/inauth/inrulesversions';
const GET_COUNTRIES_URI = '/inauth/countries';
const GET_DOC_TYPE_URI = '/inauth/documenttypes';
const GET_LICENCE_URI = '/inauth/licenses';
const UPLOAD_ISINS_URI = '/inauth/isins';

export const getInRules = () => apiClient.get(GET_INRULES_URI);

export const getCountryList = () => apiClient.get(GET_COUNTRIES_URI);

export const getDocTypeList = () => apiClient.get(GET_DOC_TYPE_URI);

export const getLicenceList = () => apiClient.get(GET_LICENCE_URI);

export const getGroupSubscribedCountryList = (groupId, docTypeId) =>
  apiClient.get(`${GROUPS_URI}/${groupId}/countries/${docTypeId}`);

export const getApiCountryList = (groupId, docTypeId) =>
  apiClient.get(`${GROUPS_URI}/${groupId}/countries/${docTypeId}`);

export const uploadISINs = payload => apiClient.put(`${UPLOAD_ISINS_URI}`, payload);
