import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Form } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import UIModal from '../UIModal';
import * as toastr from 'toastr';
import UIButton from '../UIButton';
import UIIcon from '../UIIcon';
import UIIconButton from '../UIIconButton';
import UICustomLoader from '../UICustomLoader';
import {
  setUploadedInvalidIsins as _setUploadedInvalidIsins,
  setUploadedNotSupportedIsins as _setUploadedNotSupportedIsins,
  showModal,
} from '../../../redux/actions/common';

const UIUploadModal = ({
  title,
  toggle,
  onSubmit,
  onReset,
  fieldName,
  type,
  setUploadedInvalidIsins,
  setUploadedNotSupportedIsins,
  setErrorModalMessage,
}) => {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  const onDrop = useCallback(acceptedFiles => {
    setFile(acceptedFiles[0]);
  }, []);

  const resetFile = e => {
    e.preventDefault();
    setFile(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  function errorsToSingleMessage(errors) {
    let message = '';
    for (let i = 0; i < errors.length; i++) {
      const error = errors[i];
      message += 'Entry ' + i + 1 + " has field '" + error.field + "' where " + error.message;
    }
    return message;
  }

  return (
    <UIModal
      isOpen
      toggle={onReset}
      title={
        <span>
          {title}
          <span className='optional-text'>(CSV files)</span>
        </span>
      }
      className='ui-edit-modal upload-modal'
    >
      <Form
        onSubmit={e => {
          e.preventDefault();
          setIsLoading(true);
          const formData = new FormData();
          formData.append(fieldName, file);
          onSubmit(formData)
            .then(result => {
              if (type === 'isins') {
                setUploadedInvalidIsins(result.data.invalidISINs);
                setUploadedNotSupportedIsins(result.data.notSupportedISINs);
              }
              setIsLoading(false);
              toggle();
            })
            .catch(error => {
              let errorModalMessage =
                error.data.message || errorsToSingleMessage(error?.data?.fieldErrors);
              setErrorModalMessage(errorModalMessage);
              setIsLoading(false);
            });
        }}
        onReset={onReset}
      >
        <div {...getRootProps({ className: cx('drop-zone', { isDrag: isDragActive }) })}>
          <input {...getInputProps()} />
          <UIIcon
            icon='cloudUpload'
            iconColor={isDragActive ? 'var(--dodger-blue)' : 'var(--light-grey-blue)'}
            iconSize='3rem'
          />
          <div>Drag & Drop a File, or Click Here</div>
        </div>
        <div>
          <UICustomLoader loading={isLoading} />
        </div>
        <div className='d-flex align-items-center mt-3'>
          <UIButton className='w-100' height={50} type='submit' disabled={!file}>
            Upload
          </UIButton>
          {file && (
            <div className='d-flex align-items-center ml-5'>
              {file.name}
              <UIIconButton icon='close' className='ml-2' onClick={resetFile} />
            </div>
          )}
        </div>
      </Form>
    </UIModal>
  );
};

UIUploadModal.propTypes = {
  toggle: PropTypes.func,
  onReset: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  backBtnTitle: PropTypes.string,
  submitBtnTitle: PropTypes.string,
  subtitle: PropTypes.string,
  setUploadedInvalidIsins: PropTypes.func,
  setUploadedNotSupportedIsins: PropTypes.func,
  setErrorModalMessage: PropTypes.func,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onReset: e => {
    e.preventDefault();
    dispatch(ownProps.toggle());
  },
  setUploadedInvalidIsins: isins => dispatch(_setUploadedInvalidIsins(isins)),
  setUploadedNotSupportedIsins: isins => dispatch(_setUploadedNotSupportedIsins(isins)),
  setErrorModalMessage: message =>
    dispatch(showModal('ERROR_MODAL', { title: 'CSV upload errors', errorMsg: message })),
});

export default connect(null, mapDispatchToProps)(UIUploadModal);
