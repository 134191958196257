import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Switch from 'react-switch';
import { getIsFetching, getResults } from '../../redux/helpers/createList';
import {
  getAllFeaturesList as _getAllFeaturesList,
  toggleFeature as _toggleFeature,
} from '../../redux/actions/management';
import TableRender from '../../containers/TableRender';

class FeatureManagement extends Component {
  componentDidMount() {
    const { getAllFeaturesList } = this.props;
    getAllFeaturesList();
  }

  noDataState = {
    label: 'Feature',
    onClick: e => {
      e.preventDefault();
    },
  };

  render() {
    const {
      featuresList,
      totalFeatures,
      isLoading,
      getAllFeaturesList,
      toggleFeature,
    } = this.props;

    const handleChange = (id, checked) => {
      let feature = this.props.featuresList.find(elem => elem.id === id);
      feature.enabled = checked;
      this.setState({ checked: checked });
      toggleFeature(id, feature);
    };

    const columns = [
      {
        title: 'Feature',
        filterKey: 'name',
        sortKey: 'name',
        accessor: 'name',
        Cell: ({ original: { name } }) => <div className='d-flex align-items-center'>{name}</div>,
      },
      {
        Header: () => 'Not Active/Active',
        Cell: ({ original: { id, enabled } }) => (
          <Switch onChange={checked => handleChange(id, checked)} checked={enabled} />
        ),
      },
    ];
    return (
      <div>
        <div className='title'>Features Management</div>
        <TableRender
          showPagination={false}
          canRowSelect={false}
          isLoading={isLoading}
          data={featuresList}
          columns={columns}
          total={totalFeatures}
          noDataState={this.noDataState}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ management }) => ({
  featuresList: getResults(management.featuresList),
  totalFeatures: management.totalFeatures,
  isLoading: getIsFetching(management.featuresList),
});

const mapDispatchToProps = dispatch => ({
  getAllFeaturesList: () => dispatch(_getAllFeaturesList()),
  toggleFeature: (id, payload) => dispatch(_toggleFeature(id, payload)),
});

FeatureManagement.propTypes = {
  featuresList: PropTypes.arrayOf(PropTypes.object),
  getAllFeaturesList: PropTypes.func,
  toggleFeature: PropTypes.func,
  totalFeatures: PropTypes.number,
  isLoading: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(FeatureManagement);
