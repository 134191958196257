import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import TableRender from '../containers/TableRender';
import { getIsFetching, getResults } from '../redux/helpers/createList';
import {
  deactivateGroup,
  getSearchGroupList,
  setEditGroupId,
  setGroupFilters,
} from '../redux/actions/groups';
import { paramObject } from '../common/helpers/url';
import GroupFormModal from './GroupFormModal';
import UISidebar from '../common/components/UISidebar';
import { showModal } from '../redux/actions/common';
import RowOptions from './RowOptions';
import ActiveIndicator from '../common/components/Table/ActiveIndicator';
import NotAuthorizedPage from '../common/components/NotAuthorizedPage';
import { hasRole, Roles } from '../common/helpers/roles';

const Groups = ({
  groupList,
  isLoading,
  showGroupModal,
  getGroups,
  filters,
  total,
  setFilters,
  setEditGroup,
  showDeactivateModal,
  deactivateGroupById,
  showStatisticsExportModal,
  notAuthorized,
  userInformation,
}) => {
  const [onlyActive, setOnlyActive] = useState(filters.pagination.onlyActive);
  const applyFiltersAndFetch = filter => {
    setFilters(filter);
    getGroups(filter);
  };

  useEffect(() => {
    const urlFilters = paramObject();
    const newFilter = isEmpty(urlFilters) ? filters : { ...filters, ...urlFilters };
    applyFiltersAndFetch(newFilter);
  }, []);

  const generateColumns = () => {
    const columns = [
      {
        title: 'Entity',
        filterKey: 'name',
        sortKey: 'name',
        accessor: 'name',
        // eslint-disable-next-line
        Cell: ({ original: { name, active } }) => (
          <div className='d-flex align-items-center'>
            <ActiveIndicator active={active} />
            {name}
          </div>
        ),
      },
    ];

    if (hasRole(Roles.amtClientSetup, userInformation?.amtRoles)) {
      columns.push({
        Header: () => '',
        // eslint-disable-next-line
        Cell: ({ original: { id } }) => (
          <RowOptions
            onEdit={e => {
              e.preventDefault();
              setEditGroup(id);
            }}
            onDeactivate={e => {
              e.preventDefault();
              showDeactivateModal('DEACTIVATE_MODAL', {
                title: 'Do you really want to deactivate the group?',
                submitBtnTitle: 'Deactivate',
                backBtnTitle: 'Go back to groups dashboard',
                onSubmit: () => deactivateGroupById(id),
              });
            }}
            onStatisticsExport={e => {
              e.preventDefault();
              showStatisticsExportModal('STATISTICS_EXPORT_MODAL', { groupId: id });
            }}
          />
        ),
        maxWidth: 40,
      });
    }
    return columns;
  };

  const generateSidebarStateList = () => {
    const list = [
      {
        label: 'List',
        icon: 'listSquare',
        visible: true,
      },
    ];

    if (hasRole(Roles.amtClientSetup, userInformation?.amtRoles)) {
      list.push(
        {
          label: 'Add new',
          icon: 'plus',
          onClick: e => {
            e.preventDefault();
            setEditGroup(null);
          },
        },
        {
          label: onlyActive ? 'Only active entries' : 'All entries',
          icon: onlyActive ? 'switchClose' : 'switch',
          onClick: e => {
            e.preventDefault();
            const newFilter = {
              pagination: {
                page: 0,
                size: 10,
                onlyActive: !onlyActive,
              },
            };
            setOnlyActive(!onlyActive);
            getGroups(newFilter);
            setFilters(newFilter);
          },
        },
      );
    }
    return list;
  };

  const sidebarState = {
    mainLabel: 'Groups',
    mainLabelIcon: 'bank',
    list: generateSidebarStateList(),
  };

  const noDataState = {
    label: 'group',
    onClick: e => {
      e.preventDefault();
      setEditGroup(null);
    },
  };
  return !notAuthorized ? (
    <div className='content__with-sidebar'>
      <UISidebar sidebarState={sidebarState} />
      <TableRender
        canRowSelect={false}
        isLoading={isLoading}
        data={groupList}
        total={total}
        filters={filters}
        columns={generateColumns()}
        noDataState={noDataState}
        onFiltersAndSortingChange={applyFiltersAndFetch}
      />
      {showGroupModal && <GroupFormModal />}
    </div>
  ) : (
    <NotAuthorizedPage />
  );
};

const mapStateToProps = ({ groups, common, users }) => ({
  groupList: getResults(groups.searchGroupList),
  isLoading: getIsFetching(groups.searchGroupList),
  filters: groups.filters,
  total: groups.total,
  showGroupModal: groups.showGroupModal,
  notAuthorized: common.notAuthorized,
  userInformation: getResults(users.userInformation),
});

const mapDispatchToProps = dispatch => {
  return {
    setFilters: filters => dispatch(setGroupFilters(filters)),
    getGroups: filters => dispatch(getSearchGroupList(filters)),
    setEditGroup: id => {
      dispatch(setEditGroupId(id));
      dispatch(showModal('GROUP_MODAL'));
    },
    showDeactivateModal: (type, props) => dispatch(showModal(type, props)),
    deactivateGroupById: id => dispatch(deactivateGroup(id)),
    showStatisticsExportModal: (type, props) => dispatch(showModal(type, props)),
  };
};

Groups.propTypes = {
  groupList: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  filters: PropTypes.oneOfType([PropTypes.object]),
  total: PropTypes.number,
  showGroupModal: PropTypes.bool,
  setFilters: PropTypes.func,
  getGroups: PropTypes.func,
  setEditGroup: PropTypes.func,
  showDeactivateModal: PropTypes.func,
  deactivateGroupById: PropTypes.func,
  showStatisticsExportModal: PropTypes.func,
  notAuthorized: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
