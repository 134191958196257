import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withKeycloak } from '@react-keycloak/web';
import React from 'react';
import UIIcon from './UIIcon';
import { logout } from '../../redux/actions/auth';
import UIButton from './UIButton';

const NotAuthorizedPage = ({ onLogout }) => {
  return (
    <div className='notAuthorizedContainer'>
      <div className='warning'>Warning</div>
      <div className='message'>
        <div className='text'>Not Authorized Error</div>
        <UIIcon icon='lock' iconColor='var(--black)' iconSize='3.5rem' />
      </div>
      <UIButton className='mt-2' width={125} height={35} onClick={() => onLogout()}>
        Go to Login
      </UIButton>
    </div>
  );
};

NotAuthorizedPage.propTypes = {
  onLogout: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch(logout()),
});

export default connect(null, mapDispatchToProps)(withKeycloak(NotAuthorizedPage));
