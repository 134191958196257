import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'reactstrap';

import UIModal from '../UIModal';
import UIButton from '../UIButton';

const UIDeleteModal = ({
  toggle,
  onSubmit,
  onReset,
  title,
  backBtnTitle,
  submitBtnTitle,
  subtitle,
}) => (
  <UIModal isOpen toggle={onReset} title={title} className='ui-edit-modal delete-modal'>
    <Form
      onSubmit={e => {
        e.preventDefault();
        onSubmit();
        toggle();
      }}
      onReset={onReset}
    >
      {subtitle && <div>{subtitle}</div>}
      <div className='d-flex justify-content-between'>
        <UIButton className='w-100' height={50} type='submit'>
          {submitBtnTitle}
        </UIButton>
        <UIButton btnType='outline' className='w-100' height={50} type='reset'>
          {backBtnTitle}
        </UIButton>
      </div>
    </Form>
  </UIModal>
);

UIDeleteModal.propTypes = {
  toggle: PropTypes.func,
  onReset: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  backBtnTitle: PropTypes.string,
  submitBtnTitle: PropTypes.string,
  subtitle: PropTypes.string,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onReset: e => {
    e.preventDefault();
    dispatch(ownProps.toggle());
  },
});

export default connect(null, mapDispatchToProps)(UIDeleteModal);
