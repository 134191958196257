import apiClient from '../api';

const GROUPS_URI = '/inauth/groups';
const USERS_URI = '/inauth/users';

export const getAllUsers = groupId => apiClient.get(`${GROUPS_URI}/${groupId}/users/`);

export const getSearchUsers = (groupId, queryParams) =>
  apiClient.get(`${GROUPS_URI}/${groupId}/users/search?${queryParams}`);

export const getUserById = id => apiClient.get(`${USERS_URI}/${id}`);

export const createUser = payload => apiClient.post(USERS_URI, payload);

export const updateUser = (id, payload) => apiClient.put(`${USERS_URI}/${id}`, payload);

export const deactivateUser = id => apiClient.post(`${USERS_URI}/${id}/deactivate`);

export const reactivateUser = id => apiClient.post(`${USERS_URI}/${id}/reactivate`);

export const deactivateUsers = ids => apiClient.post(`${USERS_URI}/deactivate?userIds=${ids}`);

export const uploadUsers = (groupId, payload) =>
  apiClient.post(`${GROUPS_URI}/${groupId}/users/import`, payload);

export const exportUsers = groupId =>
  apiClient.get(`${GROUPS_URI}/${groupId}/users/export`, 'blob');

export const apiAccess = () => apiClient.get(`${USERS_URI}/api_access`);

export const getAMTRoles = () => apiClient.get(`${USERS_URI}/amt_roles`);

export const getInAppRoles = () => apiClient.get(`${USERS_URI}/in_app_roles`);

export const getUserInformation = () => apiClient.get(`/inauth/user/me`);

export const deleteUser = id => apiClient.delete(`${USERS_URI}/${id}/delete`);

export const moveUser = (id, payload) => apiClient.post(`${USERS_URI}/${id}/move`, payload);

export const forceMoveUser = (id, payload) =>
  apiClient.post(`${USERS_URI}/${id}/move/force`, payload);
