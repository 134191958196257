import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import TableRender from '../containers/TableRender';
import { getIsFetching, getResults } from '../redux/helpers/createList';
import {
  deactivateSubscription,
  reactivateSubscription,
  exportSubscriptions,
  getSearchSubscriptionList,
  setEditSubscriptionId,
  setShowSubscriptionModal,
  setSubscription,
  setSubscriptionFilters,
} from '../redux/actions/subscription';
import RowOptions from './RowOptions';
import { BadgeListWithDots } from '../common/components/Table/BadgeCell';
import SubscriptionFormModal from './SubscriptionFormModal';
import UISidebar from '../common/components/UISidebar';
import { showModal } from '../redux/actions/common';
import { formatDate, getActiveByStartEndDates } from '../common/helpers/date';
import ActiveIndicator from '../common/components/Table/ActiveIndicator';
import { hasRole, Roles } from '../common/helpers/roles';

const Subscriptions = ({
  subscriptionList,
  isLoading,
  getSubscriptions,
  filters,
  total,
  setFilters,
  groupId,
  setEditSubscription,
  showSubscriptionModal,
  showDeactivateModal,
  deactivateSubscriptionById,
  setSubscriptionForDuplicate,
  exportSubscriptionList,
  reactivateSubscriptionById,
  userInformation,
}) => {
  const [onlyActive, setOnlyActive] = useState(filters.pagination.endDateFrom);
  // This works, but we might need to consider another option, like not using a "Router" anymore.
  if (groupId === null) return <Redirect to='/' />;

  const applyFiltersAndFetch = filter => {
    setFilters(filter);
    getSubscriptions(filter, groupId);
  };

  useEffect(() => {
    const newFilter = { pagination: filters.pagination, filter: {} };
    applyFiltersAndFetch(newFilter);
  }, [groupId]);

  const generateColumns = () => {
    const columns = [
      {
        title: 'Document type',
        filterKey: 'docTypeId',
        sortKey: 'docType',
        // eslint-disable-next-line
        Cell: ({ original: { docTypeId, startDate, endDate } }) => (
          <div className='d-flex align-items-center'>
            <ActiveIndicator active={getActiveByStartEndDates(startDate, endDate)} />
            {docTypeId}
          </div>
        ),
      },
      {
        title: 'Target/s',
        filterKey: 'targetCountries',
        // eslint-disable-next-line
        Cell: ({ original: { targetCountries, targetCountriesPPC, docTypeId } }) => (
          <BadgeListWithDots
            key={`${docTypeId}-inApp`}
            array={targetCountries}
            ppcArray={targetCountriesPPC}
          />
        ),
      },
      {
        title: 'Source/s',
        filterKey: 'sourceCountries',
        // eslint-disable-next-line
        Cell: ({ original: { sourceCountries } }) => <BadgeListWithDots array={sourceCountries} />,
      },
      {
        title: 'Start date',
        type: 'date',
        dateFromFilter: 'startDateFrom',
        dateToFilter: 'startDateTo',
        sortKey: 'startDate',
        Cell: ({ original: { startDate } }) =>
          startDate || <div className='ui-td__no-val'>No date</div>,
        maxWidth: 130,
      },
      {
        title: 'End date',
        type: 'date',
        dateFromFilter: 'endDateFrom',
        dateToFilter: 'endDateTo',
        sortKey: 'endDate',
        Cell: ({ original: { endDate } }) =>
          endDate || <div className='ui-td__no-val'>No date</div>,
        maxWidth: 130,
      },
      {
        title: 'Renew date',
        type: 'date',
        dateFromFilter: 'contractRenewDateFrom',
        dateToFilter: 'contractRenewDateTo',
        sortKey: 'contractRenewDate',
        Cell: ({ original: { contractRenewDate } }) =>
          contractRenewDate || <div className='ui-td__no-val'>No date</div>,
        maxWidth: 130,
      },
    ];

    if (hasRole(Roles.amtClientSetup, userInformation?.amtRoles)) {
      columns.push({
        Header: () => '',
        // eslint-disable-next-line
        Cell: ({ original: { id, startDate, endDate, ...rest } }) => (
          <RowOptions
            onEdit={e => {
              e.preventDefault();
              setEditSubscription(id, true);
            }}
            onDeactivate={e => {
              e.preventDefault();
              showDeactivateModal('DEACTIVATE_MODAL', {
                title: 'Do you really want to deactivate the subscription?',
                submitBtnTitle: 'Deactivate',
                backBtnTitle: 'Go back to subscriptions dashboard',
                onSubmit: () => deactivateSubscriptionById(id),
              });
            }}
            onReactivate={() => reactivateSubscriptionById(id)}
            onDuplicate={e => {
              e.preventDefault();
              setEditSubscription(null, true);
              setSubscriptionForDuplicate(rest);
            }}
            isActive={getActiveByStartEndDates(startDate, endDate)}
          />
        ),
        maxWidth: 40,
        className: 'h-100',
      });
    }
    return columns;
  };

  const getOnlyActiveFilter = () => {
    const newFilter = {
      pagination: {
        page: 0,
        size: 10,
        endDateFrom: formatDate(
          new Date(new Date().setDate(new Date().getDate() + 1)),
          'YYYY-MM-DD',
        ),
      },
    };
    return newFilter;
  };

  const getAllFilter = () => {
    const newFilter = {
      pagination: {
        page: 0,
        size: 10,
      },
    };
    return newFilter;
  };

  const generateSidebarStateList = () => {
    const list = [
      {
        label: 'List',
        icon: 'listSquare',
        visible: true,
      },
    ];

    if (hasRole(Roles.amtClientSetup, userInformation?.amtRoles)) {
      list.push(
        {
          label: 'Add new',
          icon: 'plus',
          onClick: e => {
            e.preventDefault();
            setEditSubscription(null, true);
          },
        },
        {
          label: 'Download list',
          icon: 'downloadFile',
          onClick: e => {
            e.preventDefault();
            exportSubscriptionList();
          },
        },
        {
          label: onlyActive ? 'Only active entries' : 'All entries',
          icon: onlyActive ? 'switchClose' : 'switch',
          onClick: e => {
            e.preventDefault();
            setOnlyActive(!onlyActive);
            const newFilter = onlyActive ? getAllFilter() : getOnlyActiveFilter();
            getSubscriptions(newFilter);
            setFilters(newFilter);
          },
        },
      );
    }
    return list;
  };

  const sidebarState = {
    mainLabel: 'Subscriptions',
    mainLabelIcon: 'bookMulti',
    list: generateSidebarStateList(),
  };

  const noDataState = {
    label: 'subscription',
    onClick: e => {
      e.preventDefault();
      setEditSubscription(null, true);
    },
  };

  return (
    <div className='content__with-sidebar'>
      <UISidebar sidebarState={sidebarState} />
      <TableRender
        canRowSelect={false}
        isLoading={isLoading}
        data={subscriptionList}
        total={total}
        filters={filters}
        noDataState={noDataState}
        columns={generateColumns()}
        onFiltersAndSortingChange={applyFiltersAndFetch}
      />
      {showSubscriptionModal && <SubscriptionFormModal />}
    </div>
  );
};

Subscriptions.propTypes = {
  subscriptionList: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  filters: PropTypes.oneOfType([PropTypes.object]),
  total: PropTypes.number,
  setFilters: PropTypes.func,
  getSubscriptions: PropTypes.func,
  setEditSubscription: PropTypes.func,
  showSubscriptionModal: PropTypes.bool,
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showDeactivateModal: PropTypes.func,
  deactivateSubscriptionById: PropTypes.func,
  reactivateSubscriptionById: PropTypes.func,
  setSubscriptionForDuplicate: PropTypes.func,
  exportSubscriptionList: PropTypes.func,
};

const mapStateToProps = ({ groups, subscriptions, users }) => ({
  subscriptionList: getResults(subscriptions.searchSubscriptionList),
  isLoading: getIsFetching(subscriptions.searchSubscriptionList),
  filters: subscriptions.filters,
  total: subscriptions.total,
  groupId: groups.selectedGroupId,
  showSubscriptionModal: subscriptions.showSubscriptionModal,
  userInformation: getResults(users.userInformation),
});

const mapDispatchToProps = dispatch => ({
  setFilters: filters => dispatch(setSubscriptionFilters(filters)),
  getSubscriptions: filters => dispatch(getSearchSubscriptionList(filters)),
  setEditSubscription: (id, show) => {
    dispatch(setEditSubscriptionId(id));
    dispatch(setShowSubscriptionModal(show));
  },
  setSubscriptionForDuplicate: ({ id, ...rest }) => dispatch(setSubscription(rest)),
  showDeactivateModal: (type, props) => dispatch(showModal(type, props)),
  deactivateSubscriptionById: id => dispatch(deactivateSubscription(id)),
  reactivateSubscriptionById: id => dispatch(reactivateSubscription(id)),
  exportSubscriptionList: () => dispatch(exportSubscriptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
