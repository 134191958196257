import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import toSafeInteger from 'lodash/toSafeInteger';
import get from 'lodash/get';
import React, { useState, useRef, useEffect } from 'react';
import isPlainObject from 'react-redux/lib/utils/isPlainObject';
import PropTypes from 'prop-types';
import CountryHelper from './CountryHelper';

let averageMaxBadges = 50;
const calculateMaxBadgesPerLine = (width = 1) => toSafeInteger(width / averageMaxBadges);

export const createBadgeList = (array, key, emptyLabel, ppcArray = [], count, setCount, ref) => {
  if (isEmpty(array) && isEmpty(ppcArray)) return <div className='ui-td__no-val'>{emptyLabel}</div>;
  const fullList = array.concat(ppcArray);
  const showDots = fullList.length > count;
  const expandBadges = () => {
    averageMaxBadges = count > averageMaxBadges ? 50 : 5;
    setCount(calculateMaxBadgesPerLine(ref.current.getBoundingClientRect().width));
  };

  return (
    <div className='d-flex flex-wrap align-content-start'>
      {fullList.slice(0, count).map(v => {
        const isPpcBadge = ppcArray.includes(v);
        const valueLic = new Array(get(v, key, 'N/A'));
        return !isPlainObject(v) ? (
          <div className={cx('ui-badge', { ppc: isPpcBadge })} key={v.id || v}>
            <CountryHelper showFlag target={v} />
          </div>
        ) : (
          <span key={'license'.concat(valueLic[0])} className='license'>
            {valueLic.map(lic => lic)}
          </span>
        );
      })}
      {showDots && (
        <div
          tabIndex='0'
          role='textbox'
          className='ui-badge dots'
          key='expand-key'
          onClick={() => expandBadges()}
        >
          ...
        </div>
      )}
    </div>
  );
};

export const BadgeListWithDots = ({ array, ppcArray = [] }) => {
  const [count, setCount] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current || !ref.current.getBoundingClientRect().width) return;
    setCount(calculateMaxBadgesPerLine(ref.current.getBoundingClientRect().width));
  }, [ref.current]);
  return (
    <div ref={ref} className='w-100'>
      {createBadgeList(array, '', 'N/A', ppcArray, count, setCount, ref)}
    </div>
  );
};

BadgeListWithDots.propTypes = {
  array: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
  ppcArray: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
};
