import React, { useState, useEffect, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import dayjs from 'dayjs';
import get from 'lodash/get';
import UIModal from '../common/components/UIModal';
import { getIsFetching, getResults } from '../redux/helpers/createList';
import { getResource } from '../redux/helpers/createResource';
import UICustomLoader from '../common/components/UICustomLoader';
import UIButton from '../common/components/UIButton';
import UIIcon from '../common/components/UIIcon';
import {
  createUser as _createUser,
  getUserById as _getUserById,
  resetUser as _resetUser,
  setEditUserId as _setEditUserId,
  setShowUserModal as _setShowUserModal,
  updateUser as _updateUser,
  getAMTRoles as _getAMTRoles,
  getInAppRoles as _getInAppRoles,
  getUserInformation as _getUserInformation,
} from '../redux/actions/users';
import { getAllProfileList as _getAllProfileList } from '../redux/actions/profiles';
import UISelect from '../common/components/UISelect';
import { formatDate, parseDateToDateObject } from '../common/helpers/date';
import UIDatePicker from '../common/components/UIDatePicker';
import { getGroupById as _getGroupById } from '../redux/actions/groups';
import UIIconButton from '../common/components/UIIconButton';
import { hasRole, Roles } from '../common/helpers/roles';
import { differenceWith, isEqual } from 'lodash';

export class DatePickerInput extends Component {
  render = () => (
    <div className='ui-date-input'>
      <input className='ui-input' {...this.props} placeholder='Choose date' />
      <UIIcon icon='calendar' iconColor='var(--gunmetal)' iconSize='1.1rem' />
    </div>
  );
}

const defaultUserModel = {
  username: null,
  profile: null,
  fullName: null,
  email: null,
  startDate: formatDate(dayjs(), 'YYYY-MM-DD'),
  endDate: null,
  amtUserRoles: [],
  inAppUserRoles: [],
};

const UserFormModal = ({
  editId,
  editUser,
  isLoading,
  profileList,
  createUser,
  getUserById,
  resetUser,
  setEditUserId,
  setShowUserModal,
  updateUser,
  getAllProfileList,
  groupId,
  getGroupById,
  amtRolesList,
  inAppRolesList,
  userInformation,
  getAMTRoles,
  getInAppRoles,
  getUserInformation,
}) => {
  const [userState, setUserState] = useState(defaultUserModel);
  const [errorState, setErrorState] = useState({});
  const [initialAMTRoles, setInitialAMTRoles] = useState(undefined);

  const errorsHandler = errors => {
    if (errors) {
      const { fieldErrors } = errors.data;
      fieldErrors.forEach(err => {
        setErrorState(prevState => ({
          ...prevState,
          [err.field]: err.message,
        }));
      });
    }
  };

  const onInit = userId => {
    getAllProfileList(groupId);
    getGroupById(groupId);
    getAMTRoles();
    getInAppRoles();
    getUserInformation();
    if (userId) {
      getUserById(userId);
    }
  };

  const onReset = e => {
    e.preventDefault();
    resetUser();
    setEditUserId();
    setShowUserModal();
  };

  const onSave = (e, user) => {
    e.preventDefault();
    if (user.id) {
      updateUser(user).catch(error => {
        errorsHandler(error);
      });
    } else {
      createUser(user).catch(error => {
        errorsHandler(error);
      });
    }
  };

  useEffect(() => {
    if (editUser) {
      setUserState(editUser);
    }
  }, [editUser]);

  useEffect(() => {
    onInit(editId);
  }, []);

  const handleChange = (key, value) => {
    setUserState({ ...userState, [key]: value });
    setErrorState({ ...errorState, [key]: undefined });
  };

  const clearEndDate = e => {
    e.preventDefault();
    handleChange('endDate', ' ');
  };

  const hasAMTtRoleManagementRole = () => {
    return hasRole(Roles.amtManagement, userInformation?.amtRoles);
  };

  const showWarningAmtRolesChanged = newRoles => {
    if (userInformation.username !== userState.username) {
      return false;
    }
    if (initialAMTRoles === undefined) {
      setInitialAMTRoles(amtUserRoles);
    }
    const newArray = differenceWith(newRoles, initialAMTRoles, isEqual);
    const newArrayReversed = differenceWith(initialAMTRoles, newRoles, isEqual);
    return newArray.length !== 0 || newArrayReversed.length !== 0;
  };

  const {
    username,
    profile,
    fullName,
    email,
    startDate,
    endDate,
    amtUserRoles,
    inAppUserRoles,
  } = userState;
  const isEdit = !!editId;
  const getPayload = () => {
    return {
      id: userState.id,
      username: userState.username,
      fullName: userState.fullName,
      email: userState.email,
      startDate: userState.startDate,
      endDate: userState.endDate,
      amtUserRoles: userState.amtUserRoles,
      inAppUserRoles: userState.inAppUserRoles,
      profileId: userState.profile?.id,
    };
  };
  return (
    <UIModal
      isOpen
      toggle={onReset}
      title={`${isEdit ? 'Edit' : 'Add'} user`}
      className='ui-edit-modal'
    >
      {isLoading ? (
        <UICustomLoader loading={isLoading} />
      ) : (
        <Form onSubmit={e => onSave(e, getPayload())} onReset={onReset}>
          <Row form>
            <Col md={4}>
              <FormGroup>
                <Label className='body body--bold mb-2'>Name</Label>
                <Input
                  name='name'
                  value={fullName || ''}
                  placeholder='Full name'
                  className={errorState.fullName ? 'ui-input error' : 'ui-input'}
                  onChange={({ target: { value } }) => handleChange('fullName', value)}
                />
                <Label className='errorMessage'>{errorState.fullName}</Label>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label className='body body--bold mb-2'>inApp login name</Label>
                <Input
                  readOnly={isEdit}
                  name='username'
                  value={username || ''}
                  placeholder='inApp login name'
                  className={errorState.username ? 'ui-input error' : 'ui-input'}
                  onChange={({ target: { value } }) => handleChange('username', value)}
                />
                <Label className='errorMessage'>{errorState.username}</Label>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label className='body body--bold mb-2'>Address e-mail</Label>
            <Input
              name='email'
              value={email || ''}
              placeholder='User email'
              className={errorState.email ? 'ui-input error' : 'ui-input'}
              type='email'
              onChange={({ target: { value } }) => handleChange('email', value)}
            />
            <Label className='errorMessage'>{errorState.email}</Label>
          </FormGroup>
          <FormGroup>
            <Label className='body body--bold mb-2'>Profile</Label>
            <UISelect
              isSearchable
              getOptionLabel={({ name: profileName }) => profileName}
              getOptionValue={({ id }) => id}
              placeholder='Select profile'
              value={profileList.find(({ id }) => id === get(profile, 'id', null))}
              onChange={val => handleChange('profile', val)}
              options={profileList}
              hasError={!!errorState.profileId}
            />
            <Label className='errorMessage'>{errorState.profileId}</Label>
          </FormGroup>
          {hasAMTtRoleManagementRole() && (
            <FormGroup className='mb-5'>
              <Label className='body body--bold mb-2'>AMT Roles</Label>
              <UISelect
                isMulti
                isSearchable
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                placeholder='Add InApp Roles to this User'
                value={amtUserRoles}
                onChange={val => {
                  handleChange('amtUserRoles', val || []);
                }}
                options={amtRolesList}
              />
              {showWarningAmtRolesChanged(amtUserRoles) && (
                <Label className='errorMessage body--bold'>
                  Note: The user must logoff and then logon again for the change take effect.
                </Label>
              )}
            </FormGroup>
          )}
          <FormGroup className='mb-5'>
            <Label className='body body--bold mb-2'>InApp Roles</Label>
            <UISelect
              isMulti
              isSearchable
              isDisabled={!hasRole(Roles.amtClientSetup, userInformation?.amtRoles)}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              placeholder='Add InApp Roles to this User'
              value={inAppUserRoles}
              onChange={val => handleChange('inAppUserRoles', val || [])}
              options={inAppRolesList}
            />
          </FormGroup>
          <Row form className='m-b-32'>
            <Col md={4}>
              <FormGroup>
                <Label className='body body--bold mb-2'>Start date</Label>
                <UIDatePicker
                  value={startDate || ''}
                  selectedDays={parseDateToDateObject(startDate)}
                  setDate={date => handleChange('startDate', formatDate(date, 'YYYY-MM-DD'))}
                  component={DatePickerInput}
                  withInput
                  withClass={errorState.startDate ? 'ui-datepicker error' : 'ui-datepicker'}
                  inputProps={{ required: true }}
                />
                <Label className='errorMessage'>{errorState.startDate}</Label>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label className='body body--bold mb-2'>
                  End date{' '}
                  <span className='optional-text'>
                    (optional)
                    <span
                      role='button'
                      tabIndex={0}
                      className='clearDate'
                      onClick={e => clearEndDate(e)}
                    >
                      <UIIconButton
                        icon='removeDate'
                        iconColor='var(--gunmetal)'
                        iconSize='1.1rem'
                      />
                    </span>
                  </span>
                </Label>
                <UIDatePicker
                  value={endDate || ''}
                  selectedDays={parseDateToDateObject(endDate)}
                  setDate={date => handleChange('endDate', formatDate(date, 'YYYY-MM-DD'))}
                  component={DatePickerInput}
                  withInput
                  withClass={errorState.endDate ? 'ui-datepicker error' : 'ui-datepicker'}
                  inputProps={{ disabled: !startDate }}
                  disabledDays={{ before: parseDateToDateObject(startDate) }}
                />
                <Label className='errorMessage'>{errorState.endDate}</Label>
              </FormGroup>
            </Col>
          </Row>
          <div className='d-flex align-items-center'>
            <UIButton className='w-100' height={50} type='submit'>
              Save
            </UIButton>
          </div>
        </Form>
      )}
    </UIModal>
  );
};

UserFormModal.propTypes = {
  editId: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  editUser: PropTypes.oneOfType([PropTypes.object]),
  isLoading: PropTypes.bool,
  profileList: PropTypes.arrayOf(PropTypes.object),
  createUser: PropTypes.func,
  getUserById: PropTypes.func,
  resetUser: PropTypes.func,
  setEditUserId: PropTypes.func,
  setShowUserModal: PropTypes.func,
  updateUser: PropTypes.func,
  getAllProfileList: PropTypes.func,
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getGroupById: PropTypes.func,
  amtRolesList: PropTypes.arrayOf(PropTypes.object),
  inAppRolesList: PropTypes.arrayOf(PropTypes.object),
  userInformation: PropTypes.object,
  getAMTRoles: PropTypes.func,
  getInAppRoles: PropTypes.func,
  getUserInformation: PropTypes.func,
};

const mapStateToProps = ({ profiles, users, groups }) => ({
  profileList: getResults(profiles.profileList),
  editUser: getResource(users.user),
  editId: users.editUserId,
  isLoading: getIsFetching(users.user),
  editModal: users.editModal,
  selectedGroup: getResource(groups.group),
  groupId: groups.selectedGroupId,
  amtRolesList: getResults(users.amtRolesList),
  inAppRolesList: getResults(users.inAppRolesList),
  userInformation: getResults(users.userInformation),
});

const mapDispatchToProps = dispatch => ({
  getAllProfileList: groupId => dispatch(_getAllProfileList(groupId)),
  getUserById: userId => dispatch(_getUserById(userId)),
  resetUser: () => dispatch(_resetUser()),
  setEditUserId: () => dispatch(_setEditUserId()),
  setShowUserModal: () => dispatch(_setShowUserModal()),
  updateUser: user => dispatch(_updateUser(user)),
  createUser: user => dispatch(_createUser(user)),
  getGroupById: groupId => dispatch(_getGroupById(groupId)),
  getAMTRoles: () => dispatch(_getAMTRoles()),
  getInAppRoles: () => dispatch(_getInAppRoles()),
  getUserInformation: () => dispatch(_getUserInformation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserFormModal);
