import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import cx from 'classnames';

import UICustomLoader from '../UICustomLoader';

const Table = ({ className, ...props }) => {
  return (
    <ReactTable
      className={cx('ui-table', className)}
      sortable={false}
      resizable={false}
      showPagination={false}
      LoadingComponent={UICustomLoader}
      getTheadProps={() => ({
        className: 'ui-thead',
      })}
      getTheadThProps={() => ({
        className: 'ui-thead-th',
      })}
      getTrGroupProps={() => ({
        className: 'ui-trgroup',
      })}
      getTdProps={() => ({
        className: 'ui-td',
      })}
      {...props}
    />
  );
};

Table.propTypes = {
  className: PropTypes.string,
};

export default Table;
