import { combineReducers } from 'redux';

import * as types from '../types/profiles';
import createList from '../helpers/createList';
import createReducer from '../helpers/createReducer';
import createResource from '../helpers/createResource';

export const profileFilterDefaultState = {
  pagination: {
    page: 0,
    size: 10,
    onlyActive: true,
  },
  sort: {},
  filter: {},
};

const profileListActions = {
  RESET: types.PROFILES_LIST_RESET,
  REQUEST: types.PROFILES_LIST_REQUEST,
  SUCCESS: types.PROFILES_LIST_SUCCESS,
  FAILURE: types.PROFILES_LIST_FAILURE,
  REMOVE: types.PROFILES_LIST_REMOVE,
  UPDATE: types.PROFILES_LIST_UPDATE,
};

const searchProfileListActions = {
  RESET: types.SEARCH_PROFILES_LIST_RESET,
  REQUEST: types.SEARCH_PROFILES_LIST_REQUEST,
  SUCCESS: types.SEARCH_PROFILES_LIST_SUCCESS,
  FAILURE: types.SEARCH_PROFILES_LIST_FAILURE,
  REMOVE: types.SEARCH_PROFILES_LIST_REMOVE,
  UPDATE: types.SEARCH_PROFILES_LIST_UPDATE,
};

const profileActions = {
  RESET: types.PROFILE_RESET,
  REQUEST: types.PROFILE_REQUEST,
  SUCCESS: types.PROFILE_SUCCESS,
};

const dataProviderAccessesActions = {
  SUCCESS: types.DATA_PROVIDER_ACCESSES_SUCCESS,
  FAILURE: types.DATA_PROVIDER_ACCESSES_FAILURE,
};

export default combineReducers({
  profileList: createList(profileListActions),
  searchProfileList: createList(searchProfileListActions),
  total: createReducer(0, {
    [types.SEARCH_PROFILES_TOTAL_RECEIVE]: (state, action) => action.total || 0,
  }),
  filters: createReducer(profileFilterDefaultState, {
    [types.SET_PROFILES_FILTERS]: (state, action) => action.filters || profileFilterDefaultState,
  }),
  profile: createResource(profileActions),
  showProfileModal: createReducer(null, {
    [types.SET_SHOW_PROFILE_MODAL]: (state, action) => action.show || null,
  }),
  editProfileId: createReducer(null, {
    [types.SET_EDIT_PROFILE_ID]: (state, action) => action.id || null,
  }),
  dataProviderAccessesList: createList(dataProviderAccessesActions),
});
