import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import cx from 'classnames';
import unionBy from 'lodash/unionBy';
import dotProp from 'dot-prop-immutable';
import UIModal from '../common/components/UIModal';
import { getIsFetching, getResults } from '../redux/helpers/createList';
import { getResource } from '../redux/helpers/createResource';
import UICustomLoader from '../common/components/UICustomLoader';
import UIButton from '../common/components/UIButton';
import UISelect from '../common/components/UISelect';
import {
  createProfile as _createProfile,
  getDataProviderAccesses as _getDataProviderAccesses,
  getProfileById as _getProfileById,
  resetProfile as _resetProfile,
  setEditProfileId as _setEditProfileId,
  setShowProfileModal as _setShowProfileModal,
  updateProfile as _updateProfile,
} from '../redux/actions/profiles';
import { getProfilesApplications as _getProfilesApplications } from '../redux/actions/groups';
import { getAllEntityList as _getAllEntityList } from '../redux/actions/entities';
import UIIcon from '../common/components/UIIcon';
import DocTypeTab from './DocTypeTab';
import {
  getCountryList as _getCountryList,
  getGroupSubscribedCountryList as _getGroupSubscribedCountryList,
} from '../redux/actions/common';
import { getTechnicalRoles as _getTechnicalRoles } from '../redux/actions/users';
import { ApplicationsAccessIds } from '../utils/general';
import UICheckbox from '../common/components/UICheckbox';
import { getAllSubscriptionList as _getAllSubscriptionList } from '../redux/actions/subscription';
import { hasRole, Roles } from '../common/helpers/roles';

const DividerLine = () => <hr className='divider-line' />;
const DividerLineFull = () => <hr className='divider-line fullWidth' />;

const defaultProfileModel = {
  name: null,
  entities: [],
  subscriptions: [],
  profileAccesses: [],
  inRulesVersionId: null,
  eam: false,
  apiAccess: [],
  applicationAccess: null,
  dataProviderAccesses: [],
};

const accessModel = {
  docTypeId: null,
  sourceCountries: [],
  targetCountries: [],
};

const getDomicileCountryFromEntities = (array, countryList) =>
  unionBy(
    array.map(({ domicileCountryId }) => {
      return {
        id: domicileCountryId,
        isoCode: domicileCountryId,
        name: countryList.filter(c => c.isoCode === domicileCountryId)[0].name,
      };
    }),
    'id',
  );

const filterSourceCountries = (source, access) => ({
  ...access,
  sourceCountries: access.sourceCountries.filter(country =>
    source.some(({ domicileCountryId }) => domicileCountryId === country),
  ),
});

const ProfileFormModal = ({
  editId,
  editProfile,
  entityList,
  subscriptionList,
  countryList,
  groupSubscribedCountryList,
  docTypeList,
  isLoading,
  getAllEntityList,
  getCountryList,
  getProfileById,
  getGroupSubscribedCountryList,
  resetProfile,
  setEditProfileId,
  setShowProfileModal,
  updateProfile,
  createProfile,
  selectedGroup,
  getTechnicalRoles,
  technicalRolesList,
  getProfilesApplications,
  applications,
  getAllSubscriptionList,
  getDataProviderAccesses,
  dataProviderAccessesList,
  userInformation,
}) => {
  const [profileState, setProfileState] = useState(defaultProfileModel);
  const [selectedDocType, setSelectedDocType] = useState(null);
  const [errorState, setErrorState] = useState({});

  const errorsHandler = errors => {
    if (errors) {
      const { fieldErrors } = errors.data;
      fieldErrors.forEach(err => {
        setErrorState(prevState => ({
          ...prevState,
          [err.field]: err.message,
        }));
      });
    }
  };

  const onInit = profileId => {
    getAllEntityList();
    getCountryList();
    getTechnicalRoles();
    getAllSubscriptionList();
    getDataProviderAccesses();
    if (selectedGroup) {
      getProfilesApplications(selectedGroup.id);
    }
    if (profileId) {
      getProfileById(profileId);
    }
  };

  const onDocTypeSelect = docTypeId => {
    getGroupSubscribedCountryList(docTypeId);
  };

  const onReset = e => {
    e.preventDefault();
    resetProfile();
    setEditProfileId();
    setShowProfileModal();
  };

  const onSave = (e, profile) => {
    e.preventDefault();
    delete profile.active;
    delete profile.entities;
    delete profile.subscriptions;

    if (profile.id) {
      updateProfile(profile).catch(error => {
        errorsHandler(error);
      });
    } else {
      createProfile(profile).catch(error => {
        errorsHandler(error);
      });
    }
  };

  useEffect(() => {
    if (editProfile) {
      setProfileState(editProfile);
    }
  }, [editProfile]);

  useEffect(() => {
    onInit(editId);
  }, []);

  const handleChange = (key, value) => {
    setProfileState(dotProp.set(profileState, key, value));
    setErrorState({ ...errorState, [key]: undefined });
  };

  const toggleSelectDoc = (id, index) => {
    if (index !== -1) {
      setProfileState(dotProp.delete(profileState, `profileAccesses.${index}`));
      setSelectedDocType(null);
    } else {
      onDocTypeSelect(id);
      setProfileState(
        dotProp.merge(profileState, 'profileAccesses', { ...accessModel, docTypeId: id }),
      );
      setSelectedDocType(id);
    }
  };

  const {
    name,
    description,
    entities,
    profileAccesses,
    eam,
    apiAccess,
    applicationAccess,
    dataProviderAccesses,
  } = profileState;

  const isEdit = !!editId;
  const domicileCountries = useMemo(() => getDomicileCountryFromEntities(entities, countryList), [
    entities,
  ]);
  const handleEntitiesChange = val => {
    // Remove already selected source countries for each profile access after removing entity
    // (coz list for sourceCountry select depends on selected entity)
    const newProfileAccesses = profileState.profileAccesses.map(access =>
      filterSourceCountries(val, access),
    );
    setProfileState({ ...profileState, entities: val, profileAccesses: newProfileAccesses });
  };

  const addAllRoles = roles => {
    setProfileState(dotProp.set(profileState, 'apiAccess', [...apiAccess, ...roles]));
  };

  const hasInAppAccess = () => {
    return (
      applicationAccess &&
      (applicationAccess.id === ApplicationsAccessIds.light ||
        applicationAccess.id === ApplicationsAccessIds.premium ||
        applicationAccess.id === ApplicationsAccessIds.pamis ||
        applicationAccess.id === ApplicationsAccessIds.samis)
    );
  };

  const getApplicationAccess = () => {
    return applicationAccess && applicationAccess.id;
  };

  const hasDataProvider = () => {
    return selectedGroup.dataProviders.length > 0;
  };
  const showDocType = docTypeId => {
    if (getApplicationAccess() === ApplicationsAccessIds.samis) {
      return eam ? docTypeId === 'CM EAM BT' : docTypeId === 'CM PB BT';
    }
    return (
      getApplicationAccess() === ApplicationsAccessIds.api ||
      (eam && docTypeId !== 'CM PB BT') ||
      (!eam && docTypeId !== 'CM EAM BT')
    );
  };
  return (
    <UIModal
      isOpen
      toggle={onReset}
      title={`${isEdit ? 'Edit' : 'Add'} profile`}
      className='ui-edit-modal profile-modal'
    >
      {isLoading ? (
        <UICustomLoader loading={isLoading} />
      ) : (
        <Form
          onSubmit={e =>
            onSave(e, { ...profileState, entitiesIds: profileState.entities.map(({ id }) => id) })
          }
        >
          <FormGroup>
            <Label className='body body--bold mb-2'>Name</Label>
            <Input
              name='name'
              value={name || ''}
              placeholder='Name'
              className={errorState.name ? 'ui-input error' : 'ui-input'}
              onChange={({ target: { value } }) => handleChange('name', value)}
            />
            <Label className='errorMessage'>{errorState.name}</Label>
          </FormGroup>
          <FormGroup className='m-b-5'>
            <Label className='body body--bold mb-2'>Entities</Label>
            <UISelect
              isMulti
              isSearchable
              getOptionLabel={({ name: entityName }) => entityName}
              getOptionValue={({ id }) => id}
              placeholder='Select inApp target/s'
              value={entities}
              onChange={val => handleEntitiesChange(val || [])}
              options={entityList}
              hasError={!!errorState.entitiesIds}
            />
            <Label className='errorMessage'>{errorState.entitiesIds}</Label>
          </FormGroup>
          <div>
            <Label className='body body--bold mb-2'>Has access to</Label>
            <div className='mb-3'>
              <UISelect
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                onChange={val => handleChange('applicationAccess', val || null)}
                value={applicationAccess}
                options={applications}
              />
            </div>
            {getApplicationAccess() === ApplicationsAccessIds.api && (
              <FormGroup>
                <div className='mb-2'>
                  <span className='body body--bold mt-2 mb-1'>Roles </span>
                  <span
                    role='button'
                    tabIndex={0}
                    className='selectAll'
                    onClick={() => addAllRoles(technicalRolesList)}
                  >
                    - Select All
                  </span>
                </div>
                <UISelect
                  isMulti
                  isSearchable
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ code }) => code}
                  placeholder='Select the role/s'
                  value={technicalRolesList.filter(
                    ({ code }) => apiAccess.map(access => access.code).indexOf(code) !== -1,
                  )}
                  onChange={val => handleChange('apiAccess', val || [])}
                  options={technicalRolesList}
                />
              </FormGroup>
            )}
            {hasInAppAccess() && (
              <div>
                <FormGroup>
                  <UICheckbox
                    checked={eam}
                    onChange={({ target: { checked } }) => handleChange('eam', checked)}
                    label='EAM'
                  />
                </FormGroup>
              </div>
            )}
          </div>
          {hasRole(Roles.amtClientSetup, userInformation?.amtRoles) && hasDataProvider() && (
            <div>
              <Label className='body body--bold mb-2'>Product Access</Label>
              <div className='mb-3'>
                <UISelect
                  isMulti
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ code }) => code}
                  onChange={val => handleChange('dataProviderAccesses', val || null)}
                  value={dataProviderAccesses}
                  options={dataProviderAccessesList}
                  hasError={!!errorState.dataProviderAccesses}
                />
              </div>
            </div>
          )}
          <Label className='errorMessage'>{errorState.dataProviderAccesses}</Label>
          <DividerLineFull />
          <FormGroup className='m-b-32'>
            <Label className='body body--bold mb-2'>Document type</Label>
            <div className='doc-type-tabs'>
              {docTypeList.map(({ id }) => {
                const isActive = id === selectedDocType;
                const isAddedIndex = profileState.profileAccesses.findIndex(
                  ({ docTypeId }) => docTypeId === id,
                );
                return (
                  showDocType(id) && (
                    <div className='doc-tab' key={`tab-${id}`}>
                      <UIButton
                        btnType={!isActive ? 'outline' : ''}
                        className={cx({ active: isActive })}
                        height={50}
                        onClick={e => {
                          e.preventDefault();
                          setSelectedDocType(id);
                          onDocTypeSelect(id);
                        }}
                      >
                        {id}
                      </UIButton>
                      <UIButton
                        btnType={!isActive ? 'outline' : ''}
                        className={cx('tab-toggle', { active: isActive })}
                        height={50}
                        onClick={e => {
                          e.preventDefault();
                          toggleSelectDoc(id, isAddedIndex);
                        }}
                      >
                        <UIIcon
                          icon={isAddedIndex !== -1 ? 'minusCircle' : 'plusCircle'}
                          iconColor={isActive ? 'var(--white)' : 'var(--light-grey-blue)'}
                        />
                      </UIButton>
                    </div>
                  )
                );
              })}
            </div>
          </FormGroup>
          <DividerLine />
          {profileAccesses.map((access, accessIndex) => {
            return access.docTypeId === selectedDocType ? (
              <DocTypeTab
                key={access.docTypeId}
                access={access}
                accessIndex={accessIndex}
                countryList={countryList}
                targetCountryList={groupSubscribedCountryList}
                sourceCountryList={domicileCountries}
                subscriptionList={subscriptionList}
                onChange={handleChange}
              />
            ) : null;
          })}
          <DividerLineFull />
          <FormGroup className='m-b-32'>
            <Label className='body body--bold mb-2'>Comment</Label>
            <Input
              name='description'
              type='textarea'
              rows='10'
              value={description || ''}
              placeholder='Add comment'
              className={errorState.description ? 'ui-input textarea error' : 'ui-input textarea'}
              onChange={({ target: { value } }) => {
                handleChange('description', value);
              }}
            />
            <Label className='errorMessage'>{errorState.description}</Label>
          </FormGroup>
          <div className='d-flex align-items-center mt-3'>
            <UIButton name='save' className='w-100' height={50}>
              Save
            </UIButton>
          </div>
        </Form>
      )}
    </UIModal>
  );
};

ProfileFormModal.propTypes = {
  editProfile: PropTypes.oneOfType([PropTypes.object]),
  editId: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  isLoading: PropTypes.bool,
  entityList: PropTypes.arrayOf(PropTypes.object),
  subscriptionList: PropTypes.arrayOf(PropTypes.object),
  docTypeList: PropTypes.arrayOf(PropTypes.object),
  countryList: PropTypes.arrayOf(PropTypes.object),
  groupSubscribedCountryList: PropTypes.arrayOf(PropTypes.object),
  getAllEntityList: PropTypes.func,
  getCountryList: PropTypes.func,
  getProfileById: PropTypes.func,
  getGroupSubscribedCountryList: PropTypes.func,
  resetProfile: PropTypes.func,
  setEditProfileId: PropTypes.func,
  setShowProfileModal: PropTypes.func,
  updateProfile: PropTypes.func,
  createProfile: PropTypes.func,
  selectedGroup: PropTypes.oneOfType([PropTypes.object]),
  getTechnicalRoles: PropTypes.func,
  technicalRolesList: PropTypes.arrayOf(PropTypes.object),
  getProfilesApplications: PropTypes.func,
  applications: PropTypes.arrayOf(PropTypes.object),
  getAllSubscriptionList: PropTypes.func,
};

const mapStateToProps = ({ groups, common, entities, profiles, users, subscriptions }) => ({
  entityList: getResults(entities.entityList),
  countryList: getResults(common.countryList),
  subscriptionList: getResults(subscriptions.subscriptionList),
  groupSubscribedCountryList: getResults(common.groupSubscribedCountryList),
  docTypeList: getResults(common.docTypeList),
  editProfile: getResource(profiles.profile),
  editId: profiles.editProfileId,
  isLoading: getIsFetching(profiles.profile),
  editModal: profiles.editModal,
  technicalRolesList: getResults(users.technicalRolesList),
  applications: getResults(groups.profileApplications),
  dataProviderAccessesList: getResults(profiles.dataProviderAccessesList),
  userInformation: getResults(users.userInformation),
});

const mapDispatchToProps = dispatch => ({
  getAllEntityList: () => dispatch(_getAllEntityList()),
  getCountryList: () => dispatch(_getCountryList()),
  getProfileById: profileId => dispatch(_getProfileById(profileId)),
  getGroupSubscribedCountryList: docTypeId => dispatch(_getGroupSubscribedCountryList(docTypeId)),
  resetProfile: () => dispatch(_resetProfile()),
  setEditProfileId: () => dispatch(_setEditProfileId()),
  setShowProfileModal: () => dispatch(_setShowProfileModal()),
  updateProfile: profile => dispatch(_updateProfile(profile)),
  createProfile: profile => dispatch(_createProfile(profile)),
  getTechnicalRoles: () => dispatch(_getTechnicalRoles()),
  getProfilesApplications: groupId => dispatch(_getProfilesApplications(groupId)),
  getAllSubscriptionList: () => dispatch(_getAllSubscriptionList()),
  getDataProviderAccesses: () => dispatch(_getDataProviderAccesses()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileFormModal);
