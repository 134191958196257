import { combineReducers } from 'redux';
import * as types from '../types/auth';
import createReducer from '../helpers/createReducer';
import { getAuthTokens } from '../actions/auth';

const getInitialState = () => {
  const { token, user } = getAuthTokens();
  return {
    auth: token || null,
    isLoading: false,
    error: false,
    user: user || null,
  };
};

export default combineReducers({
  auth: createReducer(getInitialState().auth, {
    [types.AUTH_RECEIVE]: (state, action) => action.auth || null,
  }),
  isLoading: createReducer(getInitialState().isLoading, {
    [types.AUTH_REQUEST]: (state, action) => action.isLoading || false,
  }),
  error: createReducer(getInitialState().error, {
    [types.AUTH_ERROR]: (state, action) => action.error || false,
  }),
  user: createReducer(getInitialState().user, {
    [types.AUTH_USER_RECEIVE]: (state, action) => action.user || null,
  }),
});
