import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import { getCountryList } from '../../../redux/actions/common';
import { getResults } from '../../../redux/helpers/createList';
import FlagIcon from '../UIFlag';

class CountryHelper extends React.Component {
  getCountryName() {
    const [resultName] = this.props.countryList.filter(c => c.isoCode === this.props.target);
    return resultName && resultName.name;
  }
  showCountryName() {
    return <span>{this.getCountryName()}</span>;
  }

  showFlag() {
    return (
      <span title={this.getCountryName()}>
        <FlagIcon value={this.props.target} />
      </span>
    );
  }
  render() {
    return this.props.showFlag ? this.showFlag() : this.showCountryName();
  }
}

const mapDispatchToProps = dispatch => ({
  onInit: () => {
    dispatch(getCountryList());
  },
});

const mapStateToProps = ({ common }) => ({
  countryList: getResults(common.countryList),
});

CountryHelper.propTypes = {
  showFlag: PropTypes.bool,
  target: PropTypes.string,
  countryList: PropTypes.arrayOf(PropTypes.object),
  isValidSubscriptionSource: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(CountryHelper);
