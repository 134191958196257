import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import get from 'lodash/get';
import UIModal from '../common/components/UIModal';
import { getIsFetching, getResults } from '../redux/helpers/createList';
import {
  createGroup as _createGroup,
  updateGroup as _updateGroup,
  getGroupById as _getGroupById,
  resetGroup as _resetGroup,
  setEditGroupId as _setEditGroupId,
  getApplicationAccess as _getApplicationAccess,
  getAdditionalLanguages as _getAdditionalLanguages,
  getExternalDataProviders as _getExternalDataProviders,
  getESGProviders as _getESGProviders,
  getInvestorSuitabilityProviders as _getInvestorSuitabilityProviders,
  getInvestorSuitabilityKnowledge as _getInvestorSuitabilityKnowledge,
  getIconColors as _getIconColors,
  getSanctionSecuritiesSources as _getSanctionSecuritiesSources,
  getFundInfoFeeds as _getFundInfoFeeds,
} from '../redux/actions/groups';
import {
  getInruleList as _getInruleList,
  hideModal as _hideModal,
  showModal as _showModal,
} from '../redux/actions/common';
import { getResource } from '../redux/helpers/createResource';
import UICustomLoader from '../common/components/UICustomLoader';
import UIButton from '../common/components/UIButton';
import UICheckbox from '../common/components/UICheckbox';
import UISelect from '../common/components/UISelect';
import UIIcon from '../common/components/UIIcon';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as NAIcon } from './../assets/icons/na-icon.svg';
import { SketchPicker } from 'react-color';
import cloneDeep from 'lodash/cloneDeep';
import { isEqual } from 'lodash';
import { getAllProfileList as _getAllProfileList } from '../redux/actions/profiles';
import { createObjectForUISelect, stringifyFields } from '../utils/general';

const FUNDINFO_CODE = 'FUND_INFO';
const defaultGroupModel = {
  active: true,
  admin: false,
  contactPerson: null,
  description: '',
  inRulesVersionId: 1,
  name: '',
  applications: [],
  additionalLanguages: [],
  dataProviders: null,
  esgProviders: null,
  sanctionSecuritiesSource: null,
  fundInfoFeed: null,
};

const defaultInvestorSuitability = {
  partner: null,
  defaultRegulation: null,
  editable: false,
  basic: [],
  informed: [],
  advanced: [],
};

const GroupFormModal = ({
  editId,
  editGroup,
  isLoading,
  inRulesList,
  getInruleList,
  getGroupById,
  resetGroup,
  setEditGroupId,
  hideModal,
  updateGroup,
  createGroup,
  getApplicationAccess,
  getAdditionalLanguages,
  applicationAccessList,
  additionalLanguagesList,
  externalDataProviders,
  esgProviders,
  sanctionSecuritiesSources,
  investorSuitabilityProviders,
  getExternalDataProviders,
  getESGProviders,
  getSanctionSecuritiesSources,
  fundInfoFeeds,
  getFundInfoFeeds,
  getInvestorSuitabilityProviders,
  getInvestorSuitabilityKnowledge,
  investorSuitabilityKnowledge,
  getIconColors,
  defaultIconColors,
  getAllProfileList,
  profileList,
  showModal,
}) => {
  const [groupState, setGroupState] = useState(defaultGroupModel);
  const { investorSuitabilityProvider } = groupState;
  const [investorSuitabilityState, setInvestorSuitabilityState] = useState(
    defaultInvestorSuitability,
  );
  const [errorState, setErrorState] = useState({});
  const [showPickerCode, setShowPickerCode] = useState(null);
  const [customColors, setCustomColors] = useState({});
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const listener = event => {
      if ((tabIndex == 2 && event.code === 'Enter') || event.code === 'NumpadEnter') {
        onSave(event, groupState, customColors);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [customColors]);

  useEffect(() => {
    if (investorSuitabilityProvider) {
      const {
        partner,
        defaultRegulation,
        editable,
        basic,
        informed,
        advanced,
      } = investorSuitabilityProvider;
      setInvestorSuitabilityState({
        partner: partner ? { id: partner, name: partner } : null,
        defaultRegulation: { id: defaultRegulation, name: defaultRegulation },
        editable: editable,
        basic: basic,
        informed: informed,
        advanced: advanced,
      });
    }
  }, [investorSuitabilityProvider]);
  const errorsHandler = errors => {
    if (errors) {
      const { fieldErrors } = errors.data;
      fieldErrors?.forEach(err => {
        setErrorState(prevState => ({
          ...prevState,
          [err.field]: err.message,
        }));
      });
      setTabIndex(0);
    }
  };

  const onInit = groupId => {
    getApplicationAccess();
    getAdditionalLanguages();
    getInruleList();
    getExternalDataProviders();
    getESGProviders();
    getSanctionSecuritiesSources();
    getFundInfoFeeds();
    getInvestorSuitabilityProviders();
    getInvestorSuitabilityKnowledge();
    getIconColors();
    getAllProfileList(groupId);
    if (groupId) {
      getGroupById(groupId);
    }
  };

  const onReset = e => {
    e.preventDefault();
    resetGroup();
    setEditGroupId();
    hideModal();
  };

  const onSave = (e, group, colors) => {
    e.preventDefault();
    let saveGroup = groupState;
    if (investorSuitabilityState.partner) {
      const { partner, defaultRegulation, basic, informed, advanced } = investorSuitabilityState;
      saveGroup = {
        ...saveGroup,
        investorSuitabilityProvider: {
          partner: partner ? partner.id : null,
          defaultRegulation: defaultRegulation ? defaultRegulation.id : null,
          editable: investorSuitabilityState.editable,
          basic: basic,
          informed: informed,
          advanced: advanced,
        },
      };
    } else {
      delete saveGroup.investorSuitabilityProvider;
    }
    saveGroup.design = { icons: cloneDeep(colors) };
    saveGroup = stringifyFields(saveGroup, ['sanctionSecuritiesSource', 'fundInfoFeed'], 'code');
    if (group.id) {
      updateGroup(saveGroup).catch(error => {
        errorsHandler(error);
      });
    } else {
      createGroup(saveGroup).catch(error => {
        errorsHandler(error);
      });
    }
  };

  useEffect(() => {
    if (editGroup) {
      editGroup.sanctionSecuritiesSource = createObjectForUISelect(
        editGroup.sanctionSecuritiesSource,
      );
      editGroup.fundInfoFeed = createObjectForUISelect(editGroup.fundInfoFeed);
      setGroupState(editGroup);
    }
  }, [editGroup]);

  useEffect(() => {
    onInit(editId);
  }, []);

  const handleChange = ({ target: { value, name, checked, type } }) => {
    const isCheckbox = type === 'checkbox';
    setGroupState({ ...groupState, [name]: isCheckbox ? checked : value });
    setErrorState({ ...errorState, [name]: undefined });
  };

  const externalDataProviderConfirmation = () => {
    showModal('CONFIRMATION_MODAL', {
      message: 'Some profiles are using this data provider, do you really want to remove access ?',
      confirmationBtnTitle: 'Yes, Remove the Access',
      cancelBtnTitle: 'No, Go Back to the Previous Screen',
      onCancel: () => {
        showModal('GROUP_MODAL');
      },
      onConfirmation: () => {
        let group = cloneDeep(groupState);
        group.cascadeDataProviderRemoval = true;
        group.dataProviders = null;
        updateGroup(group);
      },
    });
  };
  const handleSelectChange = (key, value) => {
    if (
      isEqual(key, 'externalDataProvider') &&
      isEqual(value, null) &&
      profileList.some(p => p.dataProviderAccesses?.length > 0)
    ) {
      externalDataProviderConfirmation();
    } else {
      setGroupState({ ...groupState, [key]: value });
      setErrorState({ ...errorState, [key]: undefined });
    }
  };

  const handleInvestorSuitabilityChange = (key, value) => {
    setInvestorSuitabilityState({ ...investorSuitabilityState, [key]: value });
  };

  const parseInvestorSuitabilityProvider = () => {
    const providers = [];
    Object.keys(investorSuitabilityProviders).forEach(p => {
      const providerOptions = [];
      investorSuitabilityProviders[p].forEach(i => {
        providerOptions.push({ name: i, id: i });
      });
      providers.push({
        id: p,
        name: p,
        defaultRegulation: providerOptions,
      });
    });
    return providers;
  };
  const getDefaultRegulationsOptions = () => {
    return parseInvestorSuitabilityProvider().filter(i => i.id === partner.id)[0]
      ?.defaultRegulation;
  };
  const cleanKnowledge = () => {
    setInvestorSuitabilityState({
      ...investorSuitabilityState,
      partner: null,
      basic: [],
      informed: [],
      advanced: [],
    });
  };
  const isEdit = !!editId;
  const { dataProviders, sanctionSecuritiesSource, fundInfoFeed } = groupState;
  const {
    partner,
    defaultRegulation,
    editable,
    basic,
    informed,
    advanced,
  } = investorSuitabilityState;
  const generateAnswerLegendIcon = (code, text, iconJsx) => {
    return (
      <div className='d-flex flex-row justify-content-between mb-2 icons-row'>
        {iconJsx}
        <FontAwesomeIcon
          icon='paint-brush'
          color='black'
          transform='shrink-5'
          onClick={() => setShowPickerCode(code)}
        />
        <input disabled value={text} />
        <span className='resetColorContainer' title='Reset'>
          <FontAwesomeIcon
            icon='times'
            color='black'
            onClick={() => {
              setShowPickerCode(null);
              setCustomColors(prevState => {
                const colors = cloneDeep(prevState);
                colors[code] = { color: getDefaultColor(code), label: getIconLabel(code) };
                return colors;
              });
            }}
          />
        </span>
      </div>
    );
  };

  const getDefaultColor = code => {
    return defaultIconColors[code]?.color;
  };

  const getSelectedColor = code => {
    let color = customColors[code]?.color || editGroup?.design.icons[code]?.color;
    return color || getDefaultColor(code);
  };

  const getIconLabel = code => {
    if (!code) {
      return '';
    }
    return defaultIconColors[code].label;
  };

  const handleColorChange = color => {
    let colors = cloneDeep(customColors);
    colors[showPickerCode] = { color: color.hex, label: getIconLabel(showPickerCode) };
    setCustomColors(colors);
  };
  const hasInAppPremium = () => groupState?.applications.find(a => a.id === 'IN_APP_PREMIUM');

  const premiumAccessRemoved = nextApplications =>
    groupState?.applications.find(a => a.id === 'IN_APP_PREMIUM') &&
    !nextApplications.find(a => a.id === 'IN_APP_PREMIUM');

  const defaultColorsLoaded = () => Object.keys(defaultIconColors).length > 0;
  const hasFundInfoProvider = () => {
    return dataProviders?.some(d => d.code === FUNDINFO_CODE);
  };

  return (
    <UIModal
      isOpen
      toggle={onReset}
      title={`${isEdit ? 'Edit' : 'Add'} group`}
      className='ui-edit-modal'
    >
      {isLoading ? (
        <UICustomLoader loading={isLoading} />
      ) : (
        <div>
          <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
            <TabList>
              <Tab>Access</Tab>
              {hasInAppPremium() && <Tab>Knowledge & Experience</Tab>}
              {process.env.REACT_APP_DESIGN_IN_DEVELOPMENT && defaultColorsLoaded() && (
                <Tab>Design</Tab>
              )}
            </TabList>
            <TabPanel>
              <Form
                className='pt-3'
                onSubmit={e => onSave(e, groupState, customColors)}
                onReset={onReset}
              >
                <FormGroup>
                  <Label className='body body--bold mb-2'>Name</Label>
                  <Input
                    name='name'
                    value={get(groupState, 'name', '')}
                    placeholder='Name'
                    className={errorState.name ? 'ui-input error' : 'ui-input'}
                    onChange={handleChange}
                  />
                  <Label className='errorMessage'>{errorState.name}</Label>
                </FormGroup>
                <FormGroup>
                  <Label className='body body--bold mb-2'>Contact person</Label>
                  <Input
                    name='contactPerson'
                    value={get(groupState, 'contactPerson') || ''}
                    placeholder='Contact person'
                    className={errorState.contactPerson ? 'ui-input error' : 'ui-input'}
                    onChange={handleChange}
                  />
                  <Label className='errorMessage'>{errorState.contactPerson}</Label>
                </FormGroup>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-baseline justify-content-baseline w-25'>
                    <FormGroup>
                      <Label className='body body--bold mb-2 mr-3'>Active</Label>
                      <UICheckbox
                        name='active'
                        checked={get(groupState, 'active', false)}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className='body body--bold mb-2'>Admin</Label>
                      <UICheckbox
                        name='admin'
                        checked={get(groupState, 'admin', false)}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </div>
                  {get(groupState, 'admin') && (
                    <div className='adminMessage'>This group will have all service accesses.</div>
                  )}
                  <Label className='errorMessage'>{errorState.inAppPremium}</Label>
                </div>
                <FormGroup>
                  <div>
                    <div className='mt-4 mb-2'>Application access</div>
                    <UISelect
                      isMulti
                      isSearchable
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      placeholder='Select the access'
                      value={get(groupState, 'applications')}
                      onChange={val => {
                        if (!val || premiumAccessRemoved(val)) {
                          setInvestorSuitabilityState(defaultInvestorSuitability);
                        }
                        handleSelectChange('applications', val || []);
                      }}
                      options={applicationAccessList}
                      hasError={!!errorState.applications}
                    />
                  </div>
                  <Label className='errorMessage'>{errorState.applications}</Label>
                </FormGroup>
                <FormGroup>
                  <div>
                    <div className='mt-4 mb-2'>Additional languages</div>
                    <UISelect
                      isMulti
                      isSearchable
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ isoCode }) => isoCode}
                      placeholder='Select additional languages'
                      value={get(groupState, 'additionalLanguages')}
                      onChange={val => handleSelectChange('additionalLanguages', val || [])}
                      options={additionalLanguagesList}
                      hasError={!!errorState.additionalLanguages}
                    />
                  </div>
                  <Label className='errorMessage'>{errorState.additionalLanguages}</Label>
                </FormGroup>
                {isEdit && (
                  <FormGroup>
                    <Label className='body body--bold mb-2'>Description</Label>
                    <Input
                      name='description'
                      type='textarea'
                      rows='10'
                      value={get(groupState, 'description', '')}
                      placeholder='Add comment'
                      className={
                        errorState.description ? 'ui-input textarea error' : 'ui-input textarea'
                      }
                      onChange={handleChange}
                    />
                    <Label className='errorMessage'>{errorState.description}</Label>
                  </FormGroup>
                )}
                <FormGroup>
                  <Label className='body body--bold mb-2'>inRule</Label>
                  <UISelect
                    isSearchable
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ id }) => id}
                    placeholder=''
                    value={inRulesList.find(
                      ({ id }) => id === get(groupState, 'inRulesVersionId', 1),
                    )}
                    onChange={val => handleSelectChange('inRulesVersionId', val.id)}
                    options={inRulesList}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className='body body--bold mb-2'>Product Data Providers</Label>
                  <UISelect
                    isMulti
                    isSearchable
                    isClearable
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ code }) => code}
                    placeholder=''
                    value={dataProviders ? get(groupState, 'dataProviders', '') : null}
                    onChange={val => {
                      if (val) {
                        handleSelectChange('dataProviders', val);
                      } else {
                        setGroupState({
                          ...groupState,
                          dataProviders: null,
                          sanctionSecuritiesSource: null,
                          fundInfoFeed: null,
                        });
                      }
                    }}
                    options={externalDataProviders}
                    hasError={!!errorState.dataProviders}
                  />
                  <Label className='errorMessage'>{errorState.dataProviders}</Label>
                </FormGroup>
                {hasFundInfoProvider() && (
                  <FormGroup>
                    <Label className='body body--bold mb-2'>Fundinfo File</Label>
                    <UISelect
                      isSearchable
                      isClearable
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ code }) => code}
                      placeholder=''
                      value={fundInfoFeed ? get(groupState, 'fundInfoFeed', '') : null}
                      onChange={val => handleSelectChange('fundInfoFeed', val)}
                      options={fundInfoFeeds}
                      hasError={!!errorState.fundInfoFeed}
                    />
                    <Label className='errorMessage'>{errorState.fundInfoFeed}</Label>
                  </FormGroup>
                )}
                {dataProviders && (
                  <FormGroup>
                    <Label className='body body--bold mb-2'>Sanctioned Securities</Label>
                    <UISelect
                      isSearchable
                      isClearable
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ code }) => code}
                      placeholder=''
                      value={
                        sanctionSecuritiesSource
                          ? get(groupState, 'sanctionSecuritiesSource', '')
                          : null
                      }
                      onChange={val => handleSelectChange('sanctionSecuritiesSource', val)}
                      options={sanctionSecuritiesSources}
                      hasError={!!errorState.sanctionSecuritiesSource}
                    />
                    <Label className='errorMessage'>{errorState.sanctionSecuritiesSource}</Label>
                  </FormGroup>
                )}
                <FormGroup>
                  <Label className='body body--bold mb-2'>ESG Providers</Label>
                  <UISelect
                    isMulti
                    isSearchable
                    isClearable
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ code }) => code}
                    placeholder=''
                    value={esgProviders ? get(groupState, 'esgProviders', '') : null}
                    onChange={val => handleSelectChange('esgProviders', val)}
                    options={esgProviders}
                    hasError={!!errorState.esgProviders}
                  />
                  <Label className='errorMessage'>{errorState.esgProviders}</Label>
                </FormGroup>
                <div className='d-flex align-items-center mt-3'>
                  <UIButton className='w-100' height={50} type='submit'>
                    Save
                  </UIButton>
                  <div className='ml-3 press-enter-text'>or press ENTER</div>
                </div>
              </Form>
            </TabPanel>
            {hasInAppPremium() && (
              <TabPanel>
                <Form
                  className='pt-3'
                  onSubmit={e => onSave(e, groupState, customColors)}
                  onReset={onReset}
                >
                  {investorSuitabilityProviders && (
                    <div>
                      <h6>Investor Suitability</h6>
                      <FormGroup>
                        <Label className='body body--bold mb-2'>Partner</Label>
                        <UISelect
                          isClearable
                          isSearchable
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ id }) => id}
                          value={partner}
                          onChange={val => {
                            if (val) {
                              if (val !== partner) {
                                setInvestorSuitabilityState({
                                  ...investorSuitabilityState,
                                  partner: val,
                                  basic: investorSuitabilityKnowledge.basic,
                                  informed: investorSuitabilityKnowledge.informed,
                                  advanced: investorSuitabilityKnowledge.advanced,
                                });
                              } else {
                                handleInvestorSuitabilityChange('partner', val);
                              }
                            } else {
                              cleanKnowledge();
                            }
                          }}
                          options={parseInvestorSuitabilityProvider()}
                        />
                      </FormGroup>
                    </div>
                  )}
                  {partner && (
                    <div>
                      <div className='d-flex flex-row justify-content-between w-75'>
                        <FormGroup>
                          <Label className='body body--bold mb-2'>
                            Applied Regulation (Default)
                          </Label>
                          <UISelect
                            isSearchable
                            getOptionLabel={({ name }) => name}
                            getOptionValue={({ id }) => id}
                            value={defaultRegulation}
                            onChange={val =>
                              handleInvestorSuitabilityChange('defaultRegulation', val)
                            }
                            options={getDefaultRegulationsOptions()}
                            hasError={!!errorState['investorSuitabilityProvider.defaultRegulation']}
                          />
                          <Label className='errorMessage'>
                            {errorState['investorSuitabilityProvider.defaultRegulation']}
                          </Label>
                        </FormGroup>
                        <div className='d-flex flex-column'>
                          <Label className='body body--bold'>Editable by end user</Label>
                          <UICheckbox
                            checked={editable}
                            onChange={() => handleInvestorSuitabilityChange('editable', !editable)}
                          />
                        </div>
                      </div>
                      <div>
                        <FormGroup>
                          <div className='d-flex flex-row'>
                            <Label className='body body--bold mb-2'>Basic Investor</Label>
                            <div
                              className='addAll'
                              onClick={() =>
                                handleInvestorSuitabilityChange(
                                  'basic',
                                  investorSuitabilityKnowledge.all,
                                )
                              }
                            >
                              <UIIcon
                                icon='plusCircle'
                                iconColor='var(--gunmetal)'
                                iconClassName='ml-1 mr-1 mt-1'
                              />
                              Add All
                            </div>
                          </div>
                          <UISelect
                            isMulti
                            isClearable
                            getOptionLabel={({ name }) => name}
                            getOptionValue={({ code }) => code}
                            value={basic}
                            onChange={val => handleInvestorSuitabilityChange('basic', val)}
                            options={investorSuitabilityKnowledge.all}
                          />
                        </FormGroup>
                        <FormGroup>
                          <div className='d-flex flex-row'>
                            <Label className='body body--bold mb-2'>Informed Investor</Label>
                            <div
                              className='addAll'
                              onClick={() =>
                                handleInvestorSuitabilityChange(
                                  'informed',
                                  investorSuitabilityKnowledge.all,
                                )
                              }
                            >
                              <UIIcon
                                icon='plusCircle'
                                iconColor='var(--gunmetal)'
                                iconClassName='ml-1 mr-1 mt-1'
                              />
                              Add All
                            </div>
                          </div>
                          <UISelect
                            isClearable
                            isMulti
                            getOptionLabel={({ name }) => name}
                            getOptionValue={({ code }) => code}
                            value={informed}
                            onChange={val => handleInvestorSuitabilityChange('informed', val)}
                            options={investorSuitabilityKnowledge.all}
                          />
                        </FormGroup>
                        <FormGroup>
                          <div className='d-flex flex-row'>
                            <Label className='body body--bold mb-2'>Advanced Investor</Label>
                            <div
                              className='addAll'
                              onClick={() =>
                                handleInvestorSuitabilityChange(
                                  'advanced',
                                  investorSuitabilityKnowledge.all,
                                )
                              }
                            >
                              <UIIcon
                                icon='plusCircle'
                                iconColor='var(--gunmetal)'
                                iconClassName='ml-1 mr-1 mt-1'
                              />
                              Add All
                            </div>
                          </div>
                          <UISelect
                            isClearable
                            isMulti
                            getOptionLabel={({ name }) => name}
                            getOptionValue={({ code }) => code}
                            value={advanced}
                            onChange={val => handleInvestorSuitabilityChange('advanced', val)}
                            options={investorSuitabilityKnowledge.all}
                          />
                        </FormGroup>
                      </div>
                    </div>
                  )}
                  <div className='d-flex align-items-center mt-3'>
                    <UIButton className='w-100' height={50} type='submit'>
                      Save
                    </UIButton>
                    <div className='ml-3 press-enter-text'>or press ENTER</div>
                  </div>
                </Form>
              </TabPanel>
            )}
            {process.env.REACT_APP_DESIGN_IN_DEVELOPMENT && defaultColorsLoaded() && (
              <TabPanel>
                <Form
                  className='pt-3'
                  onSubmit={e => onSave(e, groupState, customColors)}
                  onReset={onReset}
                >
                  <FormGroup>
                    <div className='title--h2 mb-4 mt-4'>Answer legend</div>
                    <div className='d-flex flex-row'>
                      <div className='d-flex flex-column w-50'>
                        {generateAnswerLegendIcon(
                          'YES',
                          getIconLabel('YES'),
                          <FontAwesomeIcon icon='check-circle' color={getSelectedColor('YES')} />,
                        )}
                        {generateAnswerLegendIcon(
                          'GREY_ZONE_YES',
                          getIconLabel('GREY_ZONE_YES'),
                          <FontAwesomeIcon
                            icon='exclamation-circle'
                            color={getSelectedColor('GREY_ZONE_YES')}
                          />,
                        )}
                        {generateAnswerLegendIcon(
                          'GREY_ZONE',
                          getIconLabel('GREY_ZONE'),
                          <FontAwesomeIcon
                            icon='exclamation-circle'
                            color={getSelectedColor('GREY_ZONE')}
                          />,
                        )}
                        {generateAnswerLegendIcon(
                          'GREY_ZONE_NO',
                          getIconLabel('GREY_ZONE_NO'),
                          <FontAwesomeIcon
                            icon='exclamation-circle'
                            color={getSelectedColor('GREY_ZONE_NO')}
                          />,
                        )}
                        {generateAnswerLegendIcon(
                          'NOT_APPLICABLE',
                          getIconLabel('NOT_APPLICABLE'),
                          <div className='na-container'>
                            <NAIcon
                              fill={getSelectedColor('NOT_APPLICABLE')}
                              stroke={getSelectedColor('NOT_APPLICABLE')}
                            />
                          </div>,
                        )}
                        {generateAnswerLegendIcon(
                          'WARNING',
                          getIconLabel('WARNING'),
                          <FontAwesomeIcon
                            className='warning-icon'
                            icon='exclamation-triangle'
                            color={getSelectedColor('WARNING')}
                            transform='shrink-2'
                          />,
                        )}
                        {generateAnswerLegendIcon(
                          'NO',
                          getIconLabel('NO'),
                          <FontAwesomeIcon icon='times-circle' color={getSelectedColor('NO')} />,
                        )}
                        {generateAnswerLegendIcon(
                          'DOCUMENT',
                          getIconLabel('DOCUMENT'),
                          <span className='fa-layers fa-fw'>
                            <FontAwesomeIcon icon='circle' color={getSelectedColor('DOCUMENT')} />
                            <FontAwesomeIcon
                              className='file-icon'
                              icon='file-alt'
                              color='white'
                              transform='shrink-6'
                            />
                          </span>,
                        )}
                      </div>
                      {showPickerCode !== null && (
                        <div className='ml-5'>
                          <div className='d-flex justify-content-end'>
                            <FontAwesomeIcon
                              className='cursorPointer'
                              icon='times'
                              color='black'
                              transform='shrink-5'
                              onClick={() => setShowPickerCode(null)}
                            />
                          </div>
                          <SketchPicker
                            disableAlpha={true}
                            color={getSelectedColor(showPickerCode)}
                            onChange={handleColorChange}
                          />
                        </div>
                      )}
                    </div>
                  </FormGroup>
                  <div className='d-flex align-items-center mt-3'>
                    <UIButton className='w-100' height={50} type='submit'>
                      Save
                    </UIButton>
                    <div className='ml-3 press-enter-text'>or press ENTER</div>
                  </div>
                </Form>
              </TabPanel>
            )}
          </Tabs>
        </div>
      )}
    </UIModal>
  );
};

GroupFormModal.propTypes = {
  editGroup: PropTypes.oneOfType([PropTypes.object]),
  editId: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  isLoading: PropTypes.bool,
  inRulesList: PropTypes.arrayOf(PropTypes.object),
  updateGroup: PropTypes.func,
  createGroup: PropTypes.func,
  getInruleList: PropTypes.func,
  getGroupById: PropTypes.func,
  resetGroup: PropTypes.func,
  setEditGroupId: PropTypes.func,
  hideModal: PropTypes.func,
  getApplicationAccess: PropTypes.func,
  getAdditionalLanguages: PropTypes.func,
  applicationAccessList: PropTypes.arrayOf(PropTypes.object),
  additionalLanguagesList: PropTypes.arrayOf(PropTypes.object),
  externalDataProviders: PropTypes.arrayOf(PropTypes.object),
  ESGDataProviders: PropTypes.arrayOf(PropTypes.object),
  getExternalDataProviders: PropTypes.func,
  getInvestorSuitabilityProviders: PropTypes.func,
};

const mapStateToProps = ({ common, groups, profiles }) => ({
  inRulesList: getResults(common.inRules),
  editGroup: getResource(groups.group),
  editId: groups.editGroupId,
  isLoading: getIsFetching(groups.group),
  editModal: groups.editModal,
  applicationAccessList: getResults(groups.applicationAccessList),
  additionalLanguagesList: getResults(groups.additionalLanguagesList),
  externalDataProviders: getResource(groups.externalDataProviders),
  sanctionSecuritiesSources: getResource(groups.sanctionSecuritiesSources),
  esgProviders: getResource(groups.ESGDataProviders),
  fundInfoFeeds: getResource(groups.fundInfoFeeds),
  investorSuitabilityProviders: getResource(groups.investorSuitabilityProviders),
  investorSuitabilityKnowledge: getResource(groups.investorSuitabilityKnowledge),
  defaultIconColors: getResults(groups.iconColors),
  profileList: getResults(profiles.profileList),
});

const mapDispatchToProps = dispatch => ({
  updateGroup: group => dispatch(_updateGroup(group)),
  createGroup: group => dispatch(_createGroup(group)),
  getGroupById: groupId => dispatch(_getGroupById(groupId)),
  getInruleList: () => dispatch(_getInruleList()),
  resetGroup: () => dispatch(_resetGroup()),
  setEditGroupId: () => dispatch(_setEditGroupId()),
  hideModal: () => dispatch(_hideModal()),
  getApplicationAccess: () => dispatch(_getApplicationAccess()),
  getAdditionalLanguages: () => dispatch(_getAdditionalLanguages()),
  getExternalDataProviders: () => dispatch(_getExternalDataProviders()),
  getESGProviders: () => dispatch(_getESGProviders()),
  getSanctionSecuritiesSources: () => dispatch(_getSanctionSecuritiesSources()),
  getFundInfoFeeds: () => dispatch(_getFundInfoFeeds()),
  getInvestorSuitabilityProviders: () => dispatch(_getInvestorSuitabilityProviders()),
  getInvestorSuitabilityKnowledge: () => dispatch(_getInvestorSuitabilityKnowledge()),
  getIconColors: () => dispatch(_getIconColors()),
  getAllProfileList: groupId => dispatch(_getAllProfileList(groupId)),
  showModal: (type, props) => dispatch(_showModal(type, props)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupFormModal);
