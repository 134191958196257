import { combineReducers } from 'redux';
import createReducer from './createReducer';
import createIsFetching from './createIsFetching';

const createResource = ({ SUCCESS, RESET }) =>
  createReducer(null, {
    [SUCCESS]: (state, action) => action.resource || null,
    [RESET]: () => null,
  });

const createResourceHandler = actionTypes =>
  combineReducers({
    resource: createResource(actionTypes),
    isFetching: createIsFetching(actionTypes),
  });

export default createResourceHandler;

export const getResource = state => state.resource;
export const getIsFetching = state => state.isFetching;
