import { combineReducers } from 'redux';

import * as types from '../types/users';
import createList from '../helpers/createList';
import createReducer from '../helpers/createReducer';
import createResource from '../helpers/createResource';
import { formatDate } from '../../common/helpers/date';

export const userFilterDefaultState = {
  pagination: {
    page: 0,
    size: 10,
    endDateFrom: formatDate(new Date(new Date().setDate(new Date().getDate() + 1)), 'YYYY-MM-DD'),
  },
  sort: {},
  filter: {},
};

const userListActions = {
  RESET: types.USER_LIST_RESET,
  REQUEST: types.USER_LIST_REQUEST,
  SUCCESS: types.USER_LIST_SUCCESS,
  FAILURE: types.USER_LIST_FAILURE,
  REMOVE: types.USER_LIST_REMOVE,
  UPDATE: types.USER_LIST_UPDATE,
};

const searchUserListActions = {
  RESET: types.SEARCH_USER_LIST_RESET,
  REQUEST: types.SEARCH_USER_LIST_REQUEST,
  SUCCESS: types.SEARCH_USER_LIST_SUCCESS,
  FAILURE: types.SEARCH_USER_LIST_FAILURE,
  REMOVE: types.SEARCH_USER_LIST_REMOVE,
  UPDATE: types.SEARCH_USER_LIST_UPDATE,
};

const userActions = {
  RESET: types.USER_RESET,
  REQUEST: types.USER_REQUEST,
  SUCCESS: types.USER_SUCCESS,
};

const userRolesActions = {
  SUCCESS: types.USER_API_ROLES_SUCCESS,
  FAILURE: types.USER_API_ROLES_FAILURE,
};

const amtRolesActions = {
  REQUEST: types.USER_AMT_ROLES_REQUEST,
  SUCCESS: types.USER_AMT_ROLES_SUCCESS,
};

const inAppRolesActions = {
  REQUEST: types.USER_IN_APP_ROLES_REQUEST,
  SUCCESS: types.USER_IN_APP_ROLES_SUCCESS,
};

const userInformationActions = {
  REQUEST: types.USER_INFO_REQUEST,
  SUCCESS: types.USER_INFO_SUCCESS,
};

export default combineReducers({
  userList: createList(userListActions),
  searchUserList: createList(searchUserListActions),
  total: createReducer(0, {
    [types.SEARCH_USER_TOTAL_RECEIVE]: (state, action) => action.total || 0,
  }),
  filters: createReducer(userFilterDefaultState, {
    [types.SET_USER_FILTERS]: (state, action) => action.filters || userFilterDefaultState,
  }),
  user: createResource(userActions),
  showUserModal: createReducer(null, {
    [types.SET_SHOW_USER_MODAL]: (state, action) => action.show || null,
  }),
  editUserId: createReducer(null, {
    [types.SET_EDIT_USER_ID]: (state, action) => action.id || null,
  }),
  technicalRolesList: createList(userRolesActions),
  amtRolesList: createList(amtRolesActions),
  inAppRolesList: createList(inAppRolesActions),
  userInformation: createList(userInformationActions),
});
