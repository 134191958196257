import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import UIIcon from './UIIcon';

const selectTypes = {
  default: DayPicker,
  withInput: DayPickerInput,
};

const numberOfYears = 3;
const firstYear = new Date().getFullYear() - numberOfYears;
const lastYear = new Date().getFullYear() + 10;
const fromMonth = new Date(firstYear, 0);
const currentDate = new Date();

function YearMonthForm({ date, localeUtils, onChange }) {
  const months = localeUtils.getMonths();

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= lastYear; i += 1) {
    years.push(i);
  }

  const handleChange = e => {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <form className='DayPicker-Caption'>
      <select name='month' onChange={handleChange} value={date.getMonth()}>
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select name='year' onChange={handleChange} value={date.getFullYear()}>
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </form>
  );
}

YearMonthForm.propTypes = {
  date: PropTypes.instanceOf(PropTypes.date),
  localeUtils: PropTypes.oneOfType(PropTypes.object),
  onChange: PropTypes.func,
};

const NavBar = ({ onPreviousClick, onNextClick }) => (
  <div className='DayPicker-Navbutton-container'>
    <span
      className='DayPicker-Navbutton DayPicker-Navbutton--prev'
      role='button'
      aria-label='Previous month'
      tabIndex={0}
      onClick={() => onPreviousClick()}
    >
      <UIIcon
        icon='chevronDown'
        iconSize='1.5rem'
        iconColor='var(--gunmetal)'
        iconClassName='chevron-icon'
        iconRotate={90}
      />
    </span>
    <span
      className='DayPicker-Navbutton DayPicker-Navbutton--next'
      role='button'
      aria-label='Previous month'
      tabIndex={0}
      onClick={() => onNextClick()}
    >
      <UIIcon
        icon='chevronDown'
        iconSize='1.5rem'
        iconColor='var(--gunmetal)'
        iconClassName='chevron-icon'
        iconRotate={270}
      />
    </span>
  </div>
);

NavBar.propTypes = {
  onPreviousClick: PropTypes.func,
  onNextClick: PropTypes.func,
};

const UIDatePicker = ({ selectedDays, setDate, withInput, withClass, ...args }) => {
  const Tag = withInput ? selectTypes.withInput : selectTypes.default;
  const handleDaySelect = day => {
    setDate(day === undefined ? '' : day);
  };
  const [dateValue, setDateValue] = useState(selectedDays || currentDate);
  const handleYearMonthChange = date => {
    setDateValue(date);
  };

  return (
    <div className={withClass}>
      <Tag
        className='Selectable'
        firstDayOfWeek={1}
        selectedDays={selectedDays}
        navbarElement={<NavBar />}
        dayPickerProps={{
          navbarElement: <NavBar />,
          captionElement: ({ date, localeUtils }) => (
            <YearMonthForm date={date} localeUtils={localeUtils} onChange={handleYearMonthChange} />
          ),
          month: dateValue,
          year: dateValue,
          ...args,
        }}
        onDayChange={handleDaySelect}
        onDayClick={handleDaySelect}
        captionElement={({ date, localeUtils }) => (
          <YearMonthForm date={date} localeUtils={localeUtils} onChange={handleYearMonthChange} />
        )}
        month={dateValue}
        year={dateValue}
        {...args}
      />
    </div>
  );
};

UIDatePicker.propTypes = {
  date: PropTypes.instanceOf(PropTypes.date),
  selectedDays: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setDate: PropTypes.func.isRequired,
  withInput: PropTypes.bool,
  withClass: PropTypes.string,
  localeUtils: PropTypes.oneOfType(PropTypes.object),
};

export default UIDatePicker;
